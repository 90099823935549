<template>
  <v-form ref="form">
    <v-container fluid v-if="$store.state.isUserLoggedIn && this.$store.state.marcacao != null || this.$store.state.marcacao.idmarcacao != null">
      <v-row v-if="loading == false">
        <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Marcação</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verObservacoes" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-note</v-icon>
              </template>
              <span>Observações</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-chart-timeline-variant</v-icon>
              </template>
              <span>Logs</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-message-processing</v-icon>
              </template>
              <span>Mensagens</span>
            </v-tooltip>
            <div v-if="editarMarcacoes.concluido == false" class="d-none d-lg-block" style="height: 50%; width: 20px; border-right: 3px solid white;"></div>
            <v-tooltip bottom v-if="editarMarcacoes.concluido == false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="dialogCriarInterveniente = true" v-bind="attrs" v-on="on" class="ml-5">mdi-account-multiple-plus</v-icon>
              </template>
              <span>Adicionar intervenientes</span>
            </v-tooltip>
            <v-tooltip bottom v-if="editarMarcacoes.concluido == false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="apagarMarcacao(editarMarcacoes.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
              </template>
              <span>Apagar marcação</span>
            </v-tooltip>
            <v-tooltip bottom v-if="editarMarcacoes.concluido == false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="editarMarcacao" v-bind="attrs" v-on="on" class="ml-5">mdi-content-save</v-icon>
              </template>
              <span>Guardar marcação</span>
            </v-tooltip>
            <!--Mobile-->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-5 d-lg-none">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item @click="verObservacoes">
                  <v-list-item-title>Observações</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Logs</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Mensagens</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>
        </v-col>
        <span id="mobile-margintop-conteudo"></span>
        <v-col cols="12">
          <span>{{ apresentarData(editarMarcacoes.data_registo) }} {{ apresentarHoras(editarMarcacoes.data_registo) }}</span>
          <h3>{{ editarMarcacoes.nome_cliente }}</h3>
          <h5>Registado por {{ editarMarcacoes.quemRegistou.nome }}</h5>
        </v-col>
        
        <v-col cols="12">
          <label>Descrição</label>
          <wysiwyg v-model="editarMarcacoes.descricao"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field type="text" v-model="editarMarcacoes.quem_pediu" clearable label="Quem Pediu"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="editarMarcacoes.como_pediu" label="Pedido Como" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="editarMarcacoes.id_topico" label="Tópico" :items="topicos" item-text="descricao" item-value="id"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-if="editarMarcacoes.concluido == false" v-model="editarMarcacoes.id_estado" label="Estado" :items="estados" item-text="descricao" item-value="id"></v-select>
          <v-text-field v-else type="text" value="Terminada" disabled></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-chip @click="toggleIndesmarcavel" v-model="editarMarcacoes.indesmarcavel" :color="editarMarcacoes.indesmarcavel == true ? 'primary' : ''">Indesmarcável</v-chip>
        </v-col>
        <v-col cols="12" md="2">
          <v-chip @click="toggleInstalacoesInfordio" v-model="editarMarcacoes.instalacoes_infordio" :color="editarMarcacoes.instalacoes_infordio == true ? 'primary' : ''">Instalações Infordio</v-chip>
        </v-col>
        <v-col cols="12" md="4" class="d-none d-md-block"></v-col>
        <!--
        <v-col cols="12" md="2">
          <InputDate v-model="editarMarcacoes.data_inicio" placeholder="Data de Início" dateFormat="dd/mm/yy"/>
        </v-col>
        <v-col cols="12" md="2">
          <InputDate v-model="editarMarcacoes.data_fim" placeholder="Data de Fim" dateFormat="dd/mm/yy"/>
        </v-col>
        -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="text-center">
              <label>INTERVENIENTES </label>
            </v-col>
            <v-col cols="12 pt-0" v-for="funcionario in editarMarcacoes.Marcacoes_Funcionarios" :key="funcionario.id">
              <v-list class="interve">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{funcionario.Funcionario.nome}}<br>{{ apresentarData(funcionario.data_inicio) }} - {{ apresentarHoras(funcionario.hora_inicio) }}h - {{ apresentarHoras(funcionario.hora_fim) }}h</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="funcionario.enviado_mail == true">
                    <img :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado" class="mr-5">
                  </v-list-item-action>

                  <v-tooltip bottom v-if="funcionario.concluido == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" @click="intervenienteNaoTerminada(funcionario.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-check</v-icon>
                    </template>
                    <span>Concluída</span>
                  </v-tooltip>

                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" @click="intervenienteTerminada(funcionario.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-close</v-icon>
                    </template>
                    <span>Não concluída</span>
                  </v-tooltip>


                  <v-tooltip bottom v-if="editarMarcacoes.concluido == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" @click="apagarInterveniente(funcionario.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
                    </template>
                    <span>Apagar interveniente</span>
                  </v-tooltip>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>   

        <MyModalObservacoes ref="mymodalobservacoes" />
        <MyModalMarcacoesDiaFuncionario ref="mymodalmarcacoesdiafuncionario" />
        

        <!-- Dialog apagar marcação -->
        <v-dialog v-model="dialogApagarMarcacao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar marcação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarMarcacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a marcação?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarMarcacao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-bottom-sheet v-model="dialogCriarInterveniente">
          <v-container fluid style="height: 500px !important">
            <v-row>
              <v-col cols="3">
                <InputDate @input="filtrarMarcacoesDatasFuncionario" v-model="datainicioinserida" placeholder="Data" dateFormat="dd/mm/yy"/>
                <span v-if="datainicioinserida == null" class="errodata">Campo obrigatório.</span>
              </v-col>
              <v-col cols="9"></v-col>
              <v-col cols="6">
                <InputDate v-model="horainicioinserida" placeholder="Hora de início" timeOnly showTime dateFormat="HH:mm" />
                <span v-if="horainicioinserida == null" class="errodata">Campo obrigatório.</span>
              </v-col>
              <v-col cols="6">
                <InputDate v-model="horafiminserida" placeholder="Hora de fim" timeOnly showTime dateFormat="HH:mm" />
                <span v-if="horafiminserida == null" class="errodata">Campo obrigatório.</span>
              </v-col>
              <v-col cols="12 mb-3">
                <v-chip-group v-model="intervenientesSelecionados" multiple column>
                  <v-chip v-for="tecnico in tecnicos" :key="tecnico.id" :value="tecnico" close-icon="mdi-eye" :close="marcacoesdiafuncionario.some(marcacoesdia => marcacoesdia.id_funcionario === tecnico.id)" @click:close="verMarcacoesDiaFuncionario(tecnico.id)" class="mr-2 mt-3" :color="intervenientesSelecionados.includes(tecnico) ? 'primary' : ''">
                    {{ tecnico.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12 mb-3" class="text-right" v-if="intervenientesSelecionados != '' && datainicioinserida != null && horafiminserida != null && horafiminserida != null">
                <v-btn color="primary" class="white--text" @click="confirmarcriarInterveniente">Criar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-bottom-sheet>


        <!-- Dialog apagar interveniente -->
        <v-dialog v-model="dialogApagarInterveniente">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Remover interveniente da marcação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo remover o interveniente da marcação?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarInterveniente">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template> 
<script>
//  Importar o service necessário
import MarcacoesService from '@/services/MarcacoesService'
import EstadosService from '@/services/EstadosService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import MyModalObservacoes from "@/components/Observacoes/MyModalObservacoes.vue";
import MyModalMarcacoesDiaFuncionario from "@/components/Marcacoes/MyModalMarcacoesDiaFuncionario.vue";
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  components: {
    MyModalObservacoes,
    MyModalMarcacoesDiaFuncionario
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.marcacao == null || this.$store.state.marcacao.idmarcacao == null) {
      this.$router.push({
        name: 'marcacoes'
      })
    } else {
      this.loading = true
      this.getMarcacao()
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data

      if (this.editarMarcacoes.concluido != true) {
        this.todosIntervenientesTerminados()
      }
      this.loading = false
    }
  },
  data () {
    return {
      loading: true,
      datainicioinserida: null,
      horainicioinserida: null,
      horafiminserida: null,
      estados: [],
      topicos: [],
      tecnicos: [],
      marcacoesdiafuncionario: [],
      marcacoesdiafuncionariover: [],
      intervenientesSelecionados: [],
      verIntervenientes: [],
      editarMarcacoes: {
        nome_cliente: null,
        quemRegistou: {},
        data_registo: null,
        quem_pediu: null,
        como_pediu: null,
        descricao: null,
        grau_urgencia: null,
        localizacao: null,
        id_topico: null,
        id_estado: null,
        id_tarefa: null,
        data_inicio: null,
        data_fim: null,
        indesmarcavel: null,
        instalacoes_infordio: null,
      },
      criarIntervenientes: {
        id_marcacao: this.$store.state.marcacao.idmarcacao,
        id_funcionario: null,
        atribuido_data: null,
        atribuido_por: this.$store.state.user.id,
        data_inicio: null,
        data_fim: null,
        hora_inicio: null,
        hora_fim: null,
        data_inicio_total: null,
        data_fim_total: null,
        lido: false
      },
      editarIntervenientes: {
          concluido: null,
          concluido_data: null
      },

      dialogCriarInterveniente: false,
      dialogApagarInterveniente: false,
      dialogApagarMarcacao: false,

      idmarcacaoglobal: '',
      idintervenienteglobal: ''

    }
  },
  methods: {
    goBack() {
      this.$router.push('marcacoes')
    },
    formatarDataParaISO(data) {
      if (data instanceof Date) {
        return moment(data).tz('Europe/Lisbon').toISOString();
      }
      return null;
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    //-------------------------------------------marcações------------------------------//
    async getMarcacao() {
      this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
    },
    async editarMarcacao () {
      try{
        if (this.$refs.form.validate()) {
          await MarcacoesService.put(this.editarMarcacoes)
          this.getMarcacao()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcação editada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar marcação"
          });
      }
    },
    async simMarcacaoTerminada () {
      try{
        if (this.$refs.form.validate()) {
          this.editarMarcacoes.concluido = true
          this.editarMarcacoes.concluido_data = this.formatarDataParaISO(new Date())
          this.editarMarcacoes.id_estado = 6
          await MarcacoesService.put(this.editarMarcacoes)
          this.getMarcacao()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcação terminada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar a marcação"
          });
      }
    },
    async todosIntervenientesTerminados () {
      //ver se os intervenientes estão todos concluidos
      this.verIntervenientes = (await MarcacoesFuncionarioService.index(this.$store.state.marcacao.idmarcacao)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido == this.verIntervenientes.length) {
          this.simMarcacaoTerminada()
        }
      }
    },
    async todosIntervenientesNaoTerminados () {
      //colocar a marcação como não concluida pois tiramos algum interveniente de concluido
      this.verIntervenientes = (await MarcacoesFuncionarioService.index(this.$store.state.marcacao.idmarcacao)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido != this.verIntervenientes.length) {
          this.editarMarcacoes.concluido = false
          this.editarMarcacoes.concluido_data = null
          this.editarMarcacoes.id_estado = 1
          await MarcacoesService.put(this.editarMarcacoes)
          this.getMarcacao()
        }
      }
    },
    async apagarMarcacao (item) {
      this.dialogApagarMarcacao = true
      this.idmarcacaoglobal = item
    },
    async confirmarApagarMarcacao () {
      try{
        await MarcacoesService.delete(this.idmarcacaoglobal)
        this.dialogApagarMarcacao = false
        if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
          this.$router.push('marcacoes')
        } else {
          this.$store.dispatch('setCliente', {  
            numerocliente: this.$store.state.cliente.numerocliente,
          })
          this.$router.push('marcacoes')
        }
        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Marcação apagada com sucesso"
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Erro a apagar marcação, pode ter coisas associadas"
        });
      }
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    async verObservacoes () {
      this.$refs.mymodalobservacoes.open(2, this.editarMarcacoes);
    },
    //-------------------------------------------marcações do dia funcionário------------------------------//
    //-------------------------------------------marcações do dia funcionário------------------------------//
    //-------------------------------------------marcações do dia funcionário------------------------------//
    async verMarcacoesDiaFuncionario (idfuncionario) {
      this.$refs.mymodalmarcacoesdiafuncionario.open(idfuncionario, this.formatarDataParaISO(this.datainicioinserida));
    },
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    async confirmarcriarInterveniente () {
      try{

        let horainicioescolhida = '1899-12-30T' + this.formatarDataParaISO(this.horainicioinserida).slice(11,16) + ':00.000Z'
        let horafimescolhida = '1899-12-30T' + this.formatarDataParaISO(this.horafiminserida).slice(11,16) + ':00.000Z'
        let datainiciohoratotal = this.formatarDataParaISO(this.datainicioinserida).slice(0,11) + this.formatarDataParaISO(this.horainicioinserida).slice(11,16) + ':00.000Z'
        let datafimhoratotal = this.formatarDataParaISO(this.datainicioinserida).slice(0,11) + this.formatarDataParaISO(this.horafiminserida).slice(11,16) + ':00.000Z'


        for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
          this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
          this.criarIntervenientes.data_inicio = this.formatarDataParaISO(this.datainicioinserida)
          this.criarIntervenientes.data_fim = this.criarIntervenientes.data_inicio

          
          this.criarIntervenientes.hora_inicio = horainicioescolhida
          this.criarIntervenientes.hora_fim = horafimescolhida
          this.criarIntervenientes.data_inicio_total = datainiciohoratotal
          this.criarIntervenientes.data_fim_total = datafimhoratotal
          this.criarIntervenientes.atribuido_data = this.formatarDataParaISO(new Date())
          await MarcacoesFuncionarioService.post(this.criarIntervenientes)
        }
          this.intervenientesSelecionados = []
          this.getMarcacao()
          this.dialogCriarInterveniente = false
          this.todosIntervenientesNaoTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente criado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar interveniente"
          });
      }
    },
    async apagarInterveniente (id) {
      this.dialogApagarInterveniente = true
      this.idintervenienteglobal = id
    },
    async confirmarApagarInterveniente () {
      try{
          await MarcacoesFuncionarioService.delete(this.idintervenienteglobal)
          this.dialogApagarInterveniente = false
          this.getMarcacao()
          this.todosIntervenientesTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente removido com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a remover interveniente"
          });
      }
    },
    async intervenienteNaoTerminada (id) {
      try{
          this.editarIntervenientes = (await MarcacoesFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = false
          this.editarIntervenientes.concluido_data = null
          await MarcacoesFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesNaoTerminados()
          this.getMarcacao()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente não concluiu a marcacao"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar interveniente"
          });
      }
    },
    async intervenienteTerminada (id) {
      try{
          this.editarIntervenientes = (await MarcacoesFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = true
          this.editarIntervenientes.concluido_data = this.formatarDataParaISO(new Date())
          await MarcacoesFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesTerminados()
          this.getMarcacao()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente concluiu a marcacao"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar interveniente"
          });
      }
    },
    async filtrarMarcacoesDatasFuncionario () {
      this.datafiminserida = this.datainicioinserida
      this.marcacoesdiafuncionario = (await MarcacoesFuncionarioService.marcacoesdiafuncionario(this.datainicioinserida)).data
    },
    toggleIndesmarcavel() {
      this.criarMarcacoes.indesmarcavel = !this.criarMarcacoes.indesmarcavel;
    },
    toggleInstalacoesInfordio() {
      this.criarMarcacoes.instalacoes_infordio = !this.criarMarcacoes.instalacoes_infordio;
    }
  }
}
</script>
<style>
</style>