<template>
  <v-form ref="form">
    <v-container fluid v-if="$store.state.isUserLoggedIn && this.$store.state.tarefa != null || this.$store.state.tarefa.idtarefa != null">
      <v-row v-if="loading == false">
        <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Tarefa</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verTempos2" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-timer</v-icon>
              </template>
              <span>Tempos</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verObservacoes" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-note</v-icon>
              </template>
              <span>Observações</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verMarcacoes" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-calendar-month</v-icon>
              </template>
              <span>Marcações</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verProjeto" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-file-cad</v-icon>
              </template>
              <span>Projeto</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-chart-timeline-variant</v-icon>
              </template>
              <span>Logs</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-message-processing</v-icon>
              </template>
              <span>Mensagens</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verEquipamento" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-monitor</v-icon>
              </template>
              <span>Equipamento</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verPats" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-cog</v-icon>
              </template>
              <span>Pats</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="verArtigos" v-bind="attrs" v-on="on" class="ml-5 d-none d-lg-block">mdi-package-variant-closed</v-icon>
              </template>
              <span>Artigos</span>
            </v-tooltip>
            <div v-if="editarTarefas.concluido == false" class="d-none d-lg-block" style="height: 50%; width: 20px; border-right: 3px solid white;"></div>
            <v-tooltip bottom v-if="editarTarefas.concluido == false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="dialogCriarInterveniente = true" v-bind="attrs" v-on="on" class="ml-5">mdi-account-multiple-plus</v-icon>
              </template>
              <span>Adicionar intervenientes</span>
            </v-tooltip>
            <v-tooltip bottom v-if="editarTarefas.concluido == false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="apagarTarefa(editarTarefas.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
              </template>
              <span>Apagar tarefa</span>
            </v-tooltip>
            <v-tooltip bottom v-if="editarTarefas.concluido == false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="editarTarefa" v-bind="attrs" v-on="on" class="ml-5">mdi-content-save</v-icon>
              </template>
              <span>Guardar tarefa</span>
            </v-tooltip>
            <!--Mobile-->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-5 d-lg-none">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item @click="verTempos2">
                  <v-list-item-title>Tempos</v-list-item-title>
                </v-list-item>
                <v-list-item @click="verObservacoes">
                  <v-list-item-title>Observações</v-list-item-title>
                </v-list-item>
                <v-list-item @click="verMarcacoes">
                  <v-list-item-title>Marcações</v-list-item-title>
                </v-list-item>
                <v-list-item @click="verProjeto">
                  <v-list-item-title>Projeto</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Logs</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Mensagens</v-list-item-title>
                </v-list-item>
                <v-list-item @click="verEquipamento">
                  <v-list-item-title>Equipamento</v-list-item-title>
                </v-list-item>
                <v-list-item @click="verPats">
                  <v-list-item-title>Pats</v-list-item-title>
                </v-list-item>
                <v-list-item @click="verArtigos">
                  <v-list-item-title>Artigos</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>
        </v-col>
        <span id="mobile-margintop-conteudo"></span>
        <v-col cols="12">
          <h2>{{ editarTarefas.numero + '/' + editarTarefas.ano }}</h2>
          <span>{{ apresentarData(editarTarefas.data_registo) }} {{ apresentarHoras(editarTarefas.data_registo) }}</span>
          <h3>{{ editarTarefas.nome_cliente }}</h3>
          <h5>Registado por {{ editarTarefas.quemRegistou.nome }}</h5>
        </v-col>
        
        <v-col cols="12">
          <label>Descrição</label>
          <wysiwyg v-model="editarTarefas.descricao"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field type="text" v-model="editarTarefas.quem_pediu" clearable label="Quem Pediu"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="editarTarefas.como_pediu" label="Pedido Como" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="editarTarefas.grau_urgencia" label="Urgência" :items="['Alta', 'Média', 'Baixa']"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="editarTarefas.id_topico" label="Tópico" :items="topicos" item-text="descricao" item-value="id"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-if="editarTarefas.concluido == false" v-model="editarTarefas.id_estado" label="Estado" :items="estados" item-text="descricao" item-value="id"></v-select>
          <v-text-field v-else type="text" value="Terminada" disabled></v-text-field>
        </v-col>
        <v-col cols="12" md="2" class="d-none d-md-block"></v-col>
        <v-col cols="12" md="2">
          <InputDate v-model="editarTarefas.data_inicio" placeholder="Data de Início" dateFormat="dd/mm/yy"/>
        </v-col>
        <v-col cols="12" md="2">
          <InputDate v-model="editarTarefas.data_fim" placeholder="Data de Fim" dateFormat="dd/mm/yy"/>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="text-center">
              <label>INTERVENIENTES </label>
            </v-col>
            <v-col cols="12 pt-0" v-for="funcionario in editarTarefas.Tarefas_Funcionarios" :key="funcionario.id">
              <v-list class="interve">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{funcionario.Funcionario.nome}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="funcionario.enviado_mail == true">
                    <img :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado" class="mr-5">
                  </v-list-item-action>

                  <v-tooltip bottom v-if="funcionario.concluido == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" @click="intervenienteNaoTerminada(funcionario.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-check</v-icon>
                    </template>
                    <span>Concluída</span>
                  </v-tooltip>

                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" @click="intervenienteTerminada(funcionario.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-close</v-icon>
                    </template>
                    <span>Não concluída</span>
                  </v-tooltip>


                  <v-tooltip bottom v-if="editarTarefas.concluido == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" @click="apagarInterveniente(funcionario.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
                    </template>
                    <span>Apagar interveniente</span>
                  </v-tooltip>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>    
    
        <MyModalTempos ref="mymodaltempos" />
        <MyModalObservacoes ref="mymodalobservacoes" />
        <MyModalProjetos ref="mymodalprojetos" />
        <MyModalMarcacoes ref="mymodalmarcacoes" />
        <MyModalEquipamentos ref="mymodalequipamentos" />
        <MyModalArtigos ref="mymodalartigos" />

        <!-- Dialog apagar tarefa -->
        <v-dialog v-model="dialogApagarTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarTarefa">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver tempos -->
        <v-dialog v-model="dialogVerTempos">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Tempos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerTempos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                    <span class="totais-size"><b>Total tempo: {{totaltempostarefas}}</b></span>
                </v-col>
                <v-col cols="2 text-right" v-if="editarTarefas.concluido == false">
                  <img @click="navigateToTempoCriar({name: 'criartempostarefas', params:{idTarefaEnviado: idtarefaglobal}})" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Tempos.png" alt="Add_Tempos">
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersTempos" :items="tempos">
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.trabalho_realizado`]="{ item }">
                      <span v-if="item.trabalho_realizado != null" v-html="item.trabalho_realizado.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.data_inicio`]="{ item }">
                      <span v-if="item.data_inicio != null">{{ apresentarData(item.data_inicio) }} {{ apresentarHoras(item.data_inicio) }}</span>
                    </template>
                    <template v-slot:[`item.data_fim`]="{ item }">
                      <span v-if="item.data_fim != null">{{ apresentarData(item.data_fim) }} {{ apresentarHoras(item.data_fim) }}</span>
                    </template>
                    <template v-slot:[`item.tempo_efetivo`]="{ item }">
                      <span v-if="item.tempo_efetivo != null">{{ apresentarHoras(item.tempo_efetivo) }}</span>
                    </template>
                    <template v-slot:[`item.concluido`]="{ item }">
                      <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                      <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>                    
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="verTempo(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
                      <img v-if="item.concluido != true && item.stamp_intervencao == null" @click="navigateToTempoEditar({name: 'editartempostarefas', params:{idTempoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img v-if="item.concluido == true && item.stamp_intervencao == null" @click="navigateToTempoModificar({name: 'modificartempos', params:{idTempoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img v-if="item.stamp_intervencao == null" @click="apagarTempo(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                      <img v-if="item.stamp_intervencao != null && item.intervencao_faturado == false" @click="apagarIntervencaoPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar_Intervencao.png" alt="Apagar_Intervencao">
                      <img v-if="item.stamp_intervencao == null && item.concluido == true" @click="verPatsCliente(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Intervencao.png" alt="Add_Intervencao">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog ver tempo -->
        <v-dialog v-model="dialogVerTempo">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Visualizar tempo</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Início</label>
                    <v-text-field v-if="tempo.data_inicio != null" :value="apresentarData(tempo.data_inicio) + ' ' + apresentarHoras(tempo.data_inicio)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Fim</label>
                    <v-text-field v-if="tempo.data_fim != null" :value="apresentarData(tempo.data_fim) + ' ' + apresentarHoras(tempo.data_fim)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Tempo total</label>
                    <v-text-field v-if="tempo.tempo_efetivo != null" :value="apresentarHoras(tempo.tempo_efetivo)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Deslocação/Remoto/Telefone do tempo</label><br>
                    <v-icon v-if="tempo.deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                    <v-icon v-if="tempo.remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                    <v-icon v-if="tempo.telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                  </v-col>
                  <v-col cols="12">
                    <label>Funcionário</label>
                    <v-text-field v-model="tempo.Funcionario.nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="tempo.descricao"/>
                  </v-col>
                  <v-col cols="12">
                    <label>Trabalho Realizado</label>
                    <wysiwyg v-model="tempo.trabalho_realizado"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar tempo -->
        <v-dialog v-model="dialogApagarTempo">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer>Apagar tempo</v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o tempo?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarTempo">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar interveniente -->
        <v-dialog v-model="dialogApagarInterveniente">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o interveniente?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarInterveniente">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
       
        <!-- Dialog ver pats -->
        <v-dialog v-model="dialogVerPats">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Pats tarefa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerPats = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersPats" :items="pats">
                    <template v-slot:[`item.actions`]="{ item }">
                      <img v-if="item.fechado == false" @click="editarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog ver pats cliente -->
        <v-dialog v-model="dialogVerPatsCliente">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Pats abertas do cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerPatsCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <label>Faturar intervenção</label>
                  <v-switch v-model="criarIntervencao.faturar" color="primary" inset></v-switch>
                </v-col>
                <v-col cols="4 text-right" v-if="editarTarefas.id_equipamento != null">
                  <img @click="criarPatIntervencao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Pats.png" alt="Add_Pats">
                </v-col>
                <v-col cols="4 text-right" v-if="editarTarefas.id_equipamento == null">
                  <span>Tarefa sem equipamento não podes criar pat</span>
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersPatsCliente" :items="patscliente">
                    <template v-slot:[`item.actions`]="{ item }">
                      <img v-if="item.fechado == false" @click="editarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img @click="adicionarIntervencao(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                      <img v-if="item.fechado == false && item.tem_intervencoes == false" @click="apagarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar intervenção -->
        <v-dialog v-model="dialogAdicionarIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar intervenção</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo adicionar intervenção a esta pat?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" class="white--text" @click="confirmarAdicionarIntervencao">Adicionar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar intervenção -->
        <v-dialog v-model="dialogApagarIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar intervenção do erp</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a intervenção do erp que está nesta pat?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarIntervencao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar pat -->
        <v-dialog v-model="dialogEditarPat">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar pat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Estado</label>
                <v-select v-model="editarPats.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarPat">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog criar pat -->
        <v-dialog v-model="dialogCriarPatIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
                <v-toolbar-title>Criar pat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarPatIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Equipamento</label>
                <v-text-field v-model="criarPatsIntervencao.stamp_equipamento" disabled filled outlined></v-text-field>
            </v-col>
            <v-col cols="12">
                <label>Estado</label>
                <v-select v-model="criarPatsIntervencao.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarPatIntervencao">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar pat -->
        <v-dialog v-model="dialogApagarPat">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar pat do erp</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a pat do erp?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarPat">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-bottom-sheet v-model="dialogCriarInterveniente">
          <v-container fluid>
            <v-row>
              <v-col cols="12 mb-3">
                <v-chip-group v-model="intervenientesSelecionados" multiple column>
                  <v-chip v-for="tecnico in tecnicos" :key="tecnico.id" :value="tecnico" class="mr-2 mt-3" :color="intervenientesSelecionados.includes(tecnico) ? 'primary' : ''">
                    {{ tecnico.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12 mb-3" class="text-right" v-if="intervenientesSelecionados != ''">
                <v-btn color="primary" class="white--text" @click="confirmarcriarInterveniente">Criar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-bottom-sheet>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template> 

<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import EstadosService from '@/services/EstadosService'
import TemposService from '@/services/TemposService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import EquipamentosService from '@/services/EquipamentosService'
import PatsService from '@/services/PatsService'
import EstadosPatsService from '@/services/EstadosPatsService'
import MyModalTempos from "@/components/Tempos/MyModalTempos.vue";
import MyModalObservacoes from "@/components/Observacoes/MyModalObservacoes.vue";
import MyModalProjetos from "@/components/Projetos/MyModalProjetos.vue";
import MyModalMarcacoes from "@/components/Marcacoes/MyModalMarcacoes.vue";
import MyModalArtigos from "@/components/Artigos/MyModalArtigos.vue";
import MyModalEquipamentos from "@/components/Equipamentos/MyModalEquipamentos.vue";
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  components: {
    MyModalTempos,
    MyModalObservacoes,
    MyModalProjetos,
    MyModalMarcacoes,
    MyModalEquipamentos,
    MyModalArtigos
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.tarefa == null || this.$store.state.tarefa.idtarefa == null) {
      this.$router.push({
        name: 'tarefas'
      })
    } else {
      this.loading = true
      this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data
      if (this.editarTarefas.concluido != true) {
        this.todosIntervenientesTerminados()
      }
      this.loading = false
    }
  },
  data () {
    return {
      loading: true,
      estados: [],
      estadospats: [],
      topicos: [],
      tecnicos: [],
      tempos: [],
      marcacoes: [],
      projetos: [],
      artigostarefa: [],
      artigos: [],
      artigo: {},
      projetoscliente: [],
      equipamentos: [],
      equipamentoscliente: [],
      pats: [],
      patscliente: [],
      tempo: {
        Funcionario: {}
      },
      intervenientesSelecionados: [],
      verIntervenientes: [],
      editarTarefas: {
        numero_cliente: null,
        nome_cliente: null,
        quemRegistou: {},
        data_registo: null,
        quem_pediu: null,
        como_pediu: null,
        descricao: null,
        grau_urgencia: null,
        id_topico: null,
        id_estado: null,
        id_projeto: null,
        id_equipamento: null,
        data_inicio: null,
        data_fim: null,
        numero_pat: null
      },
      criarIntervenientes: {
        id_tarefa: this.$store.state.tarefa.idtarefa,
        id_funcionario: null,
        data_registo: null,
        quem_registou: this.$store.state.user.id,
        lido: false
      },
      editarIntervenientes: {
          concluido: null,
          concluido_data: null
      },
      editarFuncionarios: {
          telefone: null,
          remoto: null,
          deslocacao: null,
          estado: null
      },
      criarIntervencao: {
        stamp_pat: null,
        numero_tecnico: this.$store.state.user.numero_tecnico,
        relatorio: null,
        tipo_intervencao: null,
        faturar: true,
        id_tempo: null,
        id_tarefa: this.$store.state.tarefa.idtarefa,
      },
      criarPatsIntervencao: {
        stamp_equipamento: null,
        estado_pat: null,
        numero_tecnico: this.$store.state.user.numero_tecnico,
        problema: null,
        numero_cliente: null,
        tipo_intervencao: null,
        faturar: null,
        id_tempo: null,
        id_tarefa: null,
        relatorio: null
      },
      criarEquipamentos: {
          serial: null,
          serial2: null,
          id_equipamentos_tipo: null,
          id_equipamentos_marca: null,
          id_equipamentos_modelo: null,
          data_venda: null,
          data_fim_garantia: null,
          numero_cliente: null,
          descricao: null,
          inativo: null,
          local: null,
      },
      tempoApagarIntervencao: {
        id_tempo: null,
        stamp_intervencao: null,
        id_tarefa: this.$store.state.tarefa.idtarefa,
        stamp_pat: null
      },
      editarPats: {
        estado_pat: null
      },
      


      dialogVerArtigosTarefas: false,
      dialogEditarObservacao: false,
      dialogCriarInterveniente: false,
      dialogApagarInterveniente: false,
      dialogVerTempos: false,
      dialogVerTempo: false,
      dialogApagarTempo: false,
      dialogVerPats: false,
      dialogVerPatsCliente: false,
      dialogAdicionarIntervencao: false,
      dialogApagarIntervencao: false,
      dialogEditarPat: false,
      dialogCriarPatIntervencao: false,
      dialogApagarPat: false,
      dialogApagarTarefa: false,
      

      idtarefaglobal: '',
      idobservacaoglobal: '',
      idintervenienteglobal: '',
      idtempoglobal: '',
      idmarcacaoglobal: '',
      idprojetoglobal: '',
      idequipamentoglobal: '',
      idartigotarefaglobal: '',
      patglobal: '',

      totaltempostarefas: '',

      headersTempos: [
        { text: "Funcionário", value: "Funcionario.nome", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Trabalho Realizado", value: "trabalho_realizado", sortable: false },
        { text: "Inicio", value: "data_inicio", sortable: false },
        { text: "Fim", value: "data_fim", sortable: false },
        { text: "Tempo", value: "tempo_efetivo", sortable: false },
        { text: "P.A.T", value: "numero_pat", sortable: false },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
     


      headersPats: [
        { text: "Nº PAT", value: "numero_pat", sortable: true },
        { text: "Cliente", value: "nome_cliente", sortable: true }, 
        { text: "Recebido por", value: "recebido_por", sortable: true },
        { text: "Equipamento", value: "equipamento", sortable: true },
        { text: "Nº série", value: "equipamento_serie", sortable: true },
        { text: "Tipo de assistência", value: "tipo_pat", sortable: true },
        { text: "Estado PAT", value: "estado_pat", sortable: true },
        { text: "Problema", value: "problema", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersPatsCliente: [
        { text: "Nº PAT", value: "numero_pat", sortable: true },
        { text: "Cliente", value: "nome_cliente", sortable: true },
        { text: "Recebido por", value: "recebido_por", sortable: true },
        { text: "Equipamento", value: "equipamento", sortable: true },
        { text: "Nº série", value: "equipamento_serie", sortable: true },
        { text: "Tipo de assistência", value: "tipo_pat", sortable: true },
        { text: "Estado PAT", value: "estado_pat", sortable: true },
        { text: "Problema", value: "problema", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      
      
      rules: {
        datamenor: value => {
          if (this.editarTarefas.data_fim) {
            if (value > this.editarTarefas.data_fim) {
              return "Data de início deve ser menor que a data de fim.";
            }
          }
          return true;
        },
        datamaior: value => {
          if (this.editarTarefas.data_inicio) {
            if (value < this.editarTarefas.data_inicio) {
              return "Data fim deve ser maior que a data de início.";
            }
          }
          return true;
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push('tarefas')
    },
    formatarDataParaISO(data) {
      if (data instanceof Date) {
        return moment(data).tz('Europe/Lisbon').toISOString();
      }
      return null;
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 16)
    },
    navigateToTempoCriar (route) {
      this.$store.dispatch('setTempoTarefa', {  
        idtarefatempo: route.params.idTarefaEnviado,
      })
      this.$router.push(route)
    },
    navigateToTempoEditar (route) {
      this.$store.dispatch('setTempo', {  
          idtempo: route.params.idTempoEnviado,
          finalizadomobile: 1
      })
      this.$router.push(route)
    },
    navigateToTempoModificar (route) {
      this.$store.dispatch('setTempo', {  
          idtempo: route.params.idTempoEnviado,
          finalizadomobile: null
      })
      this.$router.push(route)
    },
    navigateToMarcacaoCriar (route) {
      this.$store.dispatch('setMarcacaoTarefa', {  
          tarefamarcacao: this.editarTarefas
      })
      this.$router.push(route)
    },
    navigateToMarcacaoEditar (route) {
      this.$store.dispatch('setMarcacao', {  
        idmarcacao: route.params.idMarcacaoEnviado,
      })
      this.$router.push(route)
    },
    navigateToProjetoEditar (route) {
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idProjetoEnviado,
      })
      this.$router.push(route)
    },
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    async editarTarefa () {
      try{
        if (this.$refs.form.validate()) {
          await TarefasService.put(this.editarTarefas)
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa editada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar tarefa"
          });
      }
    },
    async simTarefaTerminada () {
      try{
        if (this.$refs.form.validate()) {
          this.editarTarefas.concluido = true
          this.editarTarefas.concluido_data = this.formatarDataParaISO(Date.now()),
          this.editarTarefas.id_estado = 6
          await TarefasService.put(this.editarTarefas)
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa terminada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar a tarefa"
          });
      }
    },
    async todosIntervenientesTerminados () {
      //ver se os intervenientes estão todos concluidos
      this.verIntervenientes = (await TarefasFuncionarioService.index(this.$store.state.tarefa.idtarefa)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido == this.verIntervenientes.length) {
          this.simTarefaTerminada()
        }
      }
    },
    async todosIntervenientesNaoTerminados () {
      //colocar a tareda como não concluida pois tiramos algum interveniente de concluido
      this.verIntervenientes = (await TarefasFuncionarioService.index(this.$store.state.tarefa.idtarefa)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido != this.verIntervenientes.length) {
          this.editarTarefas.concluido = false
          this.editarTarefas.concluido_data = null
          this.editarTarefas.id_estado = 1
          await TarefasService.put(this.editarTarefas)
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
        }
      }
    },
    async apagarTarefa (item) {
      this.dialogApagarTarefa = true
      this.idtarefaglobal = item
    },
    async confirmarApagarTarefa () {
      try{
        await TarefasService.delete(this.idtarefaglobal)
        this.dialogApagarTarefa = false
        if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
          this.$router.push('tarefas')
        } else {
          this.$store.dispatch('setCliente', {  
            numerocliente: this.$store.state.cliente.numerocliente,
          })
          this.$router.push('tarefas')
        }
        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tarefa apagada com sucesso"
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Erro a apagar tarefa, pode ter coisas associadas"
        });
      }
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    async verObservacoes () {
      this.$refs.mymodalobservacoes.open(1, this.editarTarefas);
    },
    
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    async verTempos2 () {
      this.$refs.mymodaltempos.open(this.$store.state.tarefa.idtarefa);
    },
    async verTempos () {
      this.idtarefaglobal = this.$store.state.tarefa.idtarefa
      this.dialogVerTempos = true
      this.tempos = (await TemposService.index(this.$store.state.tarefa.idtarefa)).data
      var totalSegundos = 0; // Inicializa o total de segundos

      for (var tt = 0; tt < this.tempos.length; tt++) {
        var tempoefetivo = this.apresentarHoras(this.tempos[tt].tempo_efetivo);
        var partesTempo = tempoefetivo.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
        var horas = parseInt(partesTempo[0], 10);
        var minutos = parseInt(partesTempo[1], 10);
        var segundos = parseInt(partesTempo[2], 10);

        // Converte tudo para segundos e soma
        totalSegundos += horas * 3600 + minutos * 60 + segundos;
      }

      // Converte o total de segundos de volta para o formato de tempo desejado
      var totalFormatado = this.formatarTempo(totalSegundos);

      this.totaltempostarefas = totalFormatado;
    },
    
    async verTempo (idTempo) {
      this.dialogVerTempo = true
      this.tempo = (await TemposService.show(idTempo)).data
    },

    // Função para formatar o tempo no formato HH:mm:ss
    formatarTempo(totalSegundos) {
      var horas = Math.floor(totalSegundos / 3600);
      var minutos = Math.floor((totalSegundos % 3600) / 60);
      var segundos = totalSegundos % 60;

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
    },
    async apagarTempo (item) {
      this.dialogApagarTempo = true
      this.idtempoglobal = item.id
    },
    async confirmarApagarTempo () {
      try{
        await TemposService.delete(this.idtempoglobal)
        this.dialogApagarTempo = false
        this.verTempos()

        //ver se este utilizador tem tempos abertos, se não tiver mete na tabela dos Funcionarios telefone, remoto, deslocao a false, Livre e cliente vazio, se não for vazio mete na coluna cliente os clientes que estão a ser tratados
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data
        const temposabertos = (await TemposService.index(null, null, null, this.$store.state.user.id, null, false)).data
        if (temposabertos == '') {
            this.editarFuncionarios.telefone = false
            this.editarFuncionarios.remoto = false
            this.editarFuncionarios.deslocacao = false
            this.editarFuncionarios.estado = 'Livre'
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
        } else {
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
            for (var i = 0; i < temposabertos.length; i++) {
                this.editarFuncionarios.cliente = this.editarFuncionarios.cliente + temposabertos[i].nome_cliente + '; '
            }  
            await FuncionariosService.put(this.editarFuncionarios) 
        }

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo apagado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar tempo"
          });
      }
    },
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    async confirmarcriarInterveniente () {
      try{
          for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
            this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
            this.criarIntervenientes.data_registo = this.formatarDataParaISO(new Date())
            await TarefasFuncionarioService.post(this.criarIntervenientes)
          }
          this.intervenientesSelecionados = []
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.dialogCriarInterveniente = false
          this.todosIntervenientesNaoTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente criado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar interveniente"
          });
      }
    },
    async apagarInterveniente (id) {
      this.dialogApagarInterveniente = true
      this.idintervenienteglobal = id
    },
    async confirmarApagarInterveniente () {
      try{
          await TarefasFuncionarioService.delete(this.idintervenienteglobal)
          this.dialogApagarInterveniente = false
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.todosIntervenientesTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente apagado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar interveniente"
          });
      }
    },
    async intervenienteNaoTerminada (id) {
      try{
          this.editarIntervenientes = (await TarefasFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = false
          this.editarIntervenientes.concluido_data = null
          await TarefasFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesNaoTerminados()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente não concluiu a tarefa"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar interveniente"
          });
      }
    },
    async intervenienteTerminada (id) {
      try{
          this.editarIntervenientes = (await TarefasFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = true
          this.editarIntervenientes.concluido_data = this.formatarDataParaISO(Date.now()),
          await TarefasFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesTerminados()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente concluiu a tarefa"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar interveniente"
          });
      }
    },
    //-------------------------------------------marcacoes------------------------------//
    //-------------------------------------------marcacoes------------------------------//
    //-------------------------------------------marcacoes------------------------------//
    async verMarcacoes () {
      this.$refs.mymodalmarcacoes.open(this.editarTarefas);
    },
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    async verProjeto () {
      this.$refs.mymodalprojetos.open(this.editarTarefas);
    },
    
    //-------------------------------------------equipamentos------------------------------//
    //-------------------------------------------equipamentos------------------------------//
    //-------------------------------------------equipamentos------------------------------//
    async verEquipamento () {
      this.$refs.mymodalequipamentos.open(this.editarTarefas);
    },
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    async verPats () {
      this.dialogVerPats = true
      if (this.editarTarefas.stamp_pat != null) {
        this.pats = (await PatsService.importarpatserp(null, this.editarTarefas.stamp_pat, null)).data
      } 
    },
    async verPatsCliente (item) {
      this.criarIntervencao.id_tempo = item.id
      if (item.deslocacao == true) {
        this.criarIntervencao.tipo_intervencao = 'Deslocação a Cliente'
      } else {
        if (item.remoto == true) {
          this.criarIntervencao.tipo_intervencao = 'Assistência Remota'
        } else {
          if (item.telefone == true) {
            this.criarIntervencao.tipo_intervencao = 'Assistência Telefónica'
          } else {
            this.criarIntervencao.tipo_intervencao = 'Assistência Interna'
          }
        }
      }    
      this.criarIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + item.trabalho_realizado

      this.dialogVerPatsCliente = true
      this.patscliente = (await PatsService.importarpatserp(this.editarTarefas.numero_cliente, null, false)).data
    },
    async adicionarIntervencao (item) {
      this.dialogAdicionarIntervencao = true
      this.criarIntervencao.stamp_pat = item.stamp_pat
    },
    async confirmarAdicionarIntervencao () {
      try{
        const response = await PatsService.criarintervencoeserp(this.criarIntervencao)

        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.verTempos()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.dialogAdicionarIntervencao = false
          this.dialogVerPatsCliente = false

          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Intervenção adicionada à pat com sucesso"
          });
        }
        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a adicionar intervenção à pat"
          });
      }
    },
    async apagarIntervencaoPat (item) {
      this.dialogApagarIntervencao = true
      this.tempoApagarIntervencao.id_tempo = item.id
      this.tempoApagarIntervencao.stamp_intervencao = item.stamp_intervencao
      this.tempoApagarIntervencao.stamp_pat = item.stamp_pat
    },
    async confirmarApagarIntervencao () {
      try{
        //Apagar intervenção da pat
        const response = await PatsService.apagarintervencoeserp(this.tempoApagarIntervencao)
        
        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.verTempos()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.dialogApagarIntervencao = false

          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Intervenção apagada da pat com sucesso"
          });
        }

        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar intervenção da pat"
          });
      }
    },
    async editarPat (item) {
      this.editarPats = (await PatsService.verpatserp(item.stamp_pat)).data
      this.estadospats = (await EstadosPatsService.index()).data
      this.dialogEditarPat = true
    },
    async confirmarEditarPat () {
      try{
        //Editar pat
        const response = await PatsService.editarpatserp(this.editarPats)    

        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.patscliente = (await PatsService.importarpatserp(this.editarTarefas.numero_cliente, null, false)).data
          if (this.editarTarefas.stamp_pat != null) {
            this.pats = (await PatsService.importarpatserp(null, this.editarTarefas.stamp_pat, null)).data
          }
          this.dialogEditarPat = false

          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Pat editada com sucesso"
          });
        }
        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar pat"
          });
      }
    },
    async criarPatIntervencao () {
      this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.editarTarefas.id_equipamento)).data
      this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].serial
      this.estadospats = (await EstadosPatsService.index()).data
      this.dialogCriarPatIntervencao = true
    },
    async confirmarCriarPatIntervencao () {
      try{
        this.criarPatsIntervencao.numero_cliente = this.editarTarefas.numero_cliente
        this.criarPatsIntervencao.problema = this.editarTarefas.descricao
        this.criarPatsIntervencao.faturar = this.criarIntervencao.faturar
        this.criarPatsIntervencao.tipo_intervencao = this.criarIntervencao.tipo_intervencao
        this.criarPatsIntervencao.id_tempo = this.criarIntervencao.id_tempo
        this.criarPatsIntervencao.id_tarefa = this.criarIntervencao.id_tarefa
        this.criarPatsIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + this.criarIntervencao.relatorio
        this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].stamp_equipamento
        const response = await PatsService.criarpatsintervencaoerp(this.criarPatsIntervencao)

        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.dialogCriarPatIntervencao = false
          this.dialogVerPatsCliente = false
          this.verTempos()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Pat criada com sucesso"
          });
        }     
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar pat"
          });
      }
    },
    async apagarPat (item) {
      this.dialogApagarPat = true
      this.patglobal = item
    },
    async confirmarApagarPat () {
      try{
        //Apagar pat do erp
        const response = await PatsService.apagarpatserp(this.patglobal)
        
        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.patscliente = (await PatsService.importarpatserp(this.editarTarefas.numero_cliente, null, false)).data
          this.dialogApagarPat = false
          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Pat apagada do erp com sucesso"
          });
        }

        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar pat do erp"
          });
      }
    },
    //-------------------------------------------artigos------------------------------//
    //-------------------------------------------artigos------------------------------//
    //-------------------------------------------artigos------------------------------//
    async verArtigos () {
      this.$refs.mymodalartigos.open(this.editarTarefas);
    },
  }
}
</script>
<style>
</style>