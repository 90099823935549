import Api from '@/services/Api'

export default {
    index (id_funcionario, data_inicio, data_fim) {
      return Api().get('calendario', {
        params: {
          id_funcionario,
          data_inicio,
          data_fim
        }
      })
    },
}
