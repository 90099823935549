<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Marcações do dia</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="marcacoesdiafuncionario.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getMarcacoesDiaFuncionario" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in marcacoesdiafuncionario">
                                    <v-list-item :key="item.id" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                                            <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                                                <span v-for="funcionario in item.Marcacoes_Funcionarios" :key="funcionario.id">
                                                   {{ apresentarData(funcionario.data_inicio) }} - {{ apresentarHoras(funcionario.hora_inicio) }} - {{ apresentarHoras(funcionario.hora_fim) }}<br>
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-if="item.instalacoes_infordio == true">Nas instalações da infordio</v-list-item-action-text>
                                            <v-list-item-action-text v-else>Fora da Infordio</v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < marcacoesdiafuncionario.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>   
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existem marcações</span></v-col> 
                </v-row>
            </v-card-text> 
        </v-card>
    </v-dialog>
</template>
<script>
import MarcacoesService from '@/services/MarcacoesService'
import infiniteScroll from "vue-infinite-scroll";
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            marcacoesdiafuncionario: [],
            idfuncionario: null,
            datainicioinserida: null,
        }
    },
    methods: {
        async open(idfuncionario, datainicioinserida) {
            this.show = true
            this.marcacoesdiafuncionario = []
            this.idfuncionario = idfuncionario
            this.datainicioinserida = datainicioinserida
            this.getMarcacoesDiaFuncionario()
        },
        async getMarcacoesDiaFuncionario() {
            this.loading = true;

            let response = await MarcacoesService.marcacoesdiafuncionariover(this.idfuncionario, this.datainicioinserida)
            this.marcacoesdiafuncionario = this.marcacoesdiafuncionario.concat(response.data);
           
            this.loading = false;
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
    }
}
</script>