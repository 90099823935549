import Api from '@/services/Api'

export default {
  index (inativo, codigo, descricao) {
    return Api().get('artigos',{
      params: {
        inativo,
        codigo,
        descricao
      }
    })
  },
  post (elementos) {
    return Api().post('artigos', elementos)
  },
  put (elementos, id) {
    return Api().put(`artigos/${id}`, elementos)
  },
  show (id) {
    return Api().get(`artigos/${id}`)
  },
  delete (id) {
    return Api().delete(`artigos/${id}`)
  },
  deleteall () {
    return Api().delete(`artigos`)
  },


  //IMPORTAR ERP'S
  importarartigoserp (codigo) {
    return Api().get('importarartigoserp', {
      params: {
        codigo
      }
    })
  },

  criarartigoserp (elementos) {
    return Api().post('criarartigoserp', elementos)
  },

  apagarartigoserp (elementos) {
    return Api().delete(`apagarartigosserp/${elementos.codigo}`)
  },
}
