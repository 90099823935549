<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Observações</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="criarObservacao" v-bind="attrs" v-on="on" class="ml-5">mdi-plus</v-icon>
                    </template>
                    <span>Criar observação</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="observacoes.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getObservacoes" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in observacoes">
                                    <v-list-item :key="item.id">
                                        <v-list-item-content>
                                            
                                            <v-list-item-subtitle style="font-size: 13px;">{{ apresentarData(item.data_registo) }} {{ apresentarHoras(item.data_registo) }}</v-list-item-subtitle>                          
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.Funcionario.nome }}</v-list-item-title>  
                                            <span style="font-size: 13px;" v-if="item.descricao != null" v-html="item.descricao"></span>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-if="item.enviado_mail == true">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon @click="verObsEmailEnviado(item.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-email-outline</v-icon>
                                                    </template>
                                                    <span>Enviado por email</span>
                                                </v-tooltip>
                                            </v-list-item-action-text>
                                            <v-list-item-action-text v-else></v-list-item-action-text>
                                            <v-list-item-action-text>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon @click="apagarObservacao(item.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
                                                    </template>
                                                    <span>Apagar observação</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon @click="editarObservacao(item.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-pencil-outline</v-icon>
                                                    </template>
                                                    <span>Editar observação</span>
                                                </v-tooltip>
                                            </v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < observacoes.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>   
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existem observações</span></v-col> 
                    <!-- Dialog ver email enviado observação-->
                    <v-dialog v-model="dialogVerObsEmailEnviado">
                        <v-card>
                            <v-toolbar class="mb-2 primary" dark flat>
                                <v-toolbar-title>Emails</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-title><v-list-item @click="dialogVerObsEmailEnviado = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                                <span>Observações da tarefa enviada por e-mail para o(s) seguinte(s) endereço(s)</span><br>
                                <span v-if="observacaoemail.enviado_mail_data != null">{{observacaoemail.enviado_mail_para_quem}} em {{apresentarData(observacaoemail.enviado_mail_data)}} às {{apresentarHoras(observacaoemail.enviado_mail_data)}} horas.</span>
                            </v-col>
                        </v-card>
                    </v-dialog>
                    <!-- Dialog apagar observação -->
                    <v-dialog v-model="dialogApagarObservacao">
                        <v-card>
                            <v-toolbar class="mb-2" color="red" dark flat>
                                <v-toolbar-title>Apagar observação</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-title><v-list-item @click="dialogApagarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                                <span>Quer mesmo apagar a observação?</span>
                            </v-col>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red" class="white--text" @click="confirmarApagarObservacao">Apagar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- Dialog criar observação-->
                    <v-dialog v-model="dialogCriarObservacao">
                        <v-card>
                            <v-toolbar class="mb-2" color="primary" dark flat>
                            <v-toolbar-title>Criar observação</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-title><v-list-item @click="dialogCriarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                                <label>Descrição</label>
                                <wysiwyg v-model="criarObservacoes.descricao"/>
                            </v-col>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" class="white--text" @click="confirmarCriarObservacao">Criar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- Dialog editar observação -->
                    <v-dialog v-model="dialogEditarObservacao">
                        <v-card>
                            <v-toolbar class="mb-2" color="primary" dark flat>
                                <v-toolbar-title>Editar observação</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-title><v-list-item @click="dialogEditarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                            </v-toolbar>
                            <v-col cols="12">
                                <span>{{ apresentarData(editarObservacoes.data_registo) }}</span><br>
                                <span>{{ editarObservacoes.Funcionario.nome }}</span>
                            </v-col>
                            <v-col cols="12">
                                <label>Descrição</label>
                                <wysiwyg v-model="editarObservacoes.descricao"/>
                            </v-col>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" class="white--text" @click="confirmarEditarObservacao">Editar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-card-text> 
        </v-card>
    </v-dialog>
</template>
<script>
import TarefasObsService from '@/services/TarefasObsService'
import MarcacoesObsService from '@/services/MarcacoesObsService'
import ProjetosObsService from '@/services/ProjetosObsService'
import infiniteScroll from "vue-infinite-scroll";
import moment from 'moment-timezone'
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            page: 1,
            busy: false,
            observacoes: [],
            observacaoemail: {},
            criarObservacoes: {
                descricao: '',
                quem_registou: this.$store.state.user.id,
                data_registo: null,
                id_tarefa: null,
                id_marcacao: null
            },
            editarObservacoes: {
                descricao: null,
                Funcionario: {}
            },
            iddeondevem: null,
            deondevemvalue: null,
            idobservacaoglobal: null,

            dialogApagarObservacao: false,
            dialogVerObsEmailEnviado: false,
            dialogCriarObservacao: false,
            dialogEditarObservacao: false
        }
    },
    methods: {
        async open(deondevem, idDeondevem) {
            this.show = true
            this.iddeondevem = idDeondevem.id
            this.deondevemvalue = deondevem
            this.page = 1
            this.observacoes = []
            this.getObservacoes()
        },
        async getObservacoes() {
            this.busy = true;
            this.loading = true;

            let response;
            if (this.deondevemvalue == 1) {
                response = await TarefasObsService.index(
                    this.page,
                    this.iddeondevem
                );
            } else if (this.deondevemvalue == 2) {
                response = await MarcacoesObsService.index(
                    this.page,
                    this.iddeondevem
                );
            } else if (this.deondevemvalue == 3) {
                response = await ProjetosObsService.index(
                    this.page,
                    this.iddeondevem
                );
            }
            this.observacoes = this.observacoes.concat(response.data);
            this.page++;
            this.busy = false;
            if (response.data.length == 0) {
                this.busy = true;
            }
            this.loading = false;
        },
        async verObsEmailEnviado (id) {
            this.dialogVerObsEmailEnviado = true

            if (this.deondevemvalue == 1) {
                this.observacaoemail = (await TarefasObsService.show(id)).data
            } else if (this.deondevemvalue == 2) {
                this.observacaoemail = (await MarcacoesObsService.show(id)).data
            } else if (this.deondevemvalue == 3) {
                this.observacaoemail = (await ProjetosObsService.show(id)).data
            }  
        },
        async apagarObservacao (id) {
            this.dialogApagarObservacao = true
            this.idobservacaoglobal = id
        },
        async confirmarApagarObservacao () {
            try{
                if (this.deondevemvalue == 1) {
                    await TarefasObsService.delete(this.idobservacaoglobal)
                } else if (this.deondevemvalue == 2) {
                    await MarcacoesObsService.delete(this.idobservacaoglobal)
                } else if (this.deondevemvalue == 3) {
                    await ProjetosObsService.delete(this.idobservacaoglobal)
                }
                
                this.dialogApagarObservacao = false
                this.page = 1
                this.observacoes = []
                this.getObservacoes()
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Observação apagada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a apagar observação"
                });
            }
        },
        async criarObservacao () {
            this.dialogCriarObservacao = true
        },
        async confirmarCriarObservacao () {
            try{
                this.criarObservacoes.data_registo = this.formatarDataParaISO(new Date())
                if (this.deondevemvalue == 1) {
                    this.criarObservacoes.id_tarefa = this.iddeondevem
                    await TarefasObsService.post(this.criarObservacoes)
                } else if (this.deondevemvalue == 2) {
                    this.criarObservacoes.id_marcacao = this.iddeondevem
                    await MarcacoesObsService.post(this.criarObservacoes)
                } else if (this.deondevemvalue == 3) {
                    this.criarObservacoes.id_projeto = this.iddeondevem
                    await ProjetosObsService.post(this.criarObservacoes)
                }

                this.criarObservacoes.descricao = ''
                this.dialogCriarObservacao = false
                this.page = 1
                this.observacoes = []
                this.getObservacoes()
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Observação criada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a criar observação"
                });
            }
        },
        async editarObservacao (id) {
            this.dialogEditarObservacao = true
            if (this.deondevemvalue == 1) {
                this.editarObservacoes = (await TarefasObsService.show(id)).data
            } else if (this.deondevemvalue == 2) {
                this.editarObservacoes = (await MarcacoesObsService.show(id)).data
            } else if (this.deondevemvalue == 3) {
                this.editarObservacoes = (await ProjetosObsService.show(id)).data
            }   
        },
        async confirmarEditarObservacao () {
            try{
                if (this.deondevemvalue == 1) {
                    await TarefasObsService.put(this.editarObservacoes)
                } else if (this.deondevemvalue == 2) {
                    await MarcacoesObsService.put(this.editarObservacoes)
                } else if (this.deondevemvalue == 3) {
                    await ProjetosObsService.put(this.editarObservacoes)
                }
                
                this.dialogEditarObservacao = false
                this.page = 1
                this.observacoes = []
                this.getObservacoes()
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Observação editada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a editar observação"
                });
            }
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
        // formatar data
        formatarDataParaISO(data) {
            if (data instanceof Date) {
                return moment(data).tz('Europe/Lisbon').toISOString();
            }
            return null;
        },
    }
}
</script>