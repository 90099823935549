<template>
  <v-container fluid v-if="$store.state.isUserLoggedIn">
    <v-row v-if="loading == false">
      <v-col cols="12" class="p-0">
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
          <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
          <v-app-bar-title>Calendário</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-icon @click.stop="dialogFiltrarCalendario = true" class="ml-5">mdi-magnify</v-icon>
        </v-app-bar>
      </v-col>
      <span id="mobile-margintop-conteudo"></span>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="ma-2" @click="PrevMonth" v-on="on">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ prevDate }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="ma-2" @click="NextMonth" v-on="on">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>{{ nextDate }}</span>
        </v-tooltip>
        {{ title }}
      </v-col>
      <v-col cols="12">
        <div style="height: 650px;">
          <v-calendar ref="calendario" locale="pt-PT" v-model="focus" :weekdays="weekdayCalendar" :type="typeCalendar"
            :interval-count="intervalCountCalendar" :interval-minutes="intervalMinutesCalendar" :first-time=firtTimeCalendar
            @click:date="viewDay" @change="setTitle" :events="eventsCalendar"
          >
          <template v-slot:event="{ event }">
            <div :style="{ backgroundColor: event.colorr, width: '100%', height: '100%' }">
              <span style="padding-left: 5px; color: white;">{{ event.nome_cliente }}</span><br>
              <span style="padding-left: 5px; color: white;">{{ event.nome_funcionario }}</span><br>
              <span style="padding-left: 5px; color: white;">{{ apresentarHoras(event.start) }} - {{ apresentarHoras(event.end) }}</span>
            </div>
          </template>
          </v-calendar>
        </div>
      </v-col>
      
      <!-- Dialog filtrar tarefa -->
      <v-bottom-sheet v-model="dialogFiltrarCalendario">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-select v-model="typeCalendar" item-text="name" item-value="value" label="Tipo de calendário" :items="typeToLabel"></v-select>
            </v-col>
            <v-col cols="12">
              <v-select v-model="intervalMinutesCalendar" item-text="name" item-value="value" label="Tempo do calendário" :items="typeToLabelIntervalTime"></v-select>
            </v-col>
            <v-col cols="12 mb-3">
              <v-chip class="mr-2 mt-3" @click="filtrarFuncionario(null)" :color="filtrarCalendario.id_funcionario === null ? 'primary' : ''">Todos</v-chip>
                <v-chip v-for="tecnico in tecnicos" :key="tecnico.id" class="mr-2 mt-3" @click="filtrarFuncionario(tecnico.id)" :color="filtrarCalendario.id_funcionario === tecnico.id ? 'primary' : ''">
                  {{ tecnico.nome }}
                </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-bottom-sheet>

    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//  Importar o service necessário
import CalendarioService from '@/services/CalendarioService'
import FuncionariosService from '@/services/FuncionariosService'
import store from '@/store/store'

export default {
  watch: {
    intervalMinutesCalendar() {
      this.$nextTick(() => {
        if (this.intervalMinutesCalendar == 15) {
          this.intervalCountCalendar = 40
        } else if (this.intervalMinutesCalendar == 30) {
          this.intervalCountCalendar = 20
        } else {
          this.intervalCountCalendar = 12
        }
        this.getCalendar()
      });
    },
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else {
      this.loading = true
      this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data

      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const nextMonth = (month + 1);
      this.filtrarCalendario.startDate = year + '-' + (nextMonth < 10 ? '0' + nextMonth : nextMonth) + '-01T00:00:00.000Z'
      this.filtrarCalendario.endDate = year + '-' + ((nextMonth + 1) < 10 ? '0' + (nextMonth + 1) : (nextMonth + 1)) + '-01T00:00:00.000Z'

      this.getCalendar()
      this.loading = false
    }
  },
  data () {
    return {
      loading: true,
      focus: '',
      title: "",
      eventsCalendar: [],
      tecnicos: [],
      prevDate: "Semana Anterior",
      nextDate: "Semana Seguinte",
      weekdayCalendar: [1, 2, 3, 4, 5, 6],
      intervalMinutesCalendar: 60,
      intervalCountCalendar: 12,
      typeCalendar: "day",
      firtTimeCalendar: "08:00",

      filtrarCalendario: {
        id_funcionario: this.$store.state.user.id,
        startDate: '',
        endDate: ''
      },

      typeToLabel: [
        { name: "Mês", value: "month" },
        { name: "Semana", value: "week" },
        { name: "Dia", value: "day" }
      ],

      typeToLabelIntervalTime: [
        { name: "15 min", value: 15 },
        { name: "30 min", value: 30 },
        { name: "1 h", value: 60 }
      ],

      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ],

      dialogFiltrarCalendario: false
    }
  },
  methods: {
    formatarData(date) {
      const data = new Date(date);
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');
      const hora = String(data.getHours()).padStart(2, '0');
      const minuto = String(data.getMinutes()).padStart(2, '0');
      return `${ano}-${mes}-${dia} ${hora}:${minuto}`;
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 16)
    },
    goBack() {
      this.$router.push('marcacoes')
    },
    async filtrarFuncionario (id) {
      this.filtrarCalendario.id_funcionario = id
      this.getCalendar();
    },
    async NextMonth() {
      this.$refs.calendario.next();
    },
    async PrevMonth() {
      this.$refs.calendario.prev();
    },
    viewDay() {
      this.typeCalendar = "day";
    },
    async getCalendar() {
      
      this.eventsCalendar = []

      const response = (await CalendarioService.index(
        this.filtrarCalendario.id_funcionario,
        this.filtrarCalendario.startDate,
        this.filtrarCalendario.endDate
      )).data;
      
      let eventosFormatados = response.map(evento => {
        return {
          start: this.formatarData(evento.data_inicio_total),
          end: this.formatarData(evento.data_fim_total),
          nome_funcionario: evento.Funcionario.nome,
          colorr: evento.Funcionario.cor_hexa || '#00000000',
          nome_cliente: evento.Marcaco?.nome_cliente || '',
        };
      });
      
      this.eventsCalendar = this.eventsCalendar.concat(eventosFormatados);
    },
    async setTitle({ start, end }) {
      if (!start || !end) {
        return;
      }
      const startMonth = this.months[start.month - 1];
      const endMonth = this.months[end.month - 1];
      const suffixMonth = endMonth;
      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = endYear;
      const startDay = start.day;
      const endDay = end.day;

      this.filtrarCalendario.startDate = start.year + '-' + (start.month < 10 ? '0' + start.month : start.month) + '-01T00:00:00.000Z'
      this.filtrarCalendario.endDate = start.year + '-' + ((start.month + 1) < 10 ? '0' + (start.month + 1) : (start.month + 1)) + '-01T00:00:00.000Z'

      switch (this.typeCalendar) {
        case "month":
          this.prevDate = "Mês Anterior";
          this.nextDate = "Mês Seguinte";
          this.title = `${startMonth} ${startYear}`;
          break;
        case "week":
          this.prevDate = "Semana Anterior";
          this.nextDate = "Semana Seguinte";
          this.title = `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
          break;
        case "day":
          this.prevDate = "Dia Anterior";
          this.nextDate = "Dia Seguinte";
          this.title = `${startDay} ${startMonth} ${startYear}`;
          break;
      }
    },
  }
}
</script>
<style>
</style>