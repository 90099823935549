<template>
  <v-app id="inspire">
    <v-app-bar v-if="$store.state.isUserLoggedIn" :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title style="width: 300px;">{{$store.state.user.nome}}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon large class="mr-5">
          <v-avatar size="50px" tile>
            <img src="../public/logo_infordio.png" alt="Logotipo Infordio" />
          </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-app-bar v-else :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" app dark>
      <v-app-bar-title style="width: 300px;">Aplicação Tarefas</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon large class="mr-5">
          <v-avatar size="50px" tile>
            <img src="../public/logo_infordio.png" alt="Logotipo Infordio" />
          </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="$store.state.isUserLoggedIn" :clipped="$vuetify.breakpoint.lgAndUp" app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">Aplicação Tarefas</v-list-item-title>
          <v-list-item-subtitle>Infordio</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link @click="navigateTo({name: 'myhome'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Home.png" alt="Home">
          <v-list-item-content><v-list-item-title>Home</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'tarefas'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Tarefas.png" alt="Tarefas">
          <v-list-item-content><v-list-item-title>Tarefas</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'marcacoes'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Marcacoes.png" alt="Marcações">
          <v-list-item-content><v-list-item-title>Marcações</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'projetos'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Projetos.png" alt="Projetos">
          <v-list-item-content><v-list-item-title>Projetos</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'tempos'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Tempos.png" alt="Tempos">
          <v-list-item-content><v-list-item-title>Tempos</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'mensagens'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Mensagens.png" alt="Mensagens">
          <v-list-item-content><v-list-item-title>Mensagens</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'artigos'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Artigos.png" alt="Artigos">
          <v-list-item-content><v-list-item-title>Artigos</v-list-item-title></v-list-item-content>
        </v-list-item>
        <hr>
        <v-list-item link to="clientes">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Clientes.png" alt="Clientes">
          <v-list-item-content><v-list-item-title>Clientes</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="funcionarios">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Funcionarios.png" alt="Funcionários">
          <v-list-item-content><v-list-item-title>Funcionários</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="funcionariosestado">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Funcionarios_Estado.png" alt="Funcionários_Estado">
          <v-list-item-content><v-list-item-title>Funcionários Estado</v-list-item-title></v-list-item-content>
        </v-list-item>
        <hr>
        <v-list-item link to="calendario">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Calendario.png" alt="Calendário">
          <v-list-item-content><v-list-item-title>Calendário</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link @click="navigateTo({name: 'equipamentos'})">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Equipamentos.png" alt="Equipamentos">
          <v-list-item-content><v-list-item-title>Equipamentos</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="tickets">
          <img :width="25" class="m-2" src="./assets/icons_aplicacao/Tickets.png" alt="Tickets">
          <v-list-item-content><v-list-item-title>Tickets&nbsp;&nbsp;<b>({{ tickets.length }})</b></v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="configuracoesmodulos" v-if="configuracoessistema.activo == true">
          <v-list-item-icon><v-icon>mdi-settings</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Configurações módulos</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link to="empresatitular">
          <v-list-item-icon><v-icon>mdi-settings</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Empresa titular</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title @click="logout">Logout</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <router-view></router-view>
      <v-snackbar v-model="snackbar.showing" :timeout="3000" :color="snackbar.color">
        <v-card-actions>
          {{ snackbar.text }}
          <v-spacer></v-spacer>
          <v-btn text @click="closeSnackbar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-snackbar>
    </v-content>
  </v-app>
</template>

<script>
import ServiceTickets from "@/services/TicketsService";
import ConfiguracoesSistemaService from "@/services/ConfiguracoesSistemaService"
export default {
  async mounted () {
    this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data

    //numero de tickets
    const response = await ServiceTickets.list(1, null, null, null, null, null, null);
    response.data.forEach(element => {
      this.tickets.push(element);
    });
  },
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    }
  },
  data: () => ({
    drawer: true,
    configuracoessistema: {},
    tickets: [],
    page: 1,
    busy: false,
  }),
  methods: {
    triggerSnackBar() {
      this.$store.dispatch("setSnackBar", {
        color: "success",
        text: "Tipo criado com sucesso"
      });
    },
    closeSnackbar() {
      this.$store.commit('hideSnackBar');
    },
    navigateTo (route) {
      this.$store.dispatch('setCliente', {
        numerocliente: null,
      })
      this.$store.dispatch('setFuncionarioSelect', {  
        idfuncionario: this.$store.state.user.id,
      })
      
      const currentRouteMatches = 
        (typeof route === 'string' && this.$route.path === route) ||
        (typeof route === 'object' && this.$route.name === route.name);

      if (!currentRouteMatches) {
        this.$router.push(route).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      }
    },
    logout () {
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      this.$router.push({
        name: 'mylogin'
      })
      location.reload()
    }
  },
}
</script>
<style>
/*CSS Página*/
.html, body {
  width: 100% !important;
  overflow-x: hidden !important;
  touch-action: manipulation;
}

@media (max-width: 1263px) {
  #paginas-v-app-bar {
    position: fixed !important;
    z-index: 5 !important;
  }
  #mobile-margintop-conteudo {
    margin-top: 60px !important;
  }
}

.v-app-bar-title__content{
  width: 250px !important;
}
footer .v-btn__content{
  display: block;
}
footer .v-btn {
  font-size: 10px !important;
}
footer .v-btn:before {
  background-color: unset;
}
footer {
  height: 53px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}

/*CSS Global*/
.w-100{
  width: 100% !important;
}
main {
  padding-bottom: 55px !important;
}
.p-0{
  padding: 0px !important;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0px;
}
.pl-5 {
  padding-left: 3rem !important;
}
.m-2 {
  margin: 0.25rem !important;
}
span {
  color: black;
}
.textwhite {
  color: white !important;
}
label {
  color: black;
  font-size: 17px;
}
.editr {
    background: rgba(0,0,0,.06);
    border-radius: 5px;
    border: 1px solid;
    color: rgba(0,0,0,.26);
    font-size: 16px;
}
.theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: black !important;
}
textarea {
  border-style: solid !important;
  width: 100% !important;
}
.theme--light.v-label--is-disabled {
  color: black !important;
}
.theme--light.v-label {
  color: black !important;
}
.v-text-field .v-text-field--enclosed .v-text-field__details {
  padding: 0 0px !important;
}
.vw-btn-image, .vw-btn-removeFormat, .vw-btn-table, .vw-btn-code, .vw-btn-link, .vw-btn-separator, .vw-btn-justifyLeft, .vw-btn-justifyCenter, .vw-btn-justifyRight, .vw-btn-headings{
  display: none !important;
}
.back-obs {
  background-color: #00e1ffab;
}
.back-pref {
  background-color: green;
  color: white;
}
.v-data-table>.v-data-table__wrapper .v-data-table__mobile-table-row{
  border: 1px solid black;
}
.myTabela{
  border: 1px solid black;
}
.myTabela div {
  padding: 5px 16px;
  height: auto;
  min-height: 48px;
}
.myTabela span {
  font-size: .875rem;
}
.myTabela .titulo {
  font-weight: 600;
}
.tirarfundobotao{
  box-shadow: unset !important;
  background-color: unset !important;
}
.tirarfundobotao:hover:before{
  opacity: 0 !important;
}
.totais-size{
  font-size: 20px !important;
}
.totalletra {
    font-size: 25px !important;
}
.errodata {
  color: red !important;
}
.interve {
  padding: 0 !important;
}
.interve .v-list-item {
  padding: 0 !important;
}
.editr--toolbar{
  display: none !important;
}
/*css tempos*/
.botoestemposcriar button, .botoestemposcriar i{
  font-size: 50px !important;
}
.botoestemposeditar button, .botoestemposeditar i{
  font-size: 50px !important;
}
.botoestemposcriar button::after{
  background-color: unset !important;
}
.botoestemposeditar button::after{
  background-color: unset !important;
}
.botoestemposcriar .mdi-play-circle {
  color: green !important;
}
.botoestemposcriar .mdi-stop-circle {
  color: grey !important;
}
.botoestemposeditar .mdi-play-circle {
  color: grey !important;
}
.botoestemposeditar .mdi-stop-circle {
  color: red !important;
}
.retangulotempofim {
  background-color: grey;
  height: 50px;
  line-height: 50px;
}
.retangulotempofim span {
  color: white;
  font-weight: bold;
  font-size: 30px;
}
.tamanho_tabelas_home_scroll{
  height: 250px;
  overflow: auto;
  display: block;
}

/*MYdatetime*/
.vdatetime-popup {
  width: 371px !important;
}
.vdatetime-calendar__month__weekday {
  font-size: 10px !important;
}
/*Alerts*/
.v-alert {
  position: fixed !important;
  bottom: 5% !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  width: 90% !important;
}

/*Cor tables concluidos/anulados*/
.v-application .coranulado {
    background-color: #fa8b8373 !important;
}
.v-application .cornaoconcluido {
    background-color: #fa8b8373 !important;
}
.v-application .corconcluido {
    background-color: #85f8a873 !important;
}

/*retangulos azuis*/
.retangulodetalhes{
  background-color: #1976d2 !important;
  width: 100% !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  cursor: pointer !important;
}
.retangulodetalhes:hover{
  background-color: #13579b !important;
}
.retangulodetalhes span, .retangulodetalhes i{
  color: white !important;
  font-weight: bold !important;
}

/*wyswyg*/
.editr {
  border-radius: unset !important;
  border: 1px solid !important;
  border-color: rgba(0, 0, 0, .42) !important;
}
.editr--content{
  color: black !important;
  background-color: white !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/*input datas*/
.p-calendar {
  display: unset !important;
}
.p-calendar .p-inputtext {
  width: 100% !important;
}
.p-datepicker {
  top: unset !important;
}
.p-datepicker table td > span {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.p-inputtext {
  color: black !important;
  border-radius: unset !important;
  border: unset !important;
  border-bottom: 1px solid rgba(0,0,0,.42) !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 8px !important;
}
.p-inputtext:enabled:focus {
    box-shadow: unset !important;
    border-color: unset !important;
}
</style>
