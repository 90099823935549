<template>
  <v-container fluid v-if="$store.state.isUserLoggedIn">
    <v-row>      
      <v-col cols="12" class="p-0">
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
          <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
          <v-app-bar-title>Tarefas</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-icon @click="navigateToTarefaCriar({name: 'criartarefas'})" class="ml-5">mdi-plus</v-icon>
          <v-icon @click.stop="dialogFiltrarTarefas = true" class="ml-5">mdi-magnify</v-icon>
        </v-app-bar>
      </v-col>
      <span id="mobile-margintop-conteudo"></span>
      <v-col cols="12">
        <v-list twoo-line>
          <v-list-item-group v-infinite-scroll="getTarefas" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >     
            <template v-for="(item, index) in tarefas">
              <v-list-item :key="item.id" @click="navigateToTarefaEditar({name: 'editartarefas', params:{idTarefaEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                <v-list-item-content>
                  <v-list-item-subtitle style="font-size: 13px;">{{ item.numero + '/' + item.ano }}</v-list-item-subtitle>
                  <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                  <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                  <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                    <span v-for="(func, index) in item.Funcionarios" :key="func.id">
                        {{ func.nome }}<span v-if="item.Funcionarios.length > 1 && index < item.Funcionarios.length - 1">;</span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text>{{ apresentarData(item.data_registo) }} {{ apresentarHoras(item.data_registo) }}</v-list-item-action-text>
                  <v-list-item-action-text>{{ item.grau_urgencia }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < tarefas.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-list>
      </v-col>
    
      <!-- Dialog filtrar tarefa -->
      <v-bottom-sheet v-model="dialogFiltrarTarefas">
        <v-container fluid>
          <v-row>
            <v-col cols="12 mt-3">
              <v-chip @click="filtrarNaoConcluidas" :color="filtrarTarefas.concluido == false ? 'primary' : ''" class="mr-2">Por concluir</v-chip>
              <v-chip @click="filtrarConcluidas" :color="filtrarTarefas.concluido == true ? 'primary' : ''" class="mr-2">Concluídas</v-chip>
              <v-chip @click="filtrarTodas" :color="filtrarTarefas.concluido == null ? 'primary' : ''" class="mr-2">Todas</v-chip>
            </v-col>
            <v-col cols="12">
              <v-autocomplete @change="filtrarCliente" v-model="filtrarTarefas.numero_cliente" :items="clientes" item-text="nome" item-value="numero" label="Cliente" clearable></v-autocomplete>
            </v-col>
            <v-col cols="12 mb-3">
              <v-chip class="mr-2 mt-3" @click="filtrarFuncionario(null)" :color="filtrarTarefas.id_funcionario === null ? 'primary' : ''">Todos</v-chip>
                <v-chip v-for="funcionario in funcionarios" :key="funcionario.id" class="mr-2 mt-3" @click="filtrarFuncionario(funcionario.id)" :color="filtrarTarefas.id_funcionario === funcionario.id ? 'primary' : ''">
                  {{ funcionario.nome }}
                </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-bottom-sheet>
      <!-- Dialog ver pats cliente -->
      <v-dialog v-model="dialogVerPatsCliente">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Pats abertas do cliente</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerPatsCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <label>Faturar intervenção</label>
                <v-switch v-model="criarIntervencao.faturar" color="primary" inset></v-switch>
              </v-col>
              <v-col cols="4 text-right" v-if="tarefatempoglobal.id_equipamento != null">
                <img @click="criarPatIntervencao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Pats.png" alt="Add_Pats">
              </v-col>
              <v-col cols="4 text-right" v-if="tarefatempoglobal.id_equipamento == null">
                <span>Tarefa sem equipamento não podes criar pat</span>
              </v-col>
              <v-col cols="12">
                <v-data-table class="elevation-1" :headers="headersPatsCliente" :items="patscliente">
                  <template v-slot:[`item.actions`]="{ item }">
                    <img @click="editarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                    <img @click="adicionarIntervencao(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                    <img v-if="item.fechado == false && item.tem_intervencoes == false" @click="apagarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text> 
        </v-card>
      </v-dialog>
      <!-- Dialog adicionar intervenção -->
      <v-dialog v-model="dialogAdicionarIntervencao">
        <v-card>
          <v-toolbar class="mb-2" color="orange" dark flat>
              <v-toolbar-title>Adicionar intervenção</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogAdicionarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo adicionar intervenção a esta pat?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" class="white--text" @click="confirmarAdicionarIntervencao">Adicionar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar intervenção -->
      <v-dialog v-model="dialogApagarIntervencao">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title>Apagar intervenção do erp</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar a intervenção do erp que está nesta pat?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarIntervencao">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog editar pat -->
      <v-dialog v-model="dialogEditarPat">
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
              <v-toolbar-title>Editar pat</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogEditarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <label>Estado</label>
              <v-select v-model="editarPats.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" class="white--text" @click="confirmarEditarPat">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog criar pat -->
      <v-dialog v-model="dialogCriarPatIntervencao">
        <v-card>
          <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar pat</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogCriarPatIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <label>Equipamento</label>
              <v-text-field v-model="criarPatsIntervencao.stamp_equipamento" disabled filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
              <label>Estado</label>
              <v-select v-model="criarPatsIntervencao.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="purple" class="white--text" @click="confirmarCriarPatIntervencao">Criar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar pat -->
      <v-dialog v-model="dialogApagarPat">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title>Apagar pat do erp</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar a pat do erp?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarPat">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </v-row>
  </v-container>
</template>
  
<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesService from '@/services/ClientesService'
import TemposService from '@/services/TemposService'
import PatsService from '@/services/PatsService'
import EstadosPatsService from '@/services/EstadosPatsService'
import EquipamentosService from '@/services/EquipamentosService'
import store from '@/store/store'
import infiniteScroll from "vue-infinite-scroll";
export default {
  directives: {
    infiniteScroll
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.filtrarTarefas.numero_cliente = this.$store.state.cliente.numerocliente
    this.funcionarios = (await FuncionariosService.index2()).data
    this.clientes = (await ClientesService.index2()).data
  },
  data () {
    return {
      funcionarios: [],
      tarefas: [],
      clientes: [],
      tempos: [],
      patscliente: [],
      estadospats: [],
      pats: [],
      tempo: {
        Funcionario: {}
      },
      filtrarTarefas: {
        concluido: false,
        numero_cliente: null,
        concluido_funcionario: false,
        id_funcionario: this.$store.state.funcionarioselect.idfuncionario
      },
      editarFuncionarios: {
          telefone: null,
          remoto: null,
          deslocacao: null,
          estado: null
      },
      criarIntervencao: {
          stamp_pat: null,
          numero_tecnico: this.$store.state.user.numero_tecnico,
          relatorio: null,
          tipo_intervencao: null,
          faturar: true,
          id_tempo: null,
          id_tarefa: null,   
      },
      criarPatsIntervencao: {
          stamp_equipamento: null,
          estado_pat: null,
          numero_tecnico: this.$store.state.user.numero_tecnico,
          problema: null,
          numero_cliente: null,
          tipo_intervencao: null,
          faturar: null,
          id_tempo: null,
          id_tarefa: null,
          relatorio: null
      },
      tempoApagarIntervencao: {
          id_tempo: null,
          stamp_intervencao: null,
          id_tarefa: null,
          stamp_pat: null
      },
      editarPats: {
          estado_pat: null
      },

      headersPatsCliente: [
          { text: "Nº PAT", value: "numero_pat", sortable: true },
          { text: "Cliente", value: "nome_cliente", sortable: true },
          { text: "Recebido por", value: "recebido_por", sortable: true },
          { text: "Equipamento", value: "equipamento", sortable: true },
          { text: "Nº série", value: "equipamento_serie", sortable: true },
          { text: "Tipo de assistência", value: "tipo_pat", sortable: true },
          { text: "Estado PAT", value: "estado_pat", sortable: true },
          { text: "Problema", value: "problema", sortable: true },
          { text: 'Ações', value: 'actions', sortable: false },
      ],

      dialogFiltrarTarefas: false,
      dialogVerTempo: false,
      dialogApagarTempo: false,
      dialogVerPatsCliente: false,
      dialogAdicionarIntervencao: false,
      dialogApagarIntervencao: false,
      dialogEditarPat: false,
      dialogCriarPatIntervencao: false,
      dialogApagarPat: false,

      idtarefaglobal: '',
      idobservacaoglobal: '',
      idtempoglobal: '',
      totaltempostarefas: '',
      tarefatempoglobal: {
          numero_cliente: null,
          id_equipamento: null,
          descricao: null,
      },
      patglobal: '',

      loading: true,
      page: 1,
      busy: false,
    }
  },
  methods: {
    async getTarefas() {
      this.busy = true;
      this.loading = true;
      const response = await TarefasService.index(
        this.page,
        this.filtrarTarefas.concluido,
        this.filtrarTarefas.numero_cliente,
        this.filtrarTarefas.concluido_funcionario,
        this.filtrarTarefas.id_funcionario
      );
      this.tarefas = this.tarefas.concat(response.data);
      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    goBack() {
      this.$router.push('home')
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 16)
    },
    
    navigateToTarefaEditar (route) {
      this.$store.dispatch('setTarefa', {
        idtarefa: route.params.idTarefaEnviado,
      })
      this.$router.push(route)
    },
    navigateToTarefaCriar (route) {
      this.$router.push(route)
    },
    // filtrar tarefas
    async filtrarFuncionario (value) {
      this.filtrarTarefas.id_funcionario = value
      this.filtrar();
    },
    async filtrarTodas () {
      this.filtrarTarefas.concluido = null
      this.filtrarTarefas.concluido_funcionario = null
      this.filtrar();
    },
    async filtrarNaoConcluidas () {
      this.filtrarTarefas.concluido = false
      this.filtrarTarefas.concluido_funcionario = false
      this.filtrar();
    },
    async filtrarConcluidas () {
      this.filtrarTarefas.concluido = true
      this.filtrarTarefas.concluido_funcionario = true
      this.filtrar();
    },
    async filtrarCliente () {
      this.filtrar();
    },
    filtrar () {
      this.tarefas = []
      this.page = 1
      this.getTarefas();
    },
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    async verTempos (idTarefa) {
      this.idtarefaglobal = idTarefa
      this.tempos = (await TemposService.index(idTarefa)).data
      var totalSegundos = 0; // Inicializa o total de segundos

      for (var tt = 0; tt < this.tempos.length; tt++) {
        var tempoefetivo = this.apresentarHoras(this.tempos[tt].tempo_efetivo);
        var partesTempo = tempoefetivo.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
        var horas = parseInt(partesTempo[0], 10);
        var minutos = parseInt(partesTempo[1], 10);
        var segundos = parseInt(partesTempo[2], 10);

        // Converte tudo para segundos e soma
        totalSegundos += horas * 3600 + minutos * 60 + segundos;
      }

      // Converte o total de segundos de volta para o formato de tempo desejado
      var totalFormatado = this.formatarTempo(totalSegundos);

      this.totaltempostarefas = totalFormatado;
    },
    
    async verTempo (idTempo) {
      this.dialogVerTempo = true
      this.tempo = (await TemposService.show(idTempo)).data
    },

    // Função para formatar o tempo no formato HH:mm:ss
    formatarTempo(totalSegundos) {
      var horas = Math.floor(totalSegundos / 3600);
      var minutos = Math.floor((totalSegundos % 3600) / 60);
      var segundos = totalSegundos % 60;

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
    },

    async apagarTempo (item) {
      this.dialogApagarTempo = true
      this.idtempoglobal = item.id
    },
    async confirmarApagarTempo () {
      try{
        await TemposService.delete(this.idtempoglobal)
        this.dialogApagarTempo = false
        this.verTempos(this.idtarefaglobal)

        //ver se este utilizador tem tempos abertos, se não tiver mete na tabela dos Funcionarios telefone, remoto, deslocao a false, Livre e cliente vazio, se não for vazio mete na coluna cliente os clientes que estão a ser tratados
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data
        const temposabertos = (await TemposService.index(null, null, null, this.$store.state.user.id, null, false)).data
        if (temposabertos == '') {
            this.editarFuncionarios.telefone = false
            this.editarFuncionarios.remoto = false
            this.editarFuncionarios.deslocacao = false
            this.editarFuncionarios.estado = 'Livre'
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
        } else {
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
            for (var i = 0; i < temposabertos.length; i++) {
                this.editarFuncionarios.cliente = this.editarFuncionarios.cliente + temposabertos[i].nome_cliente + '; '
            }  
            await FuncionariosService.put(this.editarFuncionarios) 
        }

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo apagado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar tempo"
          });
      }
    },
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    async verPatsCliente (item) {
        this.criarIntervencao.id_tempo = item.id
        if (item.deslocacao == true) {
            this.criarIntervencao.tipo_intervencao = 'Deslocação a Cliente'
        } else {
            if (item.remoto == true) {
            this.criarIntervencao.tipo_intervencao = 'Assistência Remota'
            } else {
            if (item.telefone == true) {
                this.criarIntervencao.tipo_intervencao = 'Assistência Telefónica'
            } else {
                this.criarIntervencao.tipo_intervencao = 'Assistência Interna'
            }
            }
        }    
        this.criarIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + item.trabalho_realizado
        this.criarIntervencao.id_tarefa = item.id_tarefa
        
        this.dialogVerPatsCliente = true
        this.patscliente = (await PatsService.importarpatserp(item.numero_cliente, null, false)).data
        this.tarefatempoglobal.numero_cliente = item.Tarefa.numero_cliente
        this.tarefatempoglobal.id_equipamento = item.Tarefa.id_equipamento
        this.tarefatempoglobal.descricao = item.Tarefa.descricao
    },
    async adicionarIntervencao (item) {
        this.dialogAdicionarIntervencao = true
        this.criarIntervencao.stamp_pat = item.stamp_pat
    },
    async confirmarAdicionarIntervencao () {
        try{
            const response = await PatsService.criarintervencoeserp(this.criarIntervencao)

            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.verTempos(this.idtarefaglobal)
                this.dialogAdicionarIntervencao = false
                this.dialogVerPatsCliente = false

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Intervenção adicionada à pat com sucesso"
                });
            }
            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a adicionar intervenção à pat"
            });
        }
    },
    async apagarIntervencaoPat (item) {
        this.dialogApagarIntervencao = true
        this.tempoApagarIntervencao.id_tempo = item.id
        this.tempoApagarIntervencao.stamp_intervencao = item.stamp_intervencao
        this.tempoApagarIntervencao.stamp_pat = item.stamp_pat
        this.tempoApagarIntervencao.id_tarefa = item.id_tarefa
    },
    async confirmarApagarIntervencao () {
        try{
            //Apagar intervenção da pat
            const response = await PatsService.apagarintervencoeserp(this.tempoApagarIntervencao)
            
            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.verTempos(this.idtarefaglobal)
                this.dialogApagarIntervencao = false

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Intervenção apagada da pat com sucesso"
                });
            }

            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar intervenção da pat"
            });
        }
    },
    async editarPat (item) {
        this.editarPats = (await PatsService.verpatserp(item.stamp_pat)).data
        this.estadospats = (await EstadosPatsService.index()).data
        this.dialogEditarPat = true
    },
    async confirmarEditarPat () {
        try{
            //Editar pat
            const response = await PatsService.editarpatserp(this.editarPats)    

            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.patscliente = (await PatsService.importarpatserp(this.tarefatempoglobal.numero_cliente, null, false)).data
                this.dialogEditarPat = false

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Pat editada com sucesso"
                });
            }
            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar pat"
            });
        }
    },
    async criarPatIntervencao () {
        this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.tarefatempoglobal.id_equipamento)).data
        this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].serial
        this.estadospats = (await EstadosPatsService.index()).data
        this.dialogCriarPatIntervencao = true
    },
    async confirmarCriarPatIntervencao () {
        try{
            this.criarPatsIntervencao.numero_cliente = this.tarefatempoglobal.numero_cliente
            this.criarPatsIntervencao.problema = this.tarefatempoglobal.descricao
            this.criarPatsIntervencao.faturar = this.criarIntervencao.faturar
            this.criarPatsIntervencao.tipo_intervencao = this.criarIntervencao.tipo_intervencao
            this.criarPatsIntervencao.id_tempo = this.criarIntervencao.id_tempo
            this.criarPatsIntervencao.id_tarefa = this.criarIntervencao.id_tarefa
            this.criarPatsIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + this.criarIntervencao.relatorio
            this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].stamp_equipamento
            const response = await PatsService.criarpatsintervencaoerp(this.criarPatsIntervencao)

            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.verTempos(this.idtarefaglobal)
                this.dialogCriarPatIntervencao = false
                this.dialogVerPatsCliente = false
                
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Pat criada com sucesso"
                });
            }     
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a criar pat"
            });
        }
    },
    async apagarPat (item) {
        this.dialogApagarPat = true
        this.patglobal = item
    },
    async confirmarApagarPat () {
        try{
            //Apagar pat do erp
            const response = await PatsService.apagarpatserp(this.patglobal)
            
            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.patscliente = (await PatsService.importarpatserp(this.tarefatempoglobal.numero_cliente, null, false)).data
                this.dialogApagarPat = false
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Pat apagada do erp com sucesso"
                });
            }

            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar pat do erp"
            });
        }
    },
  }
}
</script>
<style soped>
.v-dialog {
  background-color: white !important;
  z-index: 999 !important;
}
.v-input__control .v-text-field__details{
  display: none !important;
}
</style>