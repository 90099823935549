<template>
  <v-form ref="form">
    <v-container fluid v-if="$store.state.isUserLoggedIn">
      <v-row v-if="loading == false">
        <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Criar tarefa</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-icon @click="criarTarefa" class="ml-5">mdi-account-multiple-plus</v-icon>
          </v-app-bar>
        </v-col>
        <span id="mobile-margintop-conteudo"></span>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="criarTarefas.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable label="Cliente" :rules="[rules.cliente]"></v-autocomplete>
          <template v-if="criarTarefas.numero_cliente == empresaTitular.numero_consumidor_final">
            <v-text-field type="text" v-model="criarTarefas.nome_cliente" label="Nome consumidor final" :rules="[rules.consumidorfinal]"></v-text-field>
          </template>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field type="text" v-model="criarTarefas.quem_pediu" label="Quem pediu"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select v-model="criarTarefas.como_pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" label="Pedido como"></v-select>
        </v-col>
        <v-col cols="12">
          <label>Descrição</label>
          <wysiwyg v-model="criarTarefas.descricao"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarTarefas.grau_urgencia" :items="['Alta', 'Média', 'Baixa']" label="Urgência"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarTarefas.id_topico" :items="topicos" item-text="descricao" item-value="id" label="Tópico"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarTarefas.id_estado" :items="estados" item-text="descricao" item-value="id" label="Estado"></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <InputDate v-model="criarTarefas.data_inicio" placeholder="Data de Início" dateFormat="dd/mm/yy"/>
        </v-col>
        <v-col cols="12" md="3">
          <InputDate v-model="criarTarefas.data_fim" placeholder="Data de Fim" dateFormat="dd/mm/yy"/>
          <span v-if="criarTarefas.data_inicio > criarTarefas.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
        </v-col>

        <v-bottom-sheet v-model="dialogCriarInterveniente">
          <v-container fluid>
            <v-row>
              <v-col cols="12 mb-3">
                <v-chip-group v-model="intervenientesSelecionados" multiple column>
                  <v-chip v-for="tecnico in tecnicos" :key="tecnico.id" :value="tecnico" class="mr-2 mt-3" :color="intervenientesSelecionados.includes(tecnico) ? 'primary' : ''">
                    {{ tecnico.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12 mb-3" class="text-right" v-if="intervenientesSelecionados != ''">
                <v-btn color="primary" class="white--text" @click="confirmarCriarTarefa">Criar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-bottom-sheet>

      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import TemposService from '@/services/TemposService'
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import EmpresaTitutarService from '@/services/EmpresaTitutarService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.loading = true
    this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data
    this.clientes = (await ClientesService.index2()).data
    this.estados = (await EstadosService.index()).data
    this.topicos = (await TopicosService.index()).data
    this.empresaTitular = (await EmpresaTitutarService.index()).data
    this.criarTarefas.data_inicio = null
    this.criarTarefas.data_fim = null
    //criar tarefa a partir do tempo
    if (this.$store.state.tempo != null) {
      this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempotarefa)).data
      this.criarTarefas.descricao = this.editarTempos.descricao
      this.criarTarefas.numero_cliente = this.editarTempos.numero_cliente
      this.criarTarefas.nome_cliente = this.editarTempos.nome_cliente
      this.$store.dispatch('setTempo', {
        idtempotarefa: null,
        finalizadomobile: null
      })
    } else {
      this.criarTarefas.descricao = ''
      this.criarTarefas.numero_cliente = null
      this.criarTarefas.nome_cliente = null
    }
    this.loading = false
  },
  data () {
    return {
      loading: true,
      clientes: [],
      estados: [],
      topicos: [],
      tecnicos: [],
      editarTempos: {},
      empresaTitular: {},
      intervenientesSelecionados: [],
      criarTarefas: {
        numero_cliente: null,
        data_registo: null,
        quem_registou: this.$store.state.user.id,
        descricao: '',
        concluido: 0,
        id_topico: null,
        id_estado: 1,
        grau_urgencia: 'Média',
        quem_pediu: null,
        como_pediu: null,
        data_inicio: null,
        data_fim: null,
        id_projeto: 0,
        nome_cliente: null,
        ano: null,
        numero: null,
      },
      criarIntervenientes: {
        id_tarefa: null,
        id_funcionario: null,
        data_registo: null,
        quem_registou: this.$store.state.user.id,
        lido: false
      },

      dialogCriarInterveniente: false,
      
      rules: {
        cliente: value => !!value || "Cliente é Obrigatório.",    
        consumidorfinal: value => !!value || "Nome consumidor final é Obrigatório.",
        datamenor: value => {
          if (this.criarTarefas.data_fim && value > this.criarTarefas.data_fim) {
            return "Data fim deve ser maior que a data de início.";
          }
          return true;
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push('tarefas')
    },
    formatarDataParaISO(data) {
      if (data instanceof Date) {
        return moment(data).tz('Europe/Lisbon').toISOString();
      }
      return null;
    },
    //-------------------------------------------tarefa------------------------------//
    //-------------------------------------------tarefa------------------------------//
    //-------------------------------------------tarefa------------------------------//
    async criarTarefa () {
      if (this.$refs.form.validate()) {
        this.dialogCriarInterveniente = true
      }
    },
    async confirmarCriarTarefa () {
      try{
        if (this.$refs.form.validate()) {
          this.clienteselecionado = (await ClientesService.show(this.criarTarefas.numero_cliente)).data
          if (this.clienteselecionado.numero != this.empresaTitular.numero_consumidor_final) {
            this.criarTarefas.nome_cliente = this.clienteselecionado.nome
          }
          this.criarTarefas.ano = new Date().getFullYear();

          if (this.empresaTitular.tarefas_tipo == 'Anual') {
            //tem de ir buscar o maior numero do ano em que está, e se ainda não houver vai iniciar no número que estiver no tarefas_tipo_desde
            this.maiornumerotarefaano = (await TarefasService.maiornumerotarefaano()).data
            if (this.maiornumerotarefaano.numero != undefined) {
                this.criarTarefas.numero = this.maiornumerotarefaano.numero + 1;
            } else {
                this.criarTarefas.numero = this.empresaTitular.tarefas_tipo_desde
            }
          } else {
            //tem de ir buscar o maior numero, e se ainda não houver vai iniciar no número que estiver no tarefas_tipo_desde
            this.maiornumerotarefa = (await TarefasService.maiornumerotarefa()).data
            if (this.maiornumerotarefa.numero != undefined) {
                this.criarTarefas.numero = this.maiornumerotarefa.numero + 1;
            } else {
                this.criarTarefas.numero = this.empresaTitular.tarefas_tipo_desde
            }
          }
          this.criarTarefas.data_registo = this.formatarDataParaISO(new Date())
          await TarefasService.post(this.criarTarefas)
          this.tarefasultima = (await TarefasService.tarefasultima()).data
          for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
            this.criarIntervenientes.id_tarefa = this.tarefasultima.id
            this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
            this.criarIntervenientes.data_registo = this.formatarDataParaISO(new Date())
            await TarefasFuncionarioService.post(this.criarIntervenientes)
          }
          if (this.editarTempos.id != undefined) {
            this.editarTempos.id_tarefa = this.tarefasultima.id
            await TemposService.put(this.editarTempos)
          }

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa criada com sucesso"
          });
          this.$store.dispatch('setTarefa', {
            idtarefa: this.tarefasultima.id,
          })
          this.$router.push({
            name: 'editartarefas'
          })
      } else {
        this.dialogCriarInterveniente = false
      }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar tarefa"
          });
      }
    }
  }
}
</script>
<style>
</style>