import Api from '@/services/Api'

export default {
  index (page, inativo, nome) {
    return Api().get('funcionarios', {
      params: {
        page,
        inativo,
        nome
      }
    })
  },
  index2 (inativo, numero_tecnico, numero_vendedor, ocupado, livre, nome) {
    return Api().get('funcionariosindex2', {
      params: {
        inativo,
        numero_tecnico,
        numero_vendedor,
        ocupado,
        livre,
        nome
      }
    })
  },
  post (elementos) {
    return Api().post('funcionarios', elementos)
  },
  show (id) {
    return Api().get(`funcionarios/${id}`)
  },
  put (elementos) {
    return Api().put(`funcionarios/${elementos.id}`, elementos)
  },
  delete (id) {
    return Api().delete(`funcionarios/${id}`)
  },
  login(userName,pass) {
    return Api().get(`/login`,{
        params: {
          userName: userName,
          password: pass
        }
      })
  },
  changePassword(id, pass) {
    return Api().post(`changepassword`, {
      params: {
        id_funcionario: id,
        password_nova: pass
      }
    })
  },
}
