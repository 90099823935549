<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="primary" dark flat>
            <v-toolbar-title>Ler mensagem</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <h4 v-for="mensagem in mensagensLimpas.slice(0, 1)" :key="mensagem.id" ><b>Assunto: {{ mensagem.assunto }}</b></h4>
                </v-col>
              </v-row>
              <v-row style="padding-bottom: 150px !important;">
                <v-col cols="12" v-for="mensagem in mensagensLimpas" :key="mensagem.id" class="message-container">
                  <div v-if="mensagem.enviado_de == $store.state.user.id" class="balaomensagem mensagemenviada">
                    <h4>{{ mensagem.enviadoDe.nome }}</h4>
                    <span v-html="mensagem.mensagem"></span>
                    <div class="text-right">
                      <span>{{ apresentarData(mensagem.data_registo) + ' ' + apresentarHoras(mensagem.data_registo) }}</span>
                    </div>
                  </div>
                  <div v-else class="balaomensagem mensagemrecebida">
                    <h4>{{ mensagem.enviadoDe.nome }}</h4>
                    <span v-html="mensagem.mensagem"></span>
                    <div class="text-right">
                      <span class="text-right">{{ apresentarData(mensagem.data_registo) + ' ' + apresentarHoras(mensagem.data_registo) }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" style="position: fixed; bottom: 40px; background-color: white;">
                  <v-text-field type="text" v-model="novaMensagem" label="Digite sua mensagem..." filled outlined append-icon="mdi-send" @click:append="enviarMensagem"></v-text-field>
                </v-col>
                <btn @click="enviarNotificacao">Enviar notificação</btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
    </v-flex>
    <div ref="scrollToEnd"></div>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import MensagensService from '@/services/MensagensService'
import MensagensFuncionarioService from '@/services/MensagensFuncionarioService'
import NotificacoesService from '@/services/NotificacoesService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.mensagens = (await MensagensService.chatmensagens(this.$store.state.mensagem.idmensagem)).data
    this.scrollToBottom();
  },
  data () {
    return {
      mensagens: [],
      mensagem: {},
      criarMensagens: {
        numero_cliente: null,
        data_registo: this.formatarDataMoment(Date.now()),
        enviado_de: this.$store.state.user.id,
        assunto: '',
        mensagem: '',
        mensagem_resposta: 0,
        id_mensagem: 0,
        id_marcacao: 0,
        id_projeto: 0,
        nome_cliente: null,
        email: null,
        destinatarios: null,
        mensagem_original: null
      },
      editarMensagens: {
          mensagem_original: null
      },
      criarIntervenientes: {
        enviado_de: this.$store.state.user.id,
        enviado_para: null,
        id_mensagem: null,
        destinatarios: null,
        email: null,
        lido_data: null,
        lido: 0,
        data_registo: this.formatarDataMoment(Date.now()),
        
      },

      novaMensagem: ''
      
    }
  },
  methods: {
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 16)
    },
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    //-------------------------------------------mensagem------------------------------//
    //-------------------------------------------mensagem------------------------------//
    //-------------------------------------------mensagem------------------------------//
    async enviarMensagem() {
      if (this.novaMensagem.trim() === "") return;
      try{
        this.mensagem = (await MensagensService.show(this.$store.state.mensagem.idmensagem)).data
        this.criarMensagens.numero_cliente = this.mensagem.numero_cliente
        this.criarMensagens.assunto = this.mensagem.assunto
        this.criarMensagens.mensagem = this.novaMensagem
        this.criarMensagens.mensagem_resposta = this.mensagem.mensagem_resposta
        this.criarMensagens.id_mensagem = this.mensagem.id_mensagem
        this.criarMensagens.id_marcacao = this.mensagem.id_marcacao
        this.criarMensagens.id_projeto = this.mensagem.id_projeto
        this.criarMensagens.nome_cliente = this.mensagem.nome_cliente
        this.criarMensagens.email = this.mensagem.email
        this.criarMensagens.mensagem_original = this.mensagem.mensagem_original

        let destinatariosArray = this.mensagem.destinatarios.split(',').map(Number);
        destinatariosArray = destinatariosArray.filter(id => id !== this.$store.state.user.id);
        this.criarMensagens.destinatarios = destinatariosArray.join(',');

        this.editarMensagens = (await MensagensService.post(this.criarMensagens)).data

        for (var is = 0; is < destinatariosArray.length; is++) {
          this.criarIntervenientes.enviado_para = destinatariosArray[is].id
          this.criarIntervenientes.id_mensagem = this.editarMensagens.id
          this.criarIntervenientes.destinatarios = this.criarMensagens.destinatarios
          await MensagensFuncionarioService.post(this.criarIntervenientes)
        }

        this.mensagens = (await MensagensService.chatmensagens(this.$store.state.mensagem.idmensagem)).data
        this.novaMensagem = ''
        this.scrollToBottom();
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar mensagem"
          });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollToEnd;
        if (scrollContainer) {
          scrollContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });
    },
    async enviarNotificacao () {
      await NotificacoesService.sendNotification()
    }
  },
  computed: {
    mensagensLimpas() {
      return this.mensagens.map(mensagem => {
        const index = mensagem.mensagem.indexOf("********************************");
        return {
          ...mensagem,
          mensagem: index !== -1 ? mensagem.mensagem.substring(0, index) : mensagem.mensagem,
        };
      });
    },
  },
}
</script>
<style scoped>
.message-container {
  display: flex;
  flex-direction: column;
}

.balaomensagem {
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  display: inline-block;
}

.mensagemenviada {
  background-color: #4caf50;
  color: white;
  margin-left: auto;
  text-align: right;
}

.mensagemrecebida {
  background-color: #e0e0e0;
  color: #333;
  margin-right: auto;
  text-align: left;
}

</style>