<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Artigos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="AdicionarArtigoTarefa" v-bind="attrs" v-on="on" class="ml-5">mdi-plus-circle</v-icon>
                    </template>
                    <span>Adicionar artigo á tarefa</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="artigos.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getArtigosTarefa" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in artigostarefa">
                                    <v-list-item :key="item.id" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-title>  
                                            <v-list-item-subtitle>Preço sem IVA: {{ formatNumeroEuro(item.preco_sem_iva) }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>Preço com IVA: {{ formatNumeroEuro(item.preco_com_iva) }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>Total sem IVA: {{ formatNumeroEuro(item.total_linha_sem_iva) }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>Total com IVA: {{ formatNumeroEuro(item.total_linha_com_iva) }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>IVA: {{ item.iva }} %</v-list-item-subtitle>
                                            <v-list-item-subtitle>Desconto: {{ item.desconto }} %</v-list-item-subtitle>

                                            <v-list-item-subtitle v-if="item.tipo_stock == 'Saída'">Stock: {{ item.tipo_stock }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>Stock: {{ item.tipo_stock }}</v-list-item-subtitle>

                                            <v-list-item-subtitle v-if="item.propriedade == false">Propriedade: {{ item.propriedade }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>Propriedade: {{ item.propriedade }}</v-list-item-subtitle>

                                            <v-list-item-subtitle v-if="item.inativo == true">Inativo: {{ item.inativo }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>Ativo: {{ item.inativo }}</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-list-item-action-text>{{ apresentarData(item.data_registo) }} {{ apresentarHoras(item.data_registo) }}</v-list-item-action-text>
                                            <v-list-item-action-text></v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < artigos.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>   
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existe artigos</span></v-col> 
                </v-row>
            </v-card-text>
            <!-- Dialog adicionar artigo tarefa -->
        <v-dialog v-model="dialogAdicionarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="primary" dark flat>
                <v-toolbar-title>Adicionar artigo á tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                          <v-select v-model="adicionarArtigosTarefas.tipo_stock" item-text="text" item-value="value" label="Tipo de stock" :items="tipos_stock" :rules="[rules.tipostock]"></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                          <v-autocomplete v-model="adicionarArtigosTarefas.id_artigo" :items="artigos" item-text="codigo" item-value="id" label="Referência" @change="artigoTarefaReferencia"></v-autocomplete>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                        <v-autocomplete v-model="adicionarArtigosTarefas.id_artigo" :items="artigos" item-text="descricao" item-value="id" label="Descrição" @change="artigoTarefaDescricao"></v-autocomplete>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Qtd</label>
                          <v-text-field v-model="adicionarArtigosTarefas.qtd" @blur="atualizarQuantidade"></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Uni</label>
                          <v-text-field v-model="adicionarArtigosTarefas.unidade" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Sem Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.preco_sem_iva" @blur="atualizarSemIva"></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Iva (%)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.iva" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Com Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.preco_com_iva" @blur="atualizarComIva"></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Desconto (%)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.desconto" @blur="atualizarDesconto"></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Total sem Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.total_linha_sem_iva"></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Total com Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.total_linha_com_iva"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="white--text" @click="confirmarAdicionarArtigoTarefa">Adicionar</v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                </v-form>
            </v-card-text>       
          </v-card>
        </v-dialog>
        <!-- Dialog apagar artigo tarefa -->
        <v-dialog v-model="dialogApagarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o artigo da tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarArtigoTarefa">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar artigo tarefa -->
        <v-dialog v-model="dialogEditarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Descrição</label>
                <v-text-field v-model="editarArtigosTarefas.descricao" filled outlined></v-text-field>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarArtigoTarefa">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-card>
    </v-dialog>
</template>
<script>
import TarefasArtigosService from '@/services/TarefasArtigosService'
import ArtigosService from '@/services/ArtigosService'
import infiniteScroll from "vue-infinite-scroll";
import moment from 'moment-timezone'
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            page: 1,
            busy: false,
            artigos: [],
            artigoscliente: [],
            tarefa: {},
            tipos_stock: [
                {text: 'Saída', value: 'Saída'},
                {text: 'Entrada', value: 'Entrada'}
            ],

            adicionarArtigosTarefas: {
                tipo_stock: null,
                codigo_artigo: null,
                id_artigo: null,
                qtd: null,
                unidade: null,
                preco_sem_iva: null,
                iva: null,
                preco_com_iva: null,
                desconto: null,
                total_linha_sem_iva: null,
                total_linha_com_iva: null,
                descricao: '',
                quem_registou: this.$store.state.user.id,
                data_registo: this.formatarDataMoment(Date.now()),
                id_tarefa: null
            },
            editarArtigosTarefas: {
                descricao: null
            },

            headersArtigosTarefas: [
                { text: "Referência", value: "codigo_artigo", sortable: false },
                { text: "Descricao", value: "descricao", sortable: true },
                { text: "Qtd", value: "qtd", sortable: true },
                { text: "Uni", value: "unidade", sortable: true },
                { text: "Sem Iva (€)", value: "preco_sem_iva", sortable: true },
                { text: "Iva", value: "iva", sortable: true },
                { text: "Com Iva (€)", value: "preco_com_iva", sortable: true },
                { text: "Desc (%)", value: "desconto", sortable: true },
                { text: "Total sem iva (€)", value: "total_linha_sem_iva", sortable: true },
                { text: "Total com iva (€)", value: "total_linha_com_iva", sortable: true },
                { text: "Tipo de stock", value: "tipo_stock", sortable: true },
                { text: "Documento ERP", value: "documento_erp", sortable: true },
                { text: "Ações", value: "actions", sortable: true }
            ],

            rules: {
                tipostock: value => {
                    if (!value) {
                        return "O campo tipo de stock é obrigatório.";
                    }
                    return true;
                }
            },

            dialogAdicionarArtigoTarefa: false,
            dialogApagarArtigoTarefa: false,
            dialogEditarArtigoTarefa: false,
        }
    },
    methods: {
        async open(tarefa) {
            this.show = true,
            this.tarefa = tarefa
            this.artigos = []
            this.artigostarefa = []
            this.getArtigosTarefa()
        },
        async getArtigosTarefa() {
            this.loading = true;
            const response = await TarefasArtigosService.index(
                this.page,
                this.tarefa.id
            );
            this.artigostarefa = this.artigostarefa.concat(response.data);
            this.page++;
            this.busy = false;
            if (response.data.length == 0) {
                this.busy = true;
            }
            this.loading = false;
        },
        async AdicionarArtigoTarefa () {
            this.dialogAdicionarArtigoTarefa = true
            this.artigos = (await ArtigosService.index(false)).data
        },
        async confirmarAdicionarArtigoTarefa () {
            try{
                if (this.$refs.form.validate()) {
                    this.adicionarArtigosTarefas.id_tarefa = this.tarefa.id
                    await TarefasArtigosService.post(this.adicionarArtigosTarefas)
                    this.dialogAdicionarArtigoTarefa = false
                    this.filtrar()
                    this.$store.dispatch("setSnackBar", {
                        color: "success",
                        text: "Artigo adicionado com sucesso á tarefa"
                    });
                    this.clearAdicionarArtigosTarefas();
                }
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a adicionar artigo á tarefa"
                });
            }
        },
        async apagarArtigoTarefa (id) {
            this.dialogApagarArtigoTarefa = true
            this.idartigotarefaglobal = id
        },
        async confirmarApagarArtigoTarefa () {
            try{
                await TarefasArtigosService.delete(this.idartigotarefaglobal)
                this.dialogApagarArtigoTarefa = false
                this.filtrar()
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Artigo tarefa apagado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a apagar artigo tarefa"
                });
            }
        },
        async editarArtigoTarefa (id) {
            this.dialogEditarArtigoTarefa = true
            this.editarArtigosTarefas = (await TarefasArtigosService.show(id)).data
        },
        async confirmarEditarArtigoTarefa () {
            try{
                await TarefasArtigosService.put(this.editarArtigosTarefas)
                this.dialogEditarArtigoTarefa = false
                this.filtrar()
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Artigo tarefa editado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a editar artigo tarefa"
                });
            }
        },
        async artigoTarefaReferencia (item) {
            if (item != null) {
                this.artigo = (await ArtigosService.show(item)).data
                this.adicionarArtigosTarefas.codigo_artigo = this.artigo.codigo
                this.adicionarArtigosTarefas.id_artigo = this.artigo.id
                this.adicionarArtigosTarefas.qtd = 1
                this.adicionarArtigosTarefas.unidade = this.artigo.unidade
                this.adicionarArtigosTarefas.preco_sem_iva = this.artigo.preco_venda_sem_iva
                this.adicionarArtigosTarefas.iva = this.artigo.iva
                this.adicionarArtigosTarefas.preco_com_iva = this.artigo.preco_venda_com_iva
                this.adicionarArtigosTarefas.desconto = 0
                this.adicionarArtigosTarefas.total_linha_sem_iva = (this.artigo.preco_venda_sem_iva * this.adicionarArtigosTarefas.qtd).toFixed(2);
                this.adicionarArtigosTarefas.total_linha_com_iva = (this.artigo.preco_venda_com_iva * this.adicionarArtigosTarefas.qtd).toFixed(2);
                this.adicionarArtigosTarefas.descricao = this.artigo.descricao
            }
        },
        async artigoTarefaDescricao (item) {
            if (item != null) {
                this.artigo = (await ArtigosService.show(item)).data
                this.adicionarArtigosTarefas.codigo_artigo = this.artigo.codigo
                this.adicionarArtigosTarefas.id_artigo = this.artigo.id
                this.adicionarArtigosTarefas.qtd = 1
                this.adicionarArtigosTarefas.unidade = this.artigo.unidade
                this.adicionarArtigosTarefas.preco_sem_iva = this.artigo.preco_venda_sem_iva
                this.adicionarArtigosTarefas.iva = this.artigo.iva
                this.adicionarArtigosTarefas.preco_com_iva = this.artigo.preco_venda_com_iva
                this.adicionarArtigosTarefas.desconto = 0
                this.adicionarArtigosTarefas.total_linha_sem_iva = this.artigo.preco_venda_sem_iva
                this.adicionarArtigosTarefas.total_linha_com_iva = this.artigo.preco_venda_com_iva
                this.adicionarArtigosTarefas.descricao = this.artigo.descricao
            }
        },
        atualizarQuantidade () {
            this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
            this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
        },
        atualizarDesconto () {
            this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
            this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
        },
        atualizarSemIva () {
            this.adicionarArtigosTarefas.preco_com_iva = (this.adicionarArtigosTarefas.preco_sem_iva * (1 + (this.adicionarArtigosTarefas.iva / 100))).toFixed(2);
            this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
            this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
        },
        atualizarComIva () {
            this.adicionarArtigosTarefas.preco_sem_iva = (this.adicionarArtigosTarefas.preco_com_iva / (1 + (this.adicionarArtigosTarefas.iva / 100))).toFixed(2);
            this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
            this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
        },
        clearAdicionarArtigosTarefas () {
            this.adicionarArtigosTarefas.tipo_stock = null,
            this.adicionarArtigosTarefas.codigo_artigo = null,
            this.adicionarArtigosTarefas.id_artigo = null,
            this.adicionarArtigosTarefas.qtd = null,
            this.adicionarArtigosTarefas.unidade = null,
            this.adicionarArtigosTarefas.preco_sem_iva = null,
            this.adicionarArtigosTarefas.iva = null,
            this.adicionarArtigosTarefas.preco_com_iva = null,
            this.adicionarArtigosTarefas.desconto = null,
            this.adicionarArtigosTarefas.total_linha_sem_iva = null,
            this.adicionarArtigosTarefas.total_linha_com_iva = null,
            this.adicionarArtigosTarefas.descricao = '',
            this.adicionarArtigosTarefas.id_tarefa = null
        },
        filtrar () {
            this.artigostarefa = []
            this.page = 1
            this.getArtigosTarefa();
        },
        formatNumeroEuro(numero) {
            const euro = new Intl.NumberFormat('pt-PT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
            });
            return euro.format(numero);
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
        // formatar data
        formatarDataMoment(data) {
            return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
        },
    }
}
</script>