import Api from '@/services/Api'

export default {
  index (page, concluido, numero_cliente, concluido_funcionario, id_funcionario, id_tarefa) {
    return Api().get('marcacoes',{
      params: {
        page,
        concluido,
        numero_cliente,
        concluido_funcionario,
        id_funcionario,
        id_tarefa,
      }
    })
  },
  post (elementos) {
    return Api().post('marcacoes', elementos)
  },
  put (elementos) {
    return Api().put(`marcacoes/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`marcacoes/${id}`)
  },
  delete (id) {
    return Api().delete(`marcacoes/${id}`)
  },
  marcacoesultima () {
    return Api().get(`marcacoesultima`)
  },
  marcacoesdiafuncionariover (id_funcionario, data_inicio) {
    return Api().get('marcacoesdiafuncionariover',{
      params: {
        id_funcionario,
        data_inicio
      }
    })
  },
}
