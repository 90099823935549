<template>
    <v-form ref="form">
      <v-container fluid v-if="$store.state.isUserLoggedIn && this.$store.state.funcionario != null || this.$store.state.funcionario.idfuncionario != null">
        <v-row v-if="loading == false">
            <v-col cols="12" class="p-0">
                <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
                <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
                <v-app-bar-title>Funcionário</v-app-bar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="apagarFuncinario(editarFuncionarios.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
                    </template>
                    <span>Apagar funcionário</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="editarFuncionario" v-bind="attrs" v-on="on" class="ml-5">mdi-content-save</v-icon>
                    </template>
                    <span>Guardar funcionário</span>
                </v-tooltip>
                </v-app-bar>
            </v-col>
            <span id="mobile-margintop-conteudo"></span>
            <v-col cols="12">
              <v-chip @click="toggleInativo" v-model="editarFuncionarios.inativo" :color="editarFuncionarios.inativo == false ? 'primary' : ''">Ativo</v-chip>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field type="text" v-model="editarFuncionarios.nome" clearable label="Nome" :rules="[rules.nome]"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarFuncionarios.email" clearable label="Email" :rules="[rules.email]"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarFuncionarios.telemovel" clearable label="Telemóvel"></v-text-field>
            </v-col>     
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarFuncionarios.numero_tecnico" clearable label="Número técnico"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarFuncionarios.numero_vendedor" clearable label="Número vendedor"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field @click="dialogEditarPassword = true" type="password" value="*******************" clearable label="Password" readonly></v-text-field>
            </v-col>          
  
          <!-- Dialog apagar funcionário -->
          <v-dialog v-model="dialogApagarFuncionario">
            <v-card>
              <v-toolbar class="mb-2" color="red" dark flat>
                  <v-toolbar-title>Apagar funcionário</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogApagarFuncionario = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer mesmo apagar o funcionário?</span>
              </v-col>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" class="white--text" @click="confirmarApagarFuncinario">Apagar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


            <!-- Dialog editar password -->
            <v-dialog v-model="dialogEditarPassword">
                <v-card>
                    <v-toolbar class="mb-2" color="primary" dark flat>
                        <v-toolbar-title>Alterar password online</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogEditarPassword = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field type="password" v-model="passwordInserida.password" clearable label="Password" :rules="[rules.senha]"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field type="password" v-model="passwordInserida.confirmpass" label="Confirmar password" :rules="[rules.senhaconfirmar]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" class="white--text" @click="updatePass">Update password</v-btn>
                                  </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
  
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </template> 
  <script>
  //  Importar o service necessário
  import FuncionariosService from '@/services/FuncionariosService'
  import store from '@/store/store'
  
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      } else if (this.$store.state.funcionario == null || this.$store.state.funcionario.idfuncionario == null) {
        this.$router.push({
          name: 'funcionarios'
        })
      } else {
        this.loading = true
        this.getFuncionario()
  
        this.loading = false
      }
    },
    data () {
      return {
        loading: true,
        
        editarFuncionarios: {
          nome: null,
          email: null,
          telemovel: null,
          senha: null,
          numero_tecnico: null,
          numero_vendedor: null,
          inativo: false
        },

        passwordInserida: {
          password: '',
          confirmpass: ''
        },

        rules: {
            nome: value => !!value || "Número é Obrigatório.",
            email: value => {if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {return "Por favor, insira um endereço de email válido.";}return true;},
            senha: value => !!value || "Password é Obrigatória.",
            senhaconfirmar: value => { if (value !== this.passwordInserida.password) {return "Passwords não são iguais";}return true;}
        },

        idfuncionarioglobal: '',

        dialogApagarFuncionario: false,
        dialogEditarPassword: false
      }
    },
    methods: {
      goBack() {
        this.$router.push('funcionarios')
      },
      async getFuncionario() {
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.funcionario.idfuncionario)).data
      },
      async editarFuncionario () {
        try{
          if (this.$refs.form.validate()) {
            await FuncionariosService.put(this.editarFuncionarios)
            this.getFuncionario()
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Funcionário editado com sucesso"
            });
          }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar funcionário"
            });
        }
      },
      async apagarFuncinario (item) {
        this.dialogApagarFuncionario = true
        this.idfuncionarioglobal = item
      },
      async confirmarApagarFuncinario () {
        try{
          await FuncionariosService.delete(this.idfuncionarioglobal)
          this.dialogApagarFuncionario = false
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Funcinário apagado com sucesso"
          });
          this.$router.push('funcionarios')
        } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar funcionário, pode ter coisas associadas"
          });
        }
      },
      async updatePass() {
        try {
            if (this.$refs.form.validate()) {
              this.editarFuncionarios.senha = this.passwordInserida.password
              await FuncionariosService.changePassword(this.editarFuncionarios.id, this.editarFuncionarios.senha);
              this.dialogEditarPassword = false
              this.$store.dispatch("setSnackBar", {
                  color: "success",
                  text: "Password alterada com sucesso"
              });
            }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a alterar password"
            });
        }
      },
      toggleInativo() {
        this.editarFuncionarios.inativo = !this.editarFuncionarios.inativo;
      },
    }
  }
  </script>
  <style>
  </style>