<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Tempo: {{totaltempos}}h</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom to="criartarefas">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="navigateToTempoCriar({name: 'criartempostarefas'})" v-bind="attrs" v-on="on" class="ml-5">mdi-plus</v-icon>
                    </template>
                    <span>Criar tempo</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="tempos.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getTempos" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in tempos">
                                    <v-list-item :key="item.id" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                                        <v-list-item-content>
                                            <v-list-item-subtitle style="font-size: 13px;">{{ apresentarData(item.data_inicio) }} {{ apresentarHoras(item.data_inicio) }}h - {{ apresentarHoras(item.data_fim) }}h</v-list-item-subtitle>                          
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                                            <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.trabalho_realizado != null" v-html="item.trabalho_realizado.substring(0,120)"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">{{ item.Funcionario.nome }}</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-list-item-action-text></v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < tempos.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existem tempos</span></v-col>
                </v-row>
            </v-card-text> 
        </v-card>
    </v-dialog>
</template>
<script>
import TemposService from '@/services/TemposService'
import infiniteScroll from "vue-infinite-scroll";
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            page: 1,
            busy: false,
            tempos: [],
            totaltempos: '',
            idtarefaglobal: '',
        }
    },
    methods: {
        async open(idTarefa) {
            this.show = true
            this.idtarefaglobal = idTarefa
            this.page = 1
            this.tempos = []
            this.getTempos(idTarefa)
        },
        async getTempos(idTarefa) {
            this.busy = true;
            this.loading = true;
            const response = await TemposService.index(
                this.page,
                idTarefa
            );
            this.tempos = this.tempos.concat(response.data);
            this.page++;
            this.busy = false;
            if (response.data.length == 0) {
                this.busy = true;
            }

            var totalSegundos = 0;
            for (var tt = 0; tt < this.tempos.length; tt++) {
                var tempoefetivo = this.apresentarHoras2(this.tempos[tt].tempo_efetivo);
                var partesTempo = tempoefetivo.split(':');
                var horas = parseInt(partesTempo[0], 10);
                var minutos = parseInt(partesTempo[1], 10);
                var segundos = parseInt(partesTempo[2], 10);

                totalSegundos += horas * 3600 + minutos * 60 + segundos;
            }
            var totalFormatado = this.formatarTempo(totalSegundos);
            this.totaltempos = totalFormatado;
            this.loading = false;
        },
        formatarTempo(totalSegundos) {
            var horas = Math.floor(totalSegundos / 3600);
            var minutos = Math.floor((totalSegundos % 3600) / 60);
            //var segundos = totalSegundos % 60;

            return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0');
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
        apresentarHoras2 (date) {
            return new Date(date).toJSON().slice(11, 19)
        },
        navigateToTempoCriar (route) {
            this.$store.dispatch('setTempoTarefa', {  
                idtarefatempo: this.idtarefaglobal,
            })
            this.$router.push(route)
        },
    }
}
</script>