<template>
  <v-container fluid v-if="$store.state.isUserLoggedIn">
    <v-row>      
      <v-col cols="12" class="p-0">
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
          <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
          <v-app-bar-title>Projetos</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-icon @click="navigateToProjetoCriar({name: 'criarprojetos'})" class="ml-5">mdi-plus</v-icon>
          <v-icon @click.stop="dialogFiltrarProjetos = true" class="ml-5">mdi-magnify</v-icon>
        </v-app-bar>
      </v-col>
      <span id="mobile-margintop-conteudo"></span>
      <v-col cols="12">
        <v-list twoo-line>
          <v-list-item-group v-infinite-scroll="getProjetos" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >     
            <template v-for="(item, index) in projetos">
              <v-list-item :key="item.id" @click="navigateToProjetoEditar({name: 'editarprojetos', params:{idProjetoEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                <v-list-item-content>
                  <v-list-item-subtitle style="font-size: 13px;">{{ item.numero + '/' + item.ano }}</v-list-item-subtitle>
                  <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                  <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                  <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                    <span v-for="(func, index) in item.Funcionarios" :key="func.id">
                        {{ func.nome }}<span v-if="item.Funcionarios.length > 1 && index < item.Funcionarios.length - 1">;</span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < projetos.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-list>
      </v-col>
      <!-- Dialog filtrar projeto -->
      <v-bottom-sheet v-model="dialogFiltrarProjetos">
        <v-container fluid>
          <v-row>
            <v-col cols="12 mt-3">
              <v-chip @click="filtrarNaoConcluidos" :color="filtrarProjetos.concluido == false ? 'primary' : ''" class="mr-2">Por concluir</v-chip>
              <v-chip @click="filtrarConcluidos" :color="filtrarProjetos.concluido == true ? 'primary' : ''" class="mr-2">Concluídos</v-chip>
              <v-chip @click="filtrarTodos" :color="filtrarProjetos.concluido == null ? 'primary' : ''" class="mr-2">Todos</v-chip>
            </v-col>
            <v-col cols="12">
              <v-autocomplete @change="filtrarCliente" v-model="filtrarProjetos.numero_cliente" :items="clientes" item-text="nome" item-value="numero" label="Cliente" clearable></v-autocomplete>
            </v-col>
            <v-col cols="12 mb-3">
              <v-chip class="mr-2 mt-3" @click="filtrarFuncionario(null)" :color="filtrarProjetos.id_funcionario === null ? 'primary' : ''">Todos</v-chip>
                <v-chip v-for="funcionario in funcionarios" :key="funcionario.id" class="mr-2 mt-3" @click="filtrarFuncionario(funcionario.id)" :color="filtrarProjetos.id_funcionario === funcionario.id ? 'primary' : ''">
                  {{ funcionario.nome }}
                </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-bottom-sheet>
    </v-row>
  </v-container>
</template>
<script>
//  Importar o service necessário
import ProjetosService from '@/services/ProjetosService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
import infiniteScroll from "vue-infinite-scroll";
export default {
  directives: {
    infiniteScroll
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.filtrarProjetos.numero_cliente = this.$store.state.cliente.numerocliente
    this.funcionarios = (await FuncionariosService.index2()).data
    this.clientes = (await ClientesService.index2()).data
  },
  data () {
    return {
      funcionarios: [],
      projetos: [],
      clientes: [],

      filtrarProjetos: {
        concluido: false,
        numero_cliente: null,
        concluido_funcionario: false,
        id_funcionario: this.$store.state.funcionarioselect.idfuncionario
      },

      dialogFiltrarProjetos: false,

      loading: true,
      page: 1,
      busy: false,
    }
  },
  methods: {
    async getProjetos() {
      this.busy = true;
      this.loading = true;
      const response = await ProjetosService.index(
        this.page,
        this.filtrarProjetos.concluido,
        this.filtrarProjetos.numero_cliente,
        this.filtrarProjetos.concluido_funcionario,
        this.filtrarProjetos.id_funcionario
      );
      this.projetos = this.projetos.concat(response.data);
      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    goBack() {
      this.$router.push('home')
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 16)
    },
    navigateToProjetoEditar (route) {
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idProjetoEnviado,
      })
      this.$router.push(route)
    },
    navigateToProjetoCriar (route) {
      this.$router.push(route)
    },
    // filtrar projetos
    async filtrarFuncionario (value) {
      this.filtrarProjetos.id_funcionario = value
      this.filtrar();
    },
    async filtrarTodos () {
      this.filtrarProjetos.concluido = null
      this.filtrarProjetos.concluido_funcionario = null
      this.filtrar();
    },
    async filtrarNaoConcluidos () {
      this.filtrarProjetos.concluido = false
      this.filtrarProjetos.concluido_funcionario = false
      this.filtrar();
    },
    async filtrarConcluidos () {
      this.filtrarProjetos.concluido = true
      this.filtrarProjetos.concluido_funcionario = true
      this.filtrar();
    },
    async filtrarCliente () {
      this.filtrar();
    },
    filtrar () {
      this.projetos = []
      this.page = 1
      this.getProjetos();
    }
  }
}
</script>
<style>
</style>