<template>
  <v-form ref="form">
    <v-container fluid v-if="$store.state.isUserLoggedIn && this.$store.state.marcacaotarefa != null || this.$store.state.marcacaotarefa.tarefamarcacao != null">
      <v-row v-if="loading == false">
        <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Criar marcação</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-icon @click="criarMarcacao" class="ml-5">mdi-account-multiple-plus</v-icon>
          </v-app-bar>
        </v-col>
        <span id="mobile-margintop-conteudo"></span>
        <v-col cols="12" md="6">
          <v-text-field type="text" v-model="criarMarcacoes.nome_cliente" label="Cliente" disabled></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field type="text" v-model="criarMarcacoes.quem_pediu" label="Quem pediu"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select v-model="criarMarcacoes.como_pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" label="Pedido como"></v-select>
        </v-col>
        <v-col cols="12">
          <label>Descrição</label>
          <wysiwyg v-model="criarMarcacoes.descricao"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field type="text" v-model="criarMarcacoes.localizacao" label="Localização"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarMarcacoes.id_topico" :items="topicos" item-text="descricao" item-value="id" label="Tópico"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarMarcacoes.id_estado" :items="estados" item-text="descricao" item-value="id" label="Estado"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-chip @click="toggleIndesmarcavel" v-model="criarMarcacoes.indesmarcavel" :color="criarMarcacoes.indesmarcavel == true ? 'primary' : ''">Indesmarcável</v-chip>
        </v-col>
        <v-col cols="12" md="2">
          <v-chip @click="toggleInstalacoesInfordio" v-model="criarMarcacoes.instalacoes_infordio" :color="criarMarcacoes.instalacoes_infordio == true ? 'primary' : ''">Instalações Infordio</v-chip>
        </v-col>
        <!--
        <v-col cols="12" md="3">
          <InputDate v-model="criarMarcacoes.data_inicio" placeholder="Data de Início" dateFormat="dd/mm/yy"/>
        </v-col>
        <v-col cols="12" md="3">
          <InputDate v-model="criarMarcacoes.data_fim" placeholder="Data de Fim" dateFormat="dd/mm/yy"/>
          <span v-if="criarMarcacoes.data_inicio > criarMarcacoes.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
        </v-col>
        -->

        <v-bottom-sheet v-model="dialogCriarInterveniente">
          <v-container fluid style="height: 500px !important">
            <v-row>
              <v-col cols="12" md="3">
                <InputDate @input="filtrarMarcacoesDatasFuncionario" v-model="datainicioinserida" placeholder="Data" dateFormat="dd/mm/yy"/>
                <span v-if="datainicioinserida == null" class="errodata">Campo obrigatório.</span>
              </v-col>
              <v-col class="d-none d-md-block" cols="9"></v-col>
              <v-col cols="12" md="6">
                <InputDate v-model="horainicioinserida" placeholder="Hora de início" timeOnly showTime dateFormat="HH:mm" />
                <span v-if="horainicioinserida == null" class="errodata">Campo obrigatório.</span>
              </v-col>
              <v-col cols="12" md="6">
                <InputDate v-model="horafiminserida" placeholder="Hora de fim" timeOnly showTime dateFormat="HH:mm" />
                <span v-if="horafiminserida == null" class="errodata">Campo obrigatório.</span>
              </v-col>
              <v-col cols="12 mb-3">
                <v-chip-group v-model="intervenientesSelecionados" multiple column>
                  <v-chip v-for="tecnico in tecnicos" :key="tecnico.id" :value="tecnico" close-icon="mdi-eye" :close="marcacoesdiafuncionario.some(marcacoesdia => marcacoesdia.id_funcionario === tecnico.id)" @click:close="verMarcacoesDiaFuncionario(tecnico.id)" class="mr-2 mt-3" :color="intervenientesSelecionados.includes(tecnico) ? 'primary' : ''">
                    {{ tecnico.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12 mb-3" class="text-right" v-if="intervenientesSelecionados != '' && datainicioinserida != null && horafiminserida != null && horafiminserida != null">
                <v-btn color="primary" class="white--text" @click="confirmarCriarMarcacao">Criar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-bottom-sheet>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
      <MyModalMarcacoesDiaFuncionario ref="mymodalmarcacoesdiafuncionario" />
    </v-container>
  </v-form>
</template>

<script>
//  Importar o service necessário
import MarcacoesService from '@/services/MarcacoesService'
import EstadosService from '@/services/EstadosService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import MyModalMarcacoesDiaFuncionario from "@/components/Marcacoes/MyModalMarcacoesDiaFuncionario.vue";
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  components: {
    MyModalMarcacoesDiaFuncionario
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }else if (this.$store.state.marcacaotarefa == null || this.$store.state.marcacaotarefa.tarefamarcacao == null) {
      this.$router.push({
        name: 'myhome'
      })
    } else {
      this.loading = true
      this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.$store.dispatch('setMarcacaoTarefa', {  
        tarefamarcacao: null
      })
      this.loading = false
    }
  },
  data () {
    return {
      loading: true,
      datainicioinserida: null,
      horainicioinserida: null,
      horafiminserida: null,
      estados: [],
      topicos: [],
      tecnicos: [],
      marcacoesdiafuncionario: [],
      marcacoesdiafuncionariover: [],
      intervenientesSelecionados: [],
      criarMarcacoes: {
        numero_cliente: this.$store.state.marcacaotarefa.tarefamarcacao.numero_cliente,
        data_registo: null,
        quem_registou: this.$store.state.user.id,
        descricao: this.$store.state.marcacaotarefa.tarefamarcacao.descricao,
        concluido: 0,
        id_topico: null,
        id_estado: 1,
        localizacao: null,
        quem_pediu: null,
        como_pediu: null,
        data_inicio: null,
        data_fim: null,
        indesmarcavel: false,
        instalacoes_infordio: false,
        nome_cliente: this.$store.state.marcacaotarefa.tarefamarcacao.nome_cliente,
        id_tarefa: this.$store.state.marcacaotarefa.tarefamarcacao.id,
      },
      criarIntervenientes: {
        id_marcacao: null,
        id_funcionario: null,
        atribuido_data: null,
        atribuido_por: this.$store.state.user.id,
        data_inicio: null,
        data_fim: null,
        hora_inicio: null,
        hora_fim: null,
        data_inicio_total: null,
        data_fim_total: null,
        lido: false
      },

      dialogCriarInterveniente: false,
      rules: {
        cliente: value => !!value || "Cliente é Obrigatório.",  
      },
    }
  },
  methods: {
    goBack() {
      this.$router.push('marcacoes')
    },
    formatarDataParaISO(data) {
      if (data instanceof Date) {
        return moment(data).tz('Europe/Lisbon').toISOString();
      }
      return null;
    },
    //-------------------------------------------marcações do dia funcionário------------------------------//
    //-------------------------------------------marcações do dia funcionário------------------------------//
    //-------------------------------------------marcações do dia funcionário------------------------------//
    async verMarcacoesDiaFuncionario (idfuncionario) {
      this.$refs.mymodalmarcacoesdiafuncionario.open(idfuncionario, this.formatarDataParaISO(this.datainicioinserida));
    },
    //-------------------------------------------marcacao------------------------------//
    //-------------------------------------------marcacao------------------------------//
    //-------------------------------------------marcacao------------------------------//
    async criarMarcacao () {
      if (this.$refs.form.validate()) {
        this.dialogCriarInterveniente = true
      }
    },
    async confirmarCriarMarcacao () {
      try{
        if (this.$refs.form.validate()) {
          this.criarMarcacoes.data_registo = this.formatarDataParaISO(new Date())
          await MarcacoesService.post(this.criarMarcacoes)

          this.marcacoesultima = (await MarcacoesService.marcacoesultima()).data


          let horainicioescolhida = '1899-12-30T' + this.formatarDataParaISO(this.horainicioinserida).slice(11,16) + ':00.000Z'
          let horafimescolhida = '1899-12-30T' + this.formatarDataParaISO(this.horafiminserida).slice(11,16) + ':00.000Z'
          let datainiciohoratotal = this.formatarDataParaISO(this.datainicioinserida).slice(0,11) + this.formatarDataParaISO(this.horainicioinserida).slice(11,16) + ':00.000Z'
          let datafimhoratotal = this.formatarDataParaISO(this.datainicioinserida).slice(0,11) + this.formatarDataParaISO(this.horafiminserida).slice(11,16) + ':00.000Z'

          for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
            this.criarIntervenientes.id_marcacao = this.marcacoesultima.id
            this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
            this.criarIntervenientes.data_inicio = this.formatarDataParaISO(this.datainicioinserida)
            this.criarIntervenientes.data_fim = this.criarIntervenientes.data_inicio

            this.criarIntervenientes.hora_inicio = horainicioescolhida
            this.criarIntervenientes.hora_fim = horafimescolhida
            this.criarIntervenientes.data_inicio_total = datainiciohoratotal
            this.criarIntervenientes.data_fim_total = datafimhoratotal
            this.criarIntervenientes.atribuido_data = this.formatarDataParaISO(new Date())
            await MarcacoesFuncionarioService.post(this.criarIntervenientes)
          }

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcação criada com sucesso"
          });
          this.$store.dispatch('setMarcacao', {
            idmarcacao: this.marcacoesultima.id,
          })
          this.$router.push({
            name: 'editarmarcacoes'
          })
          
      } else {
        this.dialogCriarInterveniente = false
      }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar marcacao"
          });
      }
    },
    async filtrarMarcacoesDatasFuncionario () {
      this.marcacoesdiafuncionario = (await MarcacoesFuncionarioService.marcacoesdiafuncionario(this.formatarDataParaISO(this.datainicioinserida))).data
    },
    toggleIndesmarcavel() {
      this.criarMarcacoes.indesmarcavel = !this.criarMarcacoes.indesmarcavel;
    },
    toggleInstalacoesInfordio() {
      this.criarMarcacoes.instalacoes_infordio = !this.criarMarcacoes.instalacoes_infordio;
    }
  }
}
</script>
<style>
</style>