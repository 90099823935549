import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { sync } from 'vuex-router-sync'
import router from './router'
import store from '@/store/store'
import './registerServiceWorker'
import './service-worker'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'

import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';    
Vue.use(PrimeVue);
Vue.component('InputDate', Calendar);

Vue.use(DatePicker)
Vue.component('MYdatetime', DatePicker);


Vue.use(wysiwyg, {})

Vue.config.productionTip = false
sync(store, router)


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('./service-worker.js')
      .then(registration => {
        console.log('Service Worker registrado com sucesso!!!!!!!!:', registration);
      })
      .catch(error => {
        console.error('Erro ao registrar o Service Worker:', error);
      });
  });
}

// eslint-disable-next-line
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

export default {
  head: [
    ['link', {
      rel: 'stylesheet',
      href: 'https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css'
    }]
  ]
}