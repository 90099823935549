<template>
  <v-form ref="form">
    <v-container fluid v-if="$store.state.isUserLoggedIn">
      <v-row v-if="loading == false">
        <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Criar projeto</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-icon @click="criarProjeto" class="ml-5">mdi-account-multiple-plus</v-icon>
          </v-app-bar>
        </v-col>
        <span id="mobile-margintop-conteudo"></span>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="criarProjetos.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable label="Cliente" :rules="[rules.cliente]"></v-autocomplete>
          <template v-if="criarProjetos.numero_cliente == empresaTitular.numero_consumidor_final">
            <v-text-field type="text" v-model="criarProjetos.nome_cliente" label="Nome consumidor final" :rules="[rules.consumidorfinal]"></v-text-field>
          </template>
        </v-col>
        <v-col cols="12">
          <label>Descrição</label>
          <wysiwyg v-model="criarProjetos.descricao"/>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarProjetos.id_topico" :items="topicos" item-text="descricao" item-value="id" label="Tópico"></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="criarProjetos.id_estado" :items="estados" item-text="descricao" item-value="id" label="Estado"></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <InputDate v-model="criarProjetos.data_inicio" placeholder="Data de Início" dateFormat="dd/mm/yy"/>
        </v-col>
        <v-col cols="12" md="3">
          <InputDate v-model="criarProjetos.data_fim" placeholder="Data de Fim" dateFormat="dd/mm/yy"/>
          <span v-if="criarProjetos.data_inicio > criarProjetos.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
        </v-col>

        <v-bottom-sheet v-model="dialogCriarInterveniente">
          <v-container fluid>
            <v-row>
              <v-col cols="12 mb-3">
                <v-chip-group v-model="intervenientesSelecionados" multiple column>
                  <v-chip v-for="tecnico in tecnicos" :key="tecnico.id" :value="tecnico" class="mr-2 mt-3" :color="intervenientesSelecionados.includes(tecnico) ? 'primary' : ''">
                    {{ tecnico.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12 mb-3" class="text-right" v-if="intervenientesSelecionados != ''">
                <v-btn color="primary" class="white--text" @click="confirmarCriarProjeto">Criar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-bottom-sheet>

      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
  <script>
  //  Importar o service necessário
  import ProjetosService from '@/services/ProjetosService'
  import ClientesService from '@/services/ClientesService'
  import EstadosService from '@/services/EstadosService'
  import TopicosService from '@/services/TopicosService'
  import FuncionariosService from '@/services/FuncionariosService'
  import ProjetosFuncionarioService from '@/services/ProjetosFuncionarioService'
  import EmpresaTitutarService from '@/services/EmpresaTitutarService'
  import store from '@/store/store'
  import moment from 'moment-timezone'
  
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
      this.loading = true
      this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data
      this.clientes = (await ClientesService.index2()).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.empresaTitular = (await EmpresaTitutarService.index()).data
      this.loading = false
    },
    data () {
      return {
        loading: true,
        clientes: [],
        estados: [],
        topicos: [],
        tecnicos: [],
        empresaTitular: {},
        intervenientesSelecionados: [],
        criarProjetos: {
          numero_cliente: null,
          data_registo: null,
          quem_registou: this.$store.state.user.id,
          descricao: '',
          concluido: 0,
          id_topico: null,
          id_estado: 1,
          data_inicio: null,
          data_fim: null,
          nome_cliente: null,
          ano: null,
          numero: null,
        },
        criarIntervenientes: {
          id_projeto: null,
          id_funcionario: null,
          atribuido_data: null,
          atribuido_por: this.$store.state.user.id,
          lido: false
        },
  
        dialogCriarInterveniente: false,
        
        rules: {
          cliente: value => !!value || "Cliente é Obrigatório.",    
          consumidorfinal: value => !!value || "Nome consumidor final é Obrigatório.",
          datamenor: value => {
            if (this.criarProjetos.data_fim && value > this.criarProjetos.data_fim) {
              return "Data fim deve ser maior que a data de início.";
            }
            return true;
          }
        }
      }
    },
    methods: {
      goBack() {
        this.$router.push('projetos')
      },
      formatarDataParaISO(data) {
        if (data instanceof Date) {
          return moment(data).tz('Europe/Lisbon').toISOString();
        }
        return null;
      },
      //-------------------------------------------projeto------------------------------//
      //-------------------------------------------projeto------------------------------//
      //-------------------------------------------projeto------------------------------//
      async criarProjeto () {
        if (this.$refs.form.validate()) {
          this.dialogCriarInterveniente = true
        }
      },
      async confirmarCriarProjeto () {
        try{
          if (this.$refs.form.validate()) {
            this.clienteselecionado = (await ClientesService.show(this.criarProjetos.numero_cliente)).data
            if (this.clienteselecionado.numero != this.empresaTitular.numero_consumidor_final) {
              this.criarProjetos.nome_cliente = this.clienteselecionado.nome
            }
            this.criarProjetos.ano = new Date().getFullYear();

            if (this.empresaTitular.projetos_tipo == 'Anual') {
              //tem de ir buscar o maior numero do ano em que está, e se ainda não houver vai iniciar no número que estiver no projetos_tipo_desde
              this.maiornumeroprojetoano = (await ProjetosService.maiornumeroprojetoano()).data
              if (this.maiornumeroprojetoano.numero != undefined) {
                  this.criarProjetos.numero = this.maiornumeroprojetoano.numero + 1;
              } else {
                  this.criarProjetos.numero = this.empresaTitular.projetos_tipo_desde
              }
            } else {
              //tem de ir buscar o maior numero, e se ainda não houver vai iniciar no número que estiver no projetos_tipo_desde
              this.maiornumeroprojeto = (await ProjetosService.maiornumeroprojeto()).data
              if (this.maiornumeroprojeto.numero != undefined) {
                  this.criarProjetos.numero = this.maiornumeroprojeto.numero + 1;
              } else {
                  this.criarProjetos.numero = this.empresaTitular.projetos_tipo_desde
              }
            }
            this.criarProjetos.data_registo = this.formatarDataParaISO(new Date())
            await ProjetosService.post(this.criarProjetos)
            this.projetosultimo = (await ProjetosService.projetosultimo()).data
            for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
              this.criarIntervenientes.id_projeto = this.projetosultimo.id
              this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
              this.criarIntervenientes.atribuido_data = this.formatarDataParaISO(new Date())
              await ProjetosFuncionarioService.post(this.criarIntervenientes)
            }

            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Projeto criado com sucesso"
            });
            this.$store.dispatch('setProjeto', {
              idprojeto: this.projetosultimo.id,
            })
            this.$router.push({
              name: 'editarprojetos'
            })
        } else {
          this.dialogCriarInterveniente = false
        }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a criar projeto"
            });
        }
      }
    }
  }
  </script>
  <style>
  </style>