import Api from '@/services/Api'

export default {
  index (page, id_tarefa) {
    return Api().get('tarefasartigos',{
      params: {
        page,
        id_tarefa
      }
    })
  },
  post (elementos) {
    return Api().post('tarefasartigos', elementos)
  },
  put (elementos) {
    return Api().put(`tarefasartigos/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`tarefasartigos/${id}`)
  },
  delete (id) {
    return Api().delete(`tarefasartigos/${id}`)
  },
}
