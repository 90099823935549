
<template>
  <v-form ref="form">
    <v-container fluid v-if="$store.state.isUserLoggedIn && this.$store.state.funcionario != null || this.$store.state.funcionario.idfuncionario != null">
      <v-row v-if="loading == false">
        <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Criar funcionário</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="criarFuncionario" v-bind="attrs" v-on="on" class="ml-5">mdi-content-save</v-icon>
              </template>
              <span>Criar funcionário</span>
            </v-tooltip>
          </v-app-bar>
        </v-col>
        <span id="mobile-margintop-conteudo"></span>
        <v-col cols="12">
          <v-chip @click="toggleInativo" v-model="criarFuncionarios.inativo" :color="criarFuncionarios.inativo == false ? 'primary' : ''">Ativo</v-chip>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field type="text" v-model="criarFuncionarios.nome" clearable label="Nome" :rules="[rules.nome]"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field type="text" v-model="criarFuncionarios.email" clearable label="Email" :rules="[rules.email]"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field type="number" v-model="criarFuncionarios.telemovel" clearable label="Telemóvel"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field type="password" v-model="passwordInserida.password" clearable label="Password" :rules="[rules.senha]"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field type="password" v-model="passwordInserida.confirmpass" clearable label="Confirmar password" :rules="[rules.senhaconfirmar]"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field type="number" v-model="criarFuncionarios.numero_tecnico" clearable label="Número técnico"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field type="number" v-model="criarFuncionarios.numero_vendedor" clearable label="Número vendedor"></v-text-field>
        </v-col>
        
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template> 
<script>
//  Importar o service necessário
import FuncionariosService from '@/services/FuncionariosService'
import store from '@/store/store'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.loading = false
  },
  data () {
    return {
      loading: true,
      
      criarFuncionarios: {
          nome: null,
          email: null,
          telemovel: null,
          senha: null,
          senha_online: null,
          numero_tecnico: null,
          numero_vendedor: null,
          inativo: false,
      },

      passwordInserida: {
          password: '',
          confirmpass: ''
      },

      rules: {
          nome: value => !!value || "Número é Obrigatório.",
          email: value => {if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {return "Por favor, insira um endereço de email válido.";}return true;},
          senha: value => !!value || "Password é Obrigatória.",
          senhaconfirmar: value => { if (value !== this.passwordInserida.password) {return "Passwords não são iguais";}return true;}
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push('funcionarios')
    },
    async criarFuncionario () {
      try{
        if (this.$refs.form.validate()) {
          this.criarFuncionarios.senha = this.passwordInserida.password
          this.criarFuncionarios.senha_online = this.passwordInserida.password
          const funcionariocriado = (await FuncionariosService.post(this.criarFuncionarios)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Funcionário criado com sucesso"
          });
          this.$store.dispatch('setFuncionario', {
            idfuncionario: funcionariocriado.id,
          })
          this.$router.push({
            name: 'editarfuncionarios'
          })
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar funcionário"
          });
      }
    },
    toggleInativo() {
      this.criarFuncionarios.inativo = !this.criarFuncionarios.inativo;
    },
  }
}
</script>
<style>
</style>