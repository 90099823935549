<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Marcações</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="navigateToMarcacaoCriar({name: 'criarmarcacoes'})" v-bind="attrs" v-on="on" class="ml-5">mdi-plus</v-icon>
                    </template>
                    <span>Criar marcação</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="marcacoes.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getMarcacoes" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in marcacoes">
                                    <v-list-item :key="item.id" @click="navigateToMarcacaoEditar({name: 'editarmarcacoes', params:{idMarcacaoEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                                            <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                                                <span v-for="(func, index) in item.Funcionarios" :key="func.id">
                                                    {{ func.nome }}<span v-if="item.Funcionarios.length > 1 && index < item.Funcionarios.length - 1">;</span>
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-if="item.instalacoes_infordio == true">Nas instalações da infordio</v-list-item-action-text>
                                            <v-list-item-action-text v-else>Fora da Infordio</v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < marcacoes.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>   
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existem marcações</span></v-col> 
                </v-row>
            </v-card-text> 
        </v-card>
    </v-dialog>
</template>
<script>
import MarcacoesService from '@/services/MarcacoesService'
import infiniteScroll from "vue-infinite-scroll";
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            page: 1,
            busy: false,
            marcacoes: [],
            tarefa: {},
        }
    },
    methods: {
        async open(tarefa) {
            this.show = true
            this.page = 1
            this.marcacoes = []
            this.tarefa = tarefa
            this.getMarcacoes()
        },
        async getMarcacoes() {
            this.busy = true;
            this.loading = true;

            let response = await MarcacoesService.index(
                this.page,
                null,
                null,
                null,
                null,
                this.tarefa.id
            );

            this.marcacoes = this.marcacoes.concat(response.data);
            this.page++;
            this.busy = false;
            if (response.data.length == 0) {
                this.busy = true;
            }
            this.loading = false;
        },
        navigateToMarcacaoCriar (route) {
            this.$store.dispatch('setMarcacaoTarefa', {  
                tarefamarcacao: this.tarefa
            })
            this.$router.push(route)
        },
        navigateToMarcacaoEditar (route) {
            this.$store.dispatch('setMarcacao', {  
                idmarcacao: route.params.idMarcacaoEnviado,
            })
            this.$router.push(route)
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
    }
}
</script>