<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && configuracoessistema.activo == true">
        <v-flex>
            <v-card>
              <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Configurações módulos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <div class="row">
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 12">
                    <label>ERP utilizado</label>
                    <v-text-field :value="configuracoessistema.erp" disabled filled outlined></v-text-field>
                  </v-col>
                </div>
                <label>Data da ultima actulização de todos os clientes</label>
                <div class="row">
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">  
                      <MYdatetime type="date" v-model="configuracoessistema.data_atualizacao_clientes" label="Data" disabled></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5">
                    <v-text-field v-if="configuracoessistema.modulo_clientes == true" value="Módulo Clientes" disabled filled outlined class="moduloactivo"></v-text-field>
                    <v-text-field v-else value="Módulo Clientes" disabled filled outlined class="modulonaoactivo"></v-text-field>
                  </v-col>
                  <v-col v-if="configuracoessistema.modulo_clientes == true" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-icon v-if="botaoimportarclientes != false" class="botaoimportar" color="green" @click="importarClientes" title="Importar clientes">mdi-arrow-right-box</v-icon>
                    <v-icon v-if="botaoimportarclientes != true" class="botaocancelarimportar" color="red" @click="cancelarImportarClientes" title="Cancelar importar clientes">mdi-close-box</v-icon>
                    <template v-if="barraclientes != false">
                      <div>
                        <v-progress-linear color="light-blue" height="17" indeterminate></v-progress-linear>
                      </div>
                    </template>
                    <br>
                    <span v-if="textoclientesatualizados == true" class="txt-verde">Clientes importados com sucesso!!!</span>
                  </v-col>
                </div>
                <div class="row">
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">  
                      <MYdatetime type="date" v-model="configuracoessistema.data_atualizacao_contas_corrente" label="Data" disabled></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5">
                    <v-text-field v-if="configuracoessistema.modulo_contas_correntes == true" value="Módulo Contas Correntes" disabled filled outlined class="moduloactivo"></v-text-field>
                    <v-text-field v-else value="Módulo Contas Correntes" disabled filled outlined class="modulonaoactivo"></v-text-field>
                  </v-col>
                  <v-col v-if="configuracoessistema.modulo_contas_correntes == true" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-icon v-if="botaoimportarcontascorrentes != false" class="botaoimportar" color="green" @click="importarContasCorrentes" title="Importar contas correntes">mdi-arrow-right-box</v-icon>
                    <v-icon v-if="botaoimportarcontascorrentes != true" class="botaocancelarimportar" color="red" @click="cancelarImportarContasCorrentes" title="Cancelar importar contas correntes">mdi-close-box</v-icon>
                    <template v-if="barracontascorrentes != false">
                      <div>
                        <v-progress-linear color="light-blue" height="17" indeterminate></v-progress-linear>
                      </div>
                    </template>
                    <br>
                    <span v-if="textocontascorrentesatualizados == true" class="txt-verde">Contas correntes importadas com sucesso!!!</span>
                  </v-col>
                </div>
                <div class="row">
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">  
                      <MYdatetime type="date" v-model="configuracoessistema.data_atualizacao_equipamentos" label="Data" disabled></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5">
                    <v-text-field v-if="configuracoessistema.modulo_equipamentos == true" value="Módulo Equipamentos" disabled filled outlined class="moduloactivo"></v-text-field>
                    <v-text-field v-else value="Módulo Equipamentos" disabled filled outlined class="modulonaoactivo"></v-text-field>
                  </v-col>
                  <v-col v-if="configuracoessistema.modulo_equipamentos == true" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-icon v-if="botaoimportarequipamentos != false" class="botaoimportar" color="green" @click="importarEquipamentos" title="Importar equipamentos">mdi-arrow-right-box</v-icon>
                    <v-icon v-if="botaoimportarequipamentos != true" class="botaocancelarimportar" color="red" @click="cancelarImportarEquipamentos" title="Cancelar importar equipamentos">mdi-close-box</v-icon>
                    <template v-if="barraequipamentos != false">
                      <div>
                        <v-progress-linear color="light-blue" height="17" indeterminate></v-progress-linear>
                      </div>
                    </template>
                    <br>
                    <span v-if="textoequipamentosatualizados == true" class="txt-verde">Equipamentos importados com sucesso!!!</span>
                  </v-col>
                </div>
                <div class="row">
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">  
                      <MYdatetime type="date" v-model="configuracoessistema.data_atualizacao_artigos" label="Data" disabled></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5">
                    <v-text-field v-if="configuracoessistema.modulo_artigos == true" value="Módulo Artigos" disabled filled outlined class="moduloactivo"></v-text-field>
                    <v-text-field v-else value="Módulo Artigos" disabled filled outlined class="modulonaoactivo"></v-text-field>
                  </v-col>
                  <v-col v-if="configuracoessistema.modulo_artigos == true" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-icon v-if="botaoimportarartigos != false" class="botaoimportar" color="green" @click="importarArtigos" title="Importar artigos">mdi-arrow-right-box</v-icon>
                    <v-icon v-if="botaoimportarartigos != true" class="botaocancelarimportar" color="red" @click="cancelarImportarArtigos" title="Cancelar importar artigos">mdi-close-box</v-icon>
                    <template v-if="barraartigos != false">
                      <div>
                        <v-progress-linear color="light-blue" height="17" indeterminate></v-progress-linear>
                      </div>
                    </template>
                    <br>
                    <span v-if="textoartigosatualizados == true" class="txt-verde">Artigos importados com sucesso!!!</span>
                  </v-col>
                </div>
              </v-card-text>
              <!-- Dialog confirmar importar clientes -->
              <v-dialog v-model="dialogImportarClientes">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogImportarClientes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo importar os clientes do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarImportarClientes">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar importar contas correntes -->
              <v-dialog v-model="dialogImportarContasCorrentes">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogImportarContasCorrentes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo importar as contas correntes do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarImportarContasCorrentes">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar importar equipamentos -->
              <v-dialog v-model="dialogImportarEquipamentos">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogImportarEquipamentos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo importar os equipamentos do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarImportarEquipamentos">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar importar artigos -->
              <v-dialog v-model="dialogImportarArtigos">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogImportarArtigos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo importar os artigos do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarImportarArtigos">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar cancelar importar clientes -->
              <v-dialog v-model="dialogCancelarImportarClientes">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogCancelarImportarClientes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo cancelar a importação dos clientes do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarCancelarImportarClientes">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar cancelar importar contas correntes -->
              <v-dialog v-model="dialogCancelarImportarContasCorrentes">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogCancelarImportarContasCorrentes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo cancelar a importação das contas correntes do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarCancelarImportarContasCorrentes">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar cancelar importar equipamentos -->
              <v-dialog v-model="dialogCancelarImportarEquipamentos">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogCancelarImportarEquipamentos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo cancelar a importação dos equipamentos do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarCancelarImportarEquipamentos">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Dialog confirmar cancelar importar clientes -->
              <v-dialog v-model="dialogCancelarImportarArtigos">
                <v-card>
                  <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogCancelarImportarArtigos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                  </v-toolbar>
                  <v-col cols="12">
                      <span>Quer mesmo cancelar a importação dos artigos do ERP?</span>
                  </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarCancelarImportarArtigos">Confirmar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
        </v-flex>
    </v-layout>
</template>
    
<script>
//  Importar o service necessário
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import ClientesService from '@/services/ClientesService'
import ContasCorrentesService from '@/services/ContasCorrentesService'
import EquipamentosService from '@/services/EquipamentosService'
import ArtigosService from '@/services/ArtigosService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
        this.$router.push({
            name: 'mylogin'
        })
    }
    this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
    if (this.configuracoessistema.activo == false) {
      this.$router.push({
          name: 'myhome'
      })
    }
    this.clientes = (await ClientesService.index2()).data
    /*Actulização automática*/
    /*
    if (this.configuracoessistema.activo == true) {
      const now = new Date();
      let millisTill18 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0, 0) - now;
      if (millisTill18 < 0) {
        millisTill18 += 86400000;
      }
      setTimeout(async () => {
        if (this.configuracoessistema.modulo_clientes == true) {
          await this.confirmarImportarClientes();
        }
      }, millisTill18);
    }
    */
  },
  data () {
    return {
      criareditarConfiguracao: {
          activo: true,
          modulo_clientes: true,
          modulo_contas_correntes: true,
          modulo_equipamentos: true,
          erp: null,
      },
      scheduledTime: null,
      configuracoessistema: {},
      //clientes
      clientes: [],
      botaoimportarclientes: true,
      dialogImportarClientes: false,
      dialogCancelarImportarClientes: false,
      barraclientes: false,
      clienteserp: [],
      textoclientesatualizados: false,
      //contascorrentes
      botaoimportarcontascorrentes: true,
      dialogImportarContasCorrentes: false,
      dialogCancelarImportarContasCorrentes: false,
      barracontascorrentes: false,
      faturaserp: [],
      textocontascorrentesatualizados: false,
      //equipamentos
      botaoimportarequipamentos: true,
      dialogImportarEquipamentos: false,
      dialogCancelarImportarEquipamentos: false,
      barraequipamentos: false,
      equipamentoserp: [],
      textoequipamentosatualizados: false,
      //artigos
      botaoimportarartigos: true,
      dialogImportarArtigos: false,
      dialogCancelarImportarArtigos: false,
      barraartigos: false,
      artigoserp: [],
      textoartigosatualizados: false
    }
  },
  methods: {
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    async CriarConfiguracao () {
      document.location.reload(true)
      await ConfiguracoesSistemaService.post(this.criareditarConfiguracao)
    },

    
    // Modulo Clientes
    // Modulo Clientes
    // Modulo Clientes
    async importarClientes () {
      this.dialogImportarClientes = true
    },
    async confirmarImportarClientes () {
      this.textoclientesatualizados = false
      this.botaoimportarclientes = false
      this.dialogImportarClientes = false
      this.barraclientes = true
      this.clienteserp = (await ClientesService.importarclienteserp(null)).data
      this.botaoimportarclientes = true
      this.dialogCancelarImportarClientes = false
      this.barraclientes = false
      this.textoclientesatualizados = true
    },
    async cancelarImportarClientes () {
      this.dialogCancelarImportarClientes = true
    },
    async confirmarCancelarImportarClientes () {
      this.botaoimportarclientes = true
      this.dialogCancelarImportarClientes = false
      this.barraclientes = false
      document.location.reload(true)
    },


    // Modulo Contas Correntes
    // Modulo Contas Correntes
    // Modulo Contas Correntes
    async importarContasCorrentes () {
      this.dialogImportarContasCorrentes = true
    },
    async confirmarImportarContasCorrentes () {
      this.textocontascorrentesatualizados = false
      this.botaoimportarcontascorrentes = false
      this.dialogImportarContasCorrentes = false
      this.barracontascorrentes = true
      this.faturaserp = (await ContasCorrentesService.importarcontascorrenteserp(null, this.configuracoessistema.data_atualizacao_contas_corrente)).data
      this.botaoimportarcontascorrentes = true
      this.dialogCancelarImportarContasCorrentes = false
      this.barracontascorrentes = false
      this.textocontascorrentesatualizados = true
    },
    async cancelarImportarContasCorrentes () {
      this.dialogCancelarImportarContasCorrentes = true
    },
    async confirmarCancelarImportarContasCorrentes () {
      this.botaoimportarcontascorrentes = true
      this.dialogCancelarImportarContasCorrentes = false
      this.barracontascorrentes = false
      document.location.reload(true)
    },


    // Modulo Equipamentos
    // Modulo Equipamentos
    // Modulo Equipamentos
    async importarEquipamentos () {
      this.dialogImportarEquipamentos = true
    },
    async confirmarImportarEquipamentos () {
      this.textoequipamentosatualizados = false
      this.botaoimportarequipamentos = false
      this.dialogImportarEquipamentos = false
      this.barraequipamentos = true
      this.equipamentoserp = (await EquipamentosService.importarequipamentoserp(null)).data
      this.botaoimportarequipamentos = true
      this.dialogCancelarImportarEquipamentos = false
      this.barraequipamentos = false
      this.textoequipamentosatualizados = true
    },
    async cancelarImportarEquipamentos () {
      this.dialogCancelarImportarEquipamentos = true
    },
    async confirmarCancelarImportarEquipamentos () {
      this.botaoimportarequipamentos = true
      this.dialogCancelarImportarEquipamentos = false
      this.barraequipamentos = false
      document.location.reload(true)
    },




    // Modulo Artigos
    // Modulo Artigos
    // Modulo Artigos
    async importarArtigos () {
      this.dialogImportarArtigos = true
    },
    async confirmarImportarArtigos () {
      this.textoartigosatualizados = false
      this.botaoimportarartigos = false
      this.dialogImportarArtigos = false
      this.barraartigos = true
      this.artigoserp = (await ArtigosService.importarartigoserp(null)).data
      this.botaoimportarartigos = true
      this.dialogCancelarImportarArtigos = false
      this.barraartigos = false
      this.textoartigosatualizados = true
    },
    async cancelarImportarArtigos () {
      this.dialogCancelarImportarArtigos = true
    },
    async confirmarCancelarImportarArtigos () {
      this.botaoimportarartigos = true
      this.dialogCancelarImportarArtigos = false
      this.barraartigos = false
      document.location.reload(true)
    },
  }
}
</script>
<style>
.moduloactivo .v-input__slot {
  background-color: rgb(158, 247, 158) !important;
}
.modulonaoactivo .v-input__slot {
  background-color: rgb(255, 147, 147) !important;
}
.botaoimportar {
  font-size: 33px !important;
}
.botaocancelarimportar {
  font-size: 33px !important;
}
.txt-verde {
  color: green;
  font-weight: bold;
}
</style>