<template>
    <v-form ref="form">
        <v-container fluid v-if="$store.state.isUserLoggedIn  && this.$store.state.cliente != null || this.$store.state.cliente.numerocliente != null">
        <v-row v-if="loading == false">
            <v-col cols="12" class="p-0">
                <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
                    <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
                    <v-app-bar-title>Cliente</v-app-bar-title>
                    <v-spacer></v-spacer>  
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="navigateToTempoCriar({name: 'criartemposclientes'})" v-bind="attrs" v-on="on" class="ml-5">mdi-clock-plus</v-icon>
                        </template>
                        <span>Criar tempo</span>
                    </v-tooltip>
                    <div class="d-none d-lg-block" style="height: 50%; width: 20px; border-right: 3px solid white;"></div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="atualizarCliente" v-bind="attrs" v-on="on" class="ml-5">mdi-reload</v-icon>
                        </template>
                        <span>Atualizar cliente</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="navigateToCliente({name: 'verclientes'})" v-bind="attrs" v-on="on" class="ml-5">mdi-eye</v-icon>
                        </template>
                        <span>Ver cliente</span>
                    </v-tooltip>
                    <!--
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="apagarCliente(editarClientes.id)" v-bind="attrs" v-on="on" class="ml-5">mdi-trash-can-outline</v-icon>
                        </template>
                        <span>Apagar cliente</span>
                    </v-tooltip>
                    -->
                    <!--
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="editarCliente" v-bind="attrs" v-on="on" class="ml-5">mdi-content-save</v-icon>
                        </template>
                        <span>Guardar cliente</span>
                    </v-tooltip>
                    -->
                    <!--Mobile-->
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="ml-5 d-lg-none">mdi-dots-vertical</v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="navigateToTempoCriar({name: 'criartemposclientes'})">
                                <v-list-item-title>Criar tempo</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-app-bar>
            </v-col>
            <span id="mobile-margintop-conteudo"></span>
            <v-col cols="12">
                <v-chip @click="toggleInativo" v-model="editarClientes.inativo" :color="editarClientes.inativo == false ? 'primary' : ''">Ativo</v-chip>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field type="number" v-model="editarClientes.numero" label="Número" :rules="[rules.numero]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field type="text" v-model="editarClientes.nome" clearable label="Nome" :rules="[rules.nome]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field type="text" v-model="editarClientes.nome2" clearable label="Nome2"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field type="text" v-model="editarClientes.saldo" clearable label="Saldo (€)"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field type="number" v-model="editarClientes.contribuinte" clearable label="Contribuinte"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field type="number" v-model="editarClientes.telefone_empresa" clearable label="Telefone"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field type="number" v-model="editarClientes.telemovel_empresa" clearable label="Telemóvel"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field type="text" v-model="editarClientes.email_empresa" clearable label="E-mail"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field type="text" v-model="editarClientes.site" clearable label="Site"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field type="text" v-model="editarClientes.morada" clearable label="Morada"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field type="text" v-model="editarClientes.codigo_postal" clearable label="Código postal"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field type="text" v-model="editarClientes.localidade" clearable label="Localidade"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field type="text" v-model="editarClientes.vencimento" clearable label="Vencimento"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field type="text" v-model="editarClientes.limite_dias" clearable label="Limite dias"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field type="text" v-model="editarClientes.desconto" clearable label="Desconto"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.nome_comercial" clearable label="Nome comercial"></v-text-field>
            </v-col>
            <v-col cols="12 col-md-3">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="editarVendedor" v-bind="attrs" v-on="on" class="mr-1">mdi-pencil-outline</v-icon>
                    </template>
                    <span>Editar técnico</span>
                </v-tooltip>
                <span class="pb-3"><b>Vendedor:</b>
                    <template v-for="funcvend in funcionariosvendedores">
                        <template v-if="funcvend.numero_vendedor == editarClientes.numero_vendedor">
                            {{funcvend.nome}}
                        </template>
                    </template>
                </span>
                <v-dialog v-model="dialogEditarVendedor">
                    <v-card>
                        <v-toolbar class="mb-2" color="primary" dark flat>
                            <v-toolbar-title>Editar vendedor</v-toolbar-title>
                        </v-toolbar>
                        <v-col cols="12">
                            <v-select v-model="editarClientes.numero_vendedor" :items="vendedores" item-text="nome" item-value="numero_vendedor" label="Vendedor"></v-select>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="white--text" @click="confirmarEditarVendedor">Editar vendedor</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="12 col-md-3">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="editarTecnico" v-bind="attrs" v-on="on" class="mr-1">mdi-pencil-outline</v-icon>
                    </template>
                    <span>Editar técnico</span>
                </v-tooltip>
                <span class="pb-3"><b>Técnico:</b>
                    <template v-if="editarClientes.Clientes_Outro != null">
                        <template v-for="functec in funcionariostecnicos">
                            <template v-if="functec.numero_tecnico == editarClientes.Clientes_Outro.numero_tecnico">
                                {{functec.nome}}
                            </template>
                        </template>
                    </template>
                </span>
                <v-dialog v-model="dialogEditarTecnico">
                    <v-card>
                        <v-toolbar class="mb-2" color="primary" dark flat>
                            <v-toolbar-title>Editar técnico</v-toolbar-title>
                        </v-toolbar>
                        <v-col cols="12">
                            <v-select v-model="criareditarTecnicos.numero_tecnico" :items="tecnicos" item-text="nome" item-value="numero_tecnico" label="Técnico"></v-select>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="white--text" @click="confirmarEditarTecnico">Editar técnico</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="12">
                <v-text-field type="text" v-model="editarClientes.link_maps" clearable label="Link maps"></v-text-field>
            </v-col>
            <v-col cols="12">
                <label>Observações</label>
                <wysiwyg v-model="editarClientes.observacoes"/>
            </v-col>
        
            <v-col cols="12">
                <h3>Contacto 1</h3>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.contacto1" clearable label="Contacto"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarClientes.telefone1" clearable label="Telefone"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarClientes.telemovel1" clearable label="Telemóvel"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.email1" clearable label="E-mail"></v-text-field>
            </v-col>
            <v-col cols="12">
                <h3>Contacto 2</h3>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.contacto2" clearable label="Contacto"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarClientes.telefone2" clearable label="Telefone"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarClientes.telemovel2" clearable label="Telemóvel"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.email2" clearable label="E-mail"></v-text-field>
            </v-col>
            <v-col cols="12">
                <h3>Contacto 3</h3>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.contacto3" clearable label="Contacto"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarClientes.telefone3" clearable label="Telefone"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model="editarClientes.telemovel3" clearable label="Telemóvel"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="text" v-model="editarClientes.email3" clearable label="E-mail"></v-text-field>
            </v-col>
            <v-col cols="12" v-if="this.configuracoessistema.modulo_clientes == false">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <img @click="editarCliente" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                </v-card-actions>
            </v-col>
            <!-- Dialog apagar cliente -->
            <v-dialog v-model="dialogApagarCliente">
                <v-card>
                    <v-toolbar class="mb-2" color="red" dark flat>
                        <v-toolbar-title>Apagar cliente</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogApagarCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Quer mesmo apagar o cliente?</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" class="white--text" @click="confirmarApagarCliente">Apagar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogNumeroContribuinteJaExiste">
                <v-card>
                    <v-toolbar color="primary" class="mb-2" dark flat>
                        <v-toolbar-title>Contribuinte já existe</v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Quer editar o cliente com o contribuinte {{ editarClientes.contribuinte }}?</span>
                    </v-col>
                    <v-card-actions>
                        <v-btn color="primary" class="white--text" @click="dialogNumeroContribuinteJaExiste = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="white--text" @click="confirmarCriarClienteComContribuinte">Editar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        </v-container>
    </v-form>
</template> 
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesOutrosService from '@/services/ClientesOutrosService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import store from '@/store/store'
export default {
  async mounted () {
    this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
      this.$router.push({
        name: 'clientes'
      })
    } else {
        this.loading = true
        this.getCliente()
        this.funcionariosvendedores = (await FuncionariosService.index2(null, null, ' ')).data
        this.funcionariostecnicos = (await FuncionariosService.index2(null, ' ', null)).data
        this.vendedores = (await FuncionariosService.index2(false, null, ' ')).data
        this.tecnicos = (await FuncionariosService.index2(false, ' ', null)).data
        this.criareditarTecnicos.numero_cliente = this.editarClientes.numero
        if (this.editarClientes.Clientes_Outro != null) {
            this.criareditarTecnicos.numero_tecnico = this.editarClientes.Clientes_Outro.numero_tecnico
        }
        this.loading = false
    }
  },
  data () {
    return {
        loading: true,
        configuracoessistema: {},
        editarClientes: {
            numero: null,
            nome: null,
            nome2: null,
            contribuinte: null,
            morada: null,
            codigo_postal: null,
            localidade: null,
            telefone_empresa: null,
            telemovel_empresa: null,
            email_empresa: null,
            site: null,
            numero_vendedor: null,
            observacoes: null,
            contacto1: null,
            telefone1: null,
            telemovel1: null,
            email1: null,
            contacto2: null,
            telefone2: null,
            telemovel2: null,
            email2: null,
            contacto3: null,
            telefone3: null,
            telemovel3: null,
            email3: null,
            inativo: false,
            vencimento: null,
            limite_dias: null,
            saldo: null,
            desconto: null,
            nome_comercial: null,
            link_maps: null
        },
        criareditarTecnicos: {
            numero_cliente: null,
            numero_tecnico: null
        },
        clientecontribuinte: {
            contribuinte: null
        },

        funcionariosvendedores: [],
        funcionariostecnicos: [],
        vendedores: [],
        tecnicos: [],
        clienteserp: [],

        numeroclienteglobal: '',

        dialogApagarCliente: false,
        dialogEditarTecnico: false,
        dialogEditarVendedor: false,
        dialogNumeroContribuinteJaExiste: false,

        rules: {
            numero: value => !!value || "Número é Obrigatório.",
            nome: value => !!value || "Nome é Obrigatório."
        }
    }
  },
  methods: {
    goBack() {
        this.$router.push('clientes')
    },
    async getCliente() {
        this.editarClientes = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
    },
    async editarCliente () {
      if (this.$refs.form.validate()) {
        if (this.editarClientes.contribuinte != null) {
            this.clientecontribuinte = (await ClientesService.showcontribuinte(this.editarClientes.contribuinte)).data
        } 
        if (this.clientecontribuinte.contribuinte != undefined) {
            this.dialogNumeroContribuinteJaExiste = true
        } else {
          try{
              await ClientesService.put(this.editarClientes)
              this.getCliente()
              this.$store.dispatch("setSnackBar", {
                  color: "success",
                  text: "Cliente editado com sucesso"
              });
          } catch (error) {
              this.$store.dispatch("setSnackBar", {
                  color: "error",
                  text: "Erro a editar cliente"
              });
          }
        }
      }
    },
    async confirmarCriarClienteComContribuinte () {
      try {
        await ClientesService.put(this.editarClientes)
        this.getCliente()
        this.dialogNumeroContribuinteJaExiste = false
        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Cliente editado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar cliente"
          });
      }
    },
    async apagarCliente (numero) {
      this.dialogApagarCliente = true
      this.numeroclienteglobal = numero
    },
    async confirmarApagarCliente () {
      try{
        await ClientesService.delete(this.numeroclienteglobal)
        this.dialogApagarCliente = false
        this.$store.dispatch("setSnackBar", {
          color: "success",
          text: "Cliente apagado com sucesso"
        });
        this.$router.push('clientes')
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          color: "error",
          text: "Erro a apagar cliente, pode ter coisas associadas"
        });
      }
    },
    async editarVendedor () {
        this.dialogEditarVendedor = true
    },
    async confirmarEditarVendedor () {
        try {
            await ClientesService.put(this.editarClientes, this.editarClientes.numero)
            this.dialogEditarVendedor = false
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Vendedor editado com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar Vendedor"
            });
        }
    },
    async editarTecnico () {
        this.dialogEditarTecnico = true
    },
    async confirmarEditarTecnico () {
        if (this.editarClientes.Clientes_Outro != null) {
            try {
                this.clienteoutroexiste = (await ClientesOutrosService.clienteoutroexiste(this.criareditarTecnicos)).data
                await ClientesOutrosService.put(this.criareditarTecnicos, this.clienteoutroexiste.id)
                this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Técnico editado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar técnico"
                });
            }
        } else {
            try {
                this.criareditarTecnicos.numero_cliente = this.$store.state.cliente.numerocliente
                await ClientesOutrosService.post(this.criareditarTecnicos)
                this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Técnico adicionado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a adicionar técnico"
                });
            }
        }
        this.dialogEditarTecnico = false
        this.getCliente()
    },
    async atualizarCliente () {
        try {
            this.clienteserp = (await ClientesService.importarclienteserp(this.$store.state.cliente.numerocliente)).data
            this.getCliente()
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Cliente atualizado com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a atualizar cliente"
            });
        }
        
    },
    toggleInativo() {
        this.editarClientes.inativo = !this.editarClientes.inativo;
    },
    navigateToTempoCriar (route) {
      this.$store.dispatch('setTempoCliente', {  
        numeroclientetempo: this.editarClientes.numero
      })
      this.$router.push(route)
    },
    navigateToCliente (route) {
      this.$store.dispatch('setCliente', {  
        numerocliente: this.editarClientes.numero
      })
      this.$router.push(route)
    },
  }
}
</script>
<style>
</style>