import Api from '@/services/Api'

export default {
  index (page, mensagem_resposta, id_funcionario, id_tarefa, id_marcacao, id_projeto) {
    return Api().get('mensagens', {
      params: {
        page,
        mensagem_resposta,
        id_funcionario,
        id_tarefa,
        id_marcacao,
        id_projeto
      }
    })
  },
  post (elementos) {
    return Api().post('mensagens', elementos)
  },
  show (id) {
    return Api().get(`mensagens/${id}`)
  },
  put (elementos) {
    return Api().put(`mensagens/${elementos.id}`, elementos)
  },
  delete (id) {
    return Api().delete(`mensagens/${id}`)
  },
  ultimamensagemassunto (id) {
    return Api().get(`mensagens/ultimamensagemassunto/${id}`)
  },
  chatmensagens (id) {
    return Api().get(`mensagens/chatmensagens/${id}`)
  }
}
