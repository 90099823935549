<template>
    <v-container fluid v-if="$store.state.isUserLoggedIn">
        <v-row>      
            <v-col cols="12" class="p-0">
                <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
                <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
                <v-app-bar-title>Funcionários</v-app-bar-title>
                <v-spacer></v-spacer>
                <v-icon @click="navigateToFuncionarioCriar({name: 'criarfuncionarios'})" class="ml-5">mdi-plus</v-icon>
                <v-icon @click.stop="dialogFiltrarFuncionarios = true" class="ml-5">mdi-magnify</v-icon>
                </v-app-bar>
            </v-col>
            <span id="mobile-margintop-conteudo"></span>
            <v-col cols="12">
                <v-list twoo-line>
                <v-list-item-group v-infinite-scroll="getFuncionarios" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >     
                    <template v-for="(item, index) in funcionarios">
                        <v-list-item :key="item.nome" @click="navigateToFuncionarioEditar({name: 'editarfuncionarios', params:{idFuncionarioEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.inativo == true ? 'red' : 'green')}">
                            <v-list-item-content>
                                <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome }}</v-list-item-title>
                                <v-list-item-subtitle style="font-size: 13px;">{{ item.email }}</v-list-item-subtitle>
                                <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">{{ item.telemovel }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index < funcionarios.length - 1" :key="index"></v-divider>
                    </template>
                </v-list-item-group>
                <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                </v-list>
            </v-col>
            <!-- Dialog filtrar funcionários -->
            <v-bottom-sheet v-model="dialogFiltrarFuncionarios">
                <v-container fluid>
                <v-row>
                    <v-col cols="12 mt-3">
                        <v-chip @click="filtrarNaoInativos" :color="filtrarFuncionarios.inativo == false ? 'primary' : ''" class="mr-2">Ativo</v-chip>
                        <v-chip @click="filtrarInativos" :color="filtrarFuncionarios.inativo == true ? 'primary' : ''" class="mr-2">Não ativo</v-chip>
                        <v-chip @click="filtrarTodos" :color="filtrarFuncionarios.inativo == null ? 'primary' : ''" class="mr-2">Todos</v-chip>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete @change="filtrarFuncionario" v-model="filtrarFuncionarios.nome" :items="funcionarios2" item-text="nome" item-value="nome" label="Funcionário" clearable></v-autocomplete>
                    </v-col>
                </v-row>
                </v-container>
            </v-bottom-sheet>
        </v-row>
    </v-container>
</template>

<script>
//  Importar o service necessário
import FuncionariosService from '@/services/FuncionariosService'
import infiniteScroll from "vue-infinite-scroll";
import store from '@/store/store'
export default {
    directives: {
        infiniteScroll
    },
    async mounted () {
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        }
        
        this.funcionarios2 = (await FuncionariosService.index2()).data
    },
    data () {
        return {
            filtrarFuncionarios: {
                inativo: false,
                nome: null,
            },        
            funcionarios: [],
            funcionarios2: [],
            dialogFiltrarFuncionarios: false,
            
            loading: true,
            page: 1,
            busy: false,
        }
    },
    methods: {
        async getFuncionarios() {
            this.busy = true;
            this.loading = true;
            const response = await FuncionariosService.index(
                this.page,
                this.filtrarFuncionarios.inativo,
                this.filtrarFuncionarios.nome
            );
            this.funcionarios = this.funcionarios.concat(response.data);
            this.page++;
            this.busy = false;
            if (response.data.length == 0) {
                this.busy = true;
            }
            this.loading = false;
        },
        goBack() {
            this.$router.push('home')
        },
        // filtrar funcionários
        async filtrarTodos () {
            this.filtrarFuncionarios.inativo = null
            this.filtrar();
        },
        async filtrarNaoInativos () {
            this.filtrarFuncionarios.inativo = false
            this.filtrar();
        },
        async filtrarInativos () {
            this.filtrarFuncionarios.inativo = true
            this.filtrar();
        },
        async filtrarFuncionario () {
         this.filtrar();
        },
        filtrar () {
            this.funcionarios = []
            this.page = 1
            this.getFuncionarios();
        },
        navigateToFuncionarioCriar (route) {
            this.$router.push(route)
        },
        navigateToFuncionarioEditar (route) {
            this.$store.dispatch('setFuncionario', {  
                idfuncionario: route.params.idFuncionarioEnviado,
            })
            this.$router.push(route)
        },
    }
}
</script>
<style>
</style>