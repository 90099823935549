import Api from '@/services/Api'

export default {
  index (page, concluido, numero_cliente, concluido_funcionario, id_funcionario, id_projeto) {
    return Api().get('tarefas',{
      params: {
        page,
        concluido,
        numero_cliente,
        concluido_funcionario,
        id_funcionario,
        id_projeto
      }
    })
  },
  post (elementos) {
    return Api().post('tarefas', elementos)
  },
  put (elementos) {
    return Api().put(`tarefas/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`tarefas/${id}`)
  },
  delete (id) {
    return Api().delete(`tarefas/${id}`)
  },
  tarefasultima () {
    return Api().get(`tarefasultima`)
  },
  maiornumerotarefaano () {
    return Api().get(`maiornumerotarefaano`)
  },
  maiornumerotarefa () {
    return Api().get(`maiornumerotarefa`)
  },
}
