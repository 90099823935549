<template>
  <v-container fluid v-if="$store.state.isUserLoggedIn">
    <v-row>      
      <v-col cols="12" class="p-0">
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
          <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
          <v-app-bar-title>Marcações</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-icon @click="navigateToCalendario({name: 'calendario'})" class="ml-5">mdi-calendar-month-outline</v-icon>
          <v-icon @click.stop="dialogFiltrarMarcacoes = true" class="ml-5">mdi-magnify</v-icon>
        </v-app-bar>
      </v-col>
      <span id="mobile-margintop-conteudo"></span>
      <v-col cols="12">
        <v-list twoo-line>
          <v-list-item-group v-infinite-scroll="getMarcacoes" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >     
            <template v-for="(item, index) in marcacoes">
              <v-list-item :key="item.id" @click="navigateToMarcacaoEditar({name: 'editarmarcacoes', params:{idMarcacaoEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                  <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                  <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                    <span v-for="(func, index) in item.Funcionarios" :key="func.id">
                        {{ func.nome }}<span v-if="item.Funcionarios.length > 1 && index < item.Funcionarios.length - 1">;</span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text v-if="item.instalacoes_infordio == true">Nas instalações da infordio</v-list-item-action-text>
                    <v-list-item-action-text v-else>Fora da Infordio</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < marcacoes.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-list>
      </v-col>
      <!-- Dialog filtrar marcação -->
      <v-bottom-sheet v-model="dialogFiltrarMarcacoes">
        <v-container fluid>
          <v-row>
            <v-col cols="12 mt-3">
              <v-chip @click="filtrarNaoConcluidas" :color="filtrarMarcacoes.concluido == false ? 'primary' : ''" class="mr-2">Por concluir</v-chip>
              <v-chip @click="filtrarConcluidas" :color="filtrarMarcacoes.concluido == true ? 'primary' : ''" class="mr-2">Concluídas</v-chip>
              <v-chip @click="filtrarTodas" :color="filtrarMarcacoes.concluido == null ? 'primary' : ''" class="mr-2">Todas</v-chip>
            </v-col>
            <v-col cols="12">
              <v-autocomplete @change="filtrarCliente" v-model="filtrarMarcacoes.numero_cliente" :items="clientes" item-text="nome" item-value="numero" label="Cliente" clearable></v-autocomplete>
            </v-col>
            <v-col cols="12 mb-3">
              <v-chip class="mr-2 mt-3" @click="filtrarFuncionario(null)" :color="filtrarMarcacoes.id_funcionario === null ? 'primary' : ''">Todos</v-chip>
                <v-chip v-for="funcionario in funcionarios" :key="funcionario.id" class="mr-2 mt-3" @click="filtrarFuncionario(funcionario.id)" :color="filtrarMarcacoes.id_funcionario === funcionario.id ? 'primary' : ''">
                  {{ funcionario.nome }}
                </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </v-bottom-sheet>
    </v-row>
  </v-container>
</template>
  
<script>
//  Importar o service necessário
import MarcacoesService from '@/services/MarcacoesService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
import infiniteScroll from "vue-infinite-scroll";
export default {
  directives: {
    infiniteScroll
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.filtrarMarcacoes.numero_cliente = this.$store.state.cliente.numerocliente
    this.funcionarios = (await FuncionariosService.index2()).data
    this.clientes = (await ClientesService.index2()).data
  },
  data () {
    return {
      fab: false,
      funcionarios: [],
      marcacoes: [],
      clientes: [],
      filtrarMarcacoes: {
        concluido: false,
        numero_cliente: null,
        concluido_funcionario: false,
        id_funcionario: this.$store.state.funcionarioselect.idfuncionario
      },

      dialogFiltrarMarcacoes: false,

      loading: true,
      page: 1,
      busy: false,
    }
  },
  methods: {
    async getMarcacoes() {
      this.busy = true;
      this.loading = true;
      const response = await MarcacoesService.index(
        this.page,
        this.filtrarMarcacoes.concluido,
        this.filtrarMarcacoes.numero_cliente,
        this.filtrarMarcacoes.concluido_funcionario,
        this.filtrarMarcacoes.id_funcionario
      );
      this.marcacoes = this.marcacoes.concat(response.data);
      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    goBack() {
      this.$router.push('home')
    },
    // filtrar marcações
    async filtrarFuncionario (value) {
      this.filtrarMarcacoes.id_funcionario = value
      this.filtrar();
    },
    async filtrarTodas () {
      this.filtrarMarcacoes.concluido = null
      this.filtrarMarcacoes.concluido_funcionario = null
      this.filtrar();
    },
    async filtrarNaoConcluidas () {
      this.filtrarMarcacoes.concluido = false
      this.filtrarMarcacoes.concluido_funcionario = false
      this.filtrar();
    },
    async filtrarConcluidas () {
      this.filtrarMarcacoes.concluido = true
      this.filtrarMarcacoes.concluido_funcionario = true
      this.filtrar();
    },
    async filtrarCliente () {
      this.filtrar();
    },
    filtrar () {
      this.marcacoes = []
      this.page = 1
      this.getMarcacoes();
    },
    navigateToMarcacaoEditar (route) {
      this.$store.dispatch('setMarcacao', {  
        idmarcacao: route.params.idMarcacaoEnviado,
      })
      this.$router.push(route)
    },
    navigateToCalendario (route) {
      this.$router.push(route)
    },
  }
}
</script>
<style>
</style>