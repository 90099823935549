import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import sharedMutations from 'vuex-shared-mutations'
//import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      /*
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, {
          secure: false
        }),
        removeItem: key => Cookies.remove(key)
      }*/
      storage: window.localStorage
    }),
    sharedMutations({
      predicate: [
        'setToken', 'setUser', 'setTeste', 'setTarefa', 'setMarcacao', 'setProjeto', 
        'setTicket', 'setCliente', 'setTempo', 'setSnackBar', 'setTempoCliente', 
        'setTempoTarefa', 'setMarcacaoTarefa', 'setFuncionarioSelect', 'setMensagem', 'setFuncionario'
      ]
    })
  ],
  strict: true,
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false,
    teste: null,
    tarefa: null,
    marcacao: null,
    projeto: null,
    funcionario: null,
    ticket: null,
    cliente: null,
    tempo: null,
    tempotarefa: null,
    tempocliente: null,
    marcacaotarefa: null,
    funcionarioselect: null,
    mensagem: null,
    snackbar: {
      showing: false,
      color: 'success',
      text: 'Operação efectuada com sucesso.'
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      state.isUserLoggedIn = !!token
    },
    setUser (state, user) {
      state.user = user
    },
    setTeste (state, teste) {
      state.teste = teste
    },
    setTarefa (state, tarefa) {
      state.tarefa = tarefa
    },
    setMarcacao (state, marcacao) {
      state.marcacao = marcacao
    },
    setProjeto (state, projeto) {
      state.projeto = projeto
    },
    setFuncionario (state, funcionario) {
      state.funcionario = funcionario
    },
    setTicket (state, ticket) {
      state.ticket = ticket
    },
    setCliente (state, cliente) {
      state.cliente = cliente
    },
    setTempo (state, tempo) {
      state.tempo = tempo
    },
    setTempoTarefa (state, tempotarefa) {
      state.tempotarefa = tempotarefa
    },
    setTempoCliente (state, tempocliente) {
      state.tempocliente = tempocliente
    },
    setMarcacaoTarefa (state, marcacaotarefa) {
      state.marcacaotarefa = marcacaotarefa
    },
    setFuncionarioSelect(state, funcionarioselect) {
      state.funcionarioselect = funcionarioselect
    },
    setMensagem (state, mensagem) {
      state.mensagem = mensagem
    },
    setSnackBar(state, snackbar) {
      state.snackbar = snackbar;
    },
    hideSnackBar (state) {
      state.snackbar.showing = false;
    }
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    setTeste ({ commit }, teste) {
      commit('setTeste', teste)
    },
    setTarefa ({ commit }, tarefa) {
      commit('setTarefa', tarefa)
    },
    setMarcacao ({ commit }, marcacao) {
      commit('setMarcacao', marcacao)
    },
    setProjeto ({ commit }, projeto) {
      commit('setProjeto', projeto)
    },
    setFuncionario ({ commit }, funcionario) {
      commit('setFuncionario', funcionario)
    },
    setTicket ({ commit }, ticket) {
      commit('setTicket', ticket)
    },
    setCliente ({ commit }, cliente) {
      commit('setCliente', cliente)
    },
    setTempo ({ commit }, tempo) {
      commit('setTempo', tempo)
    },
    setTempoTarefa ({ commit }, tempotarefa) {
      commit('setTempoTarefa', tempotarefa)
    },
    setTempoCliente ({ commit }, tempocliente) {
      commit('setTempoCliente', tempocliente)
    },
    setMarcacaoTarefa ({ commit }, marcacaotarefa) {
      commit('setMarcacaoTarefa', marcacaotarefa)
    },
    setMensagem ({ commit }, mensagem) {
      commit('setMensagem', mensagem)
    },
    setFuncionarioSelect ({ commit }, funcionarioselect) {
      commit('setFuncionarioSelect', funcionarioselect)
    },
    setSnackBar({ commit }, snackbar) {
      if (typeof snackbar.showing === "undefined") {
        snackbar.showing = true;
      }
      snackbar.text = snackbar.text || 'Operação realizada com sucesso.';
      snackbar.color = snackbar.color || 'success';
      commit('setSnackBar', snackbar);
  
      setTimeout(() => {
        commit('setSnackBar', { ...snackbar, showing: false });
      }, 3000);
    }
  }
})