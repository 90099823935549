<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Equipamento</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="verEquipamentosCliente" v-bind="attrs" v-on="on" class="ml-5">mdi-plus-circle</v-icon>
                    </template>
                    <span>Adicionar equipamento á tarefa</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="equipamentos.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getEquipamentos" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in equipamentos">
                                    <v-list-item :key="item.id" @click="navigateToEquipamentoEditar({name: 'editarequipamentos', params:{idEquipamentoEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                                            <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                                                <span v-for="(func, index) in item.Funcionarios" :key="func.id">
                                                    {{ func.nome }}<span v-if="item.Funcionarios.length > 1 && index < item.Funcionarios.length - 1">;</span>
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-if="item.instalacoes_infordio == true">Nas instalações da infordio</v-list-item-action-text>
                                            <v-list-item-action-text v-else>Fora da Infordio</v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < equipamentos.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>   
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existe equipamento</span></v-col> 
                </v-row>
            </v-card-text> 
             <!-- Dialog ver equipamentos cliente -->
        <v-dialog v-model="dialogVerEquipamentosCliente">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Equipamentos cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerEquipamentosCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersEquipamentosCliente" :items="equipamentoscliente">
                    <template v-slot:[`item.data_venda`]="{ item }">
                      {{ apresentarData(item.data_venda) }}
                    </template>
                    <template v-slot:[`item.data_fim_garantia`]="{ item }">
                      {{ apresentarData(item.data_fim_garantia) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="adicionarEquipamento(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar equipamento -->
        <v-dialog v-model="dialogAdicionarEquipamento">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar equipamento</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarEquipamento = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo adicionar equipamento a esta tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" class="white--text" @click="confirmarAdicionarEquipamento">Adicionar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-card>
    </v-dialog>
</template>
<script>
import EquipamentosService from '@/services/EquipamentosService'
import TarefasService from '@/services/TarefasService'
import infiniteScroll from "vue-infinite-scroll";
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            equipamentos: [],
            equipamentoscliente: [],
            tarefa: {},

            dialogVerEquipamentosCliente: false,
            dialogAdicionarEquipamento: false,

            headersEquipamentosCliente: [
                { text: "Nº Série ", value: "serial", sortable: true },
                { text: "Descrição Equipamento", value: "descricao", sortable: true },
                { text: "Marca", value: "Equipamentos_Marca.nome", sortable: true },
                { text: "Modelo", value: "Equipamentos_Modelo.nome", sortable: true },
                { text: 'Tipo', value: 'Equipamentos_Tipo.nome', sortable: true },
                { text: 'Data Venda', value: 'data_venda', sortable: true },
                { text: 'Fim Grantia', value: 'data_fim_garantia', sortable: true },
                { text: 'Local', value: 'local', sortable: true },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
        }
    },
    methods: {
        async open(tarefa) {
            this.show = true
            this.equipamentos = []
            this.tarefa = tarefa
            this.getEquipamentos()
        },
        async getEquipamentos() {
            this.loading = true;

            if (this.tarefa.id_equipamento != null) {
                let response = await EquipamentosService.show(this.tarefa.id_equipamento);
                this.equipamentos = this.equipamentos.concat(response.data);
            }

            this.loading = false;
        },
        async verEquipamentosCliente () {
            this.dialogVerEquipamentosCliente = true
            this.equipamentoscliente = (await EquipamentosService.index(null, null, this.editarTarefas.numero_cliente, null, null, null, null, null, null)).data
        },
        async adicionarEquipamento (id) {
            this.idequipamentoglobal = id
            this.dialogAdicionarEquipamento = true
            this.equipamentoscliente = (await EquipamentosService.index(null, null, this.editarTarefas.numero_cliente, null, null, null, null, null, null)).data
        },
        async confirmarAdicionarEquipamento () {
            try{
                this.editarTarefas.id_equipamento = this.idequipamentoglobal
                await TarefasService.put(this.editarTarefas)

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Equipamento adicionado à tarefa"
                });

                this.dialogAdicionarEquipamento = false
                this.dialogVerEquipamentosCliente = false
                if (this.editarTarefas.id_equipamento != null) {
                this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.editarTarefas.id_equipamento)).data
                }
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a adicionar equipamento à tarefa"
                });
            }
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
    }
}
</script>