<template>
    <v-dialog v-model="show" scrollable width="100%" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar class="primary" dark flat>
                <v-toolbar-title>Projeto</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="projetos.length != 0">
                        <v-list twoo-line>
                            <v-list-item-group v-infinite-scroll="getProjetos" infinite-scroll-disabled="busy" infinite-scroll-distance="10">     
                                <template v-for="(item, index) in projetos">
                                    <v-list-item :key="item.id" @click="navigateToProjetoEditar({name: 'editarprojetos', params:{idProjetoEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.concluido == true ? 'green' : 'red')}">
                                        <v-list-item-content>
                                            <v-list-item-subtitle style="font-size: 13px;">{{ item.numero + '/' + item.ano }}</v-list-item-subtitle>
                                            <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome_cliente }}</v-list-item-title>  
                                            <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.descricao != null" v-html="item.descricao.substring(0,120)"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">
                                                <span v-for="(func, index) in item.Funcionarios" :key="func.id">
                                                    {{ func.nome }}
                                                    <span v-if="item.Funcionarios.length > 1 && index < item.Funcionarios.length - 1">;</span>
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-list-item-action-text>{{ apresentarData(item.data_registo) }} {{ apresentarHoras(item.data_registo) }}</v-list-item-action-text>
                                            <v-list-item-action-text></v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < projetos.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        </v-list>   
                    </v-col>
                    <v-col v-else cols="12" class="text-center"><span >Não existe projeto</span></v-col> 
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import ProjetosService from '@/services/ProjetosService'
import infiniteScroll from "vue-infinite-scroll";
export default {
    directives: {
        infiniteScroll
    },
    data () {
        return {
            show: false,
            loading: false,
            projetos: [],
            projetoscliente: [],
            tarefa: {}
        }
    },
    methods: {
        async open(tarefa) {
            this.show = true
            this.tarefa = tarefa
            this.projetos = []
            this.getProjetos()
        },
        async getProjetos() {
            this.loading = true;

            if (this.tarefa.id_projeto != 0) {
                let response = await ProjetosService.show(this.tarefa.id_projeto);
                this.projetos = this.projetos.concat(response.data);
            }
            
            this.loading = false;
        },
        navigateToProjetoEditar (route) {
            this.$store.dispatch('setProjeto', {  
                idprojeto: route.params.idProjetoEnviado,
            })
            this.$router.push(route)
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        apresentarHoras (date) {
            return new Date(date).toJSON().slice(11, 16)
        },
    }
}
</script>