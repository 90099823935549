<template>
  <v-container fluid v-if="$store.state.isUserLoggedIn">
      <v-row>      
      <v-col cols="12" class="p-0">
          <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
            <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
            <v-app-bar-title>Clientes</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-icon v-if="configuracoessistema.modulo_clientes == false" class="ml-5">mdi-plus</v-icon>
            <v-icon @click.stop="dialogFiltrarClientes = true" class="ml-5">mdi-magnify</v-icon>
          </v-app-bar>
      </v-col>
      <span id="mobile-margintop-conteudo"></span>
      <v-col cols="12">
          <v-list twoo-line>
          <v-list-item-group v-infinite-scroll="getClientes" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >     
              <template v-for="(item, index) in clientes">
                  <v-list-item :key="item.id" @click="navigateToCliente({name: 'editarclientes', params:{numeroClienteEnviado: item.numero}})" :style="{'border-left': '10px solid ' + (item.inativo == true ? 'red' : 'green')}">
                      <v-list-item-content>
                          <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.nome }}</v-list-item-title>
                          <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">{{ item.email_empresa }}</v-list-item-subtitle>
                          <v-list-item-subtitle class="text--primary mt-1" style="font-size: 13px;">{{ item.telemovel_empresa }}</v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 13px;">{{ item.morada }}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index < clientes.length - 1" :key="index"></v-divider>
              </template>
          </v-list-item-group>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
          </v-list>
      </v-col>
      <!-- Dialog filtrar clientes -->
      <v-bottom-sheet v-model="dialogFiltrarClientes">
          <v-container fluid>
          <v-row>
              <v-col cols="12 mt-3">
                  <v-chip @click="filtrarNaoInativos" :color="filtrarClientes.inativo == false ? 'primary' : ''" class="mr-2">Ativo</v-chip>
                  <v-chip @click="filtrarInativos" :color="filtrarClientes.inativo == true ? 'primary' : ''" class="mr-2">Não ativo</v-chip>
                  <v-chip @click="filtrarTodos" :color="filtrarClientes.inativo == null ? 'primary' : ''" class="mr-2">Todos</v-chip>
              </v-col>
              <v-col cols="12">
                  <v-text-field @input="filtrarCliente" v-model="filtrarClientes.numero" label="Número" clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-autocomplete @change="filtrarCliente" v-model="filtrarClientes.numero" :items="clientes2" item-text="nome" item-value="numero" label="Nome" clearable></v-autocomplete>
              </v-col>
              <v-col cols="12">
                  <v-text-field @input="filtrarCliente" v-model="filtrarClientes.contribuinte" label="Contribuinte" clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-text-field @input="filtrarCliente" v-model="filtrarClientes.telefone" label="Telefone" clearable></v-text-field>
              </v-col>
          </v-row>
          </v-container>
      </v-bottom-sheet>
      </v-row>
  </v-container>
</template>
  
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import infiniteScroll from "vue-infinite-scroll";
import store from '@/store/store'
export default {
  directives: {
      infiniteScroll
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else {
      this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
      this.clientes2 = (await ClientesService.index2(this.filtrarClientes.inativo)).data
    }
  },
  data () {
    return {
      clientes: [],
      clientes2: [],
      configuracoessistema: {},

      filtrarClientes: {
        inativo: false,
        numero: null,
        contribuinte: null,
        telefone: null,
      },

      dialogFiltrarClientes: false,
      
      loading: true,
      page: 1,
      busy: false,
    }
  },
  methods: {
    async getClientes() {
        this.busy = true;
        this.loading = true;
        const response = await ClientesService.index(
            this.page,
            this.filtrarClientes.inativo,
            this.filtrarClientes.numero,
            this.filtrarClientes.contribuinte,
            this.filtrarClientes.telefone
        );
        this.clientes = this.clientes.concat(response.data);
        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
            this.busy = true;
        }
        this.loading = false;
    },
    goBack() {
        this.$router.push('home')
    },
    // filtrar clientes
    async filtrarTodos () {
        this.filtrarClientes.inativo = null
        this.filtrar();
    },
    async filtrarNaoInativos () {
        this.filtrarClientes.inativo = false
        this.filtrar();
    },
    async filtrarInativos () {
        this.filtrarClientes.inativo = true
        this.filtrar();
    },
    async filtrarCliente () {
      this.filtrar();
    },
    filtrar () {
        this.clientes = []
        this.page = 1
        this.getClientes();
    },

    navigateToCliente (route) {
      this.$store.dispatch('setCliente', {  
        numerocliente: route.params.numeroClienteEnviado,
      })
      this.$router.push(route)
    },
  }
}
</script>
<style>
</style>