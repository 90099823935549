<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="blue" @click.stop="dialogFiltrarEquipamentos = true">
        <img :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" to="criarequipamentos">
        <img :width="45" src="../../assets/icons_aplicacao/Add_Equipamentos.png" alt="Add_Equipamentos">
      </v-btn>
    </v-speed-dial>
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Equipamentos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{textobotaoinativo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
          <v-switch class="pt-5" @change="filtrarInativos" v-model="botaoinativo" color="green" inset></v-switch>
        </v-toolbar>
        <v-col cols="12">
            <span v-if="cliente.nome != null">Equipamentos do cliente {{cliente.nome}}</span>
            <span v-else>Equipamentos de todos os clientes {{cliente.nome}}</span>
        </v-col>
        <v-col cols="12">
          <v-data-table class="elevation-1" :headers="headersEquipamentos" :items="equipamentos">
            <template v-slot:[`item.data_venda`]="{ item }">
              {{ apresentarData(item.data_venda) }}
            </template>
            <template v-slot:[`item.data_fim_garantia`]="{ item }">
              {{ apresentarData(item.data_fim_garantia) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <img @click="apagarEquipamento(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog apagar equipamento -->
      <v-dialog v-model="dialogApagarEquipamento">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title>Apagar equipamento</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarEquipamento = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar o equipamento?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarEquipamento">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog filtrar equipamento -->
      <v-dialog v-model="dialogFiltrarEquipamentos">
        <v-card>
          <v-toolbar class="mb-2" color="black" dark flat>
            <v-toolbar-title>Filtrar Equipamentos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogFiltrarEquipamentos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <label>Número de série</label>
            <v-autocomplete v-model="filtrarEquipamentos.numero_serial" :items="equipamentos" item-text="serial" item-value="serial" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Nome cliente</label>
            <v-autocomplete v-model="filtrarEquipamentos.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Marca</label>
            <v-autocomplete @change="filtrarModelos" v-model="filtrarEquipamentos.id_equipamentos_marca" :items="equipamentosmarcas" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Modelo</label>
            <v-autocomplete v-model="filtrarEquipamentos.id_equipamentos_modelo" :items="equipamentosmodelos" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Tipo</label>
            <v-autocomplete v-model="filtrarEquipamentos.id_equipamentos_tipo" :items="equipamentostipos" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Data da venda</label>
            <MYdatetime type="date" v-model="filtrarEquipamentos.data_venda"></MYdatetime>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" @click="confirmarFiltrarEquipamentos">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import EquipamentosService from '@/services/EquipamentosService'
import ClientesService from '@/services/ClientesService'
import EquipamentosMarcasService from '@/services/EquipamentosMarcasService'
import EquipamentosModelosService from '@/services/EquipamentosModelosService'
import EquipamentosTiposService from '@/services/EquipamentosTiposService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import store from '@/store/store'
export default {
  async mounted () {
    this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
      this.equipamentos = (await EquipamentosService.index(this.filtrarEquipamentos.inativo, this.filtrarEquipamentos.numero_serial, this.filtrarEquipamentos.numero_cliente, this.filtrarEquipamentos.id_equipamentos_marca, this.filtrarEquipamentos.id_equipamentos_modelo, this.filtrarEquipamentos.id_equipamentos_tipo, this.filtrarEquipamentos.data_venda)).data
    } else {
      this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
      this.filtrarEquipamentos.numero_cliente = this.$store.state.cliente.numerocliente
      this.equipamentos = (await EquipamentosService.index(this.filtrarEquipamentos.inativo, this.filtrarEquipamentos.numero_serial, this.filtrarEquipamentos.numero_cliente, this.filtrarEquipamentos.id_equipamentos_marca, this.filtrarEquipamentos.id_equipamentos_modelo, this.filtrarEquipamentos.id_equipamentos_tipo, this.filtrarEquipamentos.data_venda)).data
    }

    this.clientes = (await ClientesService.index2()).data
    this.equipamentosmarcas = (await EquipamentosMarcasService.index()).data
    this.equipamentosmodelos = (await EquipamentosModelosService.index()).data
    this.equipamentostipos = (await EquipamentosTiposService.index()).data
  },
  data () {
    return {
      fab: false,
      equipamentos: [],
      clientes: [],
      cliente: {},
      equipamentosmarcas: [],
      equipamentosmodelos: [],
      equipamentostipos: [],
      configuracoessistema: {},
      filtrarEquipamentos: {
        inativo: false,
        numero_serial: null,
        numero_cliente: null,
        id_equipamentos_marca: null,
        id_equipamentos_modelo: null,
        id_equipamentos_tipo: null,
        data_venda: null
      },
      headersEquipamentos: [
        { text: "Cliente", value: "Cliente.nome", sortable: true },
        { text: "Nº Série ", value: "serial", sortable: true },
        { text: "Descrição Equipamento", value: "descricao", sortable: true },
        { text: "Marca", value: "Equipamentos_Marca.nome", sortable: true },
        { text: "Modelo", value: "Equipamentos_Modelo.nome", sortable: true },
        { text: 'Tipo', value: 'Equipamentos_Tipo.nome', sortable: true },
        { text: 'Data Venda', value: 'data_venda', sortable: true },
        { text: 'Fim Grantia', value: 'data_fim_garantia', sortable: true },
        { text: 'Local', value: 'local', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      dialogFiltrarEquipamentos: false,
      dialogApagarEquipamento: false,
      idequipamentoglobal: '',
      textobotaoinativo: 'Mostrar activos',
      botaoinativo: false
    }
  },
  methods: {
    // formatar data
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    navigateTo (route) {
      this.$store.dispatch('setEquipamento', {  
        idequipamento: route.params.idEquipamentoEnviado,
      })
      this.$router.push(route)
    },
    // filtrar equipamentos
    async confirmarFiltrarEquipamentos () {
      if (this.filtrarEquipamentos.numero_cliente != null) {
        this.cliente = (await ClientesService.show(this.filtrarEquipamentos.numero_cliente)).data
      } else {
        this.cliente.nome = null
      }
      this.equipamentos = (await EquipamentosService.index(this.filtrarEquipamentos.inativo, this.filtrarEquipamentos.numero_serial, this.filtrarEquipamentos.numero_cliente, this.filtrarEquipamentos.id_equipamentos_marca, this.filtrarEquipamentos.id_equipamentos_modelo, this.filtrarEquipamentos.id_equipamentos_tipo, this.filtrarEquipamentos.data_venda)).data
      this.dialogFiltrarEquipamentos = false
    },
    // filtrar modelos
    async filtrarModelos () {
      this.equipamentosmodelos = (await EquipamentosModelosService.index(this.filtrarEquipamentos.id_equipamentos_marca)).data
    },
    // filtrar inativos
    async filtrarInativos () {
      if (this.botaoinativo == false) {
          this.filtrarEquipamentos.inativo = false
          this.textobotaoinativo = 'Mostrar activos'
      }
      if (this.botaoinativo == true) {
        this.filtrarEquipamentos.inativo = null
        this.textobotaoinativo = 'Mostrar todos'
      }
      this.equipamentos = (await EquipamentosService.index(this.filtrarEquipamentos.inativo, this.filtrarEquipamentos.numero_serial, this.filtrarEquipamentos.numero_cliente, this.filtrarEquipamentos.id_equipamentos_marca, this.filtrarEquipamentos.id_equipamentos_modelo, this.filtrarEquipamentos.id_equipamentos_tipo, this.filtrarEquipamentos.data_venda)).data
    },
    //-------------------------------------------equipamentos------------------------------//
    //-------------------------------------------equipamentos------------------------------//
    //-------------------------------------------equipamentos------------------------------//
    async apagarEquipamento (id) {
      this.dialogApagarEquipamento = true
      this.idequipamentoglobal = id
    },
    async confirmarApagarEquipamento () {
      try{
        if (this.configuracoessistema.modulo_equipamentos == true) {
          this.equipamentosid = (await EquipamentosService.show(this.idequipamentoglobal)).data
          const response =  await EquipamentosService.apagarequipamentoserp(this.equipamentosid)
          if (response.data == 'ERRO') {
              this.$store.dispatch("setSnackBar", {
                  color: "error",
                  text: "Campos estão a ir errados"
              });         
          } else {
            await EquipamentosService.delete(this.idequipamentoglobal)
            this.dialogApagarEquipamento = false
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Equipamento apagado do erp com sucesso"
            });
            this.equipamentos = (await EquipamentosService.index(this.filtrarEquipamentos.inativo, this.filtrarEquipamentos.numero_serial, this.filtrarEquipamentos.numero_cliente, this.filtrarEquipamentos.id_equipamentos_marca, this.filtrarEquipamentos.id_equipamentos_modelo, this.filtrarEquipamentos.id_equipamentos_tipo, this.filtrarEquipamentos.data_venda)).data
          }
        } else {
          await EquipamentosService.delete(this.idequipamentoglobal)
          this.dialogApagarEquipamento = false
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Equipamento apagado com sucesso"
          });
          this.equipamentos = (await EquipamentosService.index(this.filtrarEquipamentos.inativo, this.filtrarEquipamentos.numero_serial, this.filtrarEquipamentos.numero_cliente, this.filtrarEquipamentos.id_equipamentos_marca, this.filtrarEquipamentos.id_equipamentos_modelo, this.filtrarEquipamentos.id_equipamentos_tipo, this.filtrarEquipamentos.data_venda)).data
        }
        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar equipamento, pode ter coisas associadas"
          });
      }
    },
  }
}
</script>
<style>
</style>