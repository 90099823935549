import Api from '@/services/Api'

export default {
  index (page, id_tarefa, data_inicio, data_fim, id_funcionario, numero_cliente, concluido) {
    return Api().get('tempos',{
      params: {
        page,
        id_tarefa,
        data_inicio,
        data_fim,
        id_funcionario,
        numero_cliente,
        concluido
      }
    })
  },
  post (elementos) {
    return Api().post('tempos', elementos)
  },
  put (elementos) {
    return Api().put(`tempos/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`tempos/${id}`)
  },
  delete (id) {
    return Api().delete(`tempos/${id}`)
  },
  ultimotempo () {
    return Api().get(`ultimotempo`)
  },
}
