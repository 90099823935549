<template>
  <v-container fluid v-if="$store.state.isUserLoggedIn">
      <v-row>      
          <v-col cols="12" class="p-0">
              <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="primary" class="flex-grow-0" dark id="paginas-v-app-bar">
              <v-icon @click="goBack" class="mr-2">mdi-arrow-left</v-icon>
              <v-app-bar-title>Mensagens</v-app-bar-title>
              <v-spacer></v-spacer>
              <v-icon @click="navigateToMensagemCriar({name: 'criarmensagens'})" class="ml-5">mdi-plus</v-icon>
              <v-icon @click.stop="dialogFiltrarMensagens = true" class="ml-5">mdi-magnify</v-icon>
              </v-app-bar>
          </v-col>
          <span id="mobile-margintop-conteudo"></span>
          <v-col cols="12">
              <v-list twoo-line>
              <v-list-item-group v-infinite-scroll="getMensagens" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >     
                  <template v-for="(item, index) in mensagens">
                      <v-list-item :key="item.id" @click="navigateToMensagemEditar({name: 'chatmensagens', params:{idMensagemEnviado: item.id}})" :style="{'border-left': '10px solid ' + (item.inativo == true ? 'red' : 'green')}">
                          <v-list-item-content>
                              <v-list-item-title style="font-size: 15px; font-weight: bold;">{{ item.enviadoDe.nome }}</v-list-item-title>
                              <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.assunto != null" v-html="item.assunto.substring(0,120)"></span></v-list-item-subtitle>
                              <v-list-item-subtitle style="font-size: 13px;"><span v-if="item.mensagem != null" v-html="item.mensagem.substring(0,120)"></span></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text>{{ apresentarData(item.data_registo) }} {{ apresentarHoras(item.data_registo) }}</v-list-item-action-text>
                          </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index < mensagens.length - 1" :key="index"></v-divider>
                  </template>
              </v-list-item-group>
              <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
              </v-list>
          </v-col>
          <!-- Dialog filtrar funcionários -->
          <v-bottom-sheet v-model="dialogFiltrarMensagens">
              <v-container fluid>
              <v-row>
                  <v-col cols="12">
                      <v-autocomplete @change="filtrarFuncionario" v-model="filtrarMensagens.id_funcionario" :items="funcionarios" item-text="nome" item-value="nome" label="Funcionário" clearable></v-autocomplete>
                  </v-col>
              </v-row>
              </v-container>
          </v-bottom-sheet>
      </v-row>
  </v-container>
</template>
    
<script>
//  Importar o service necessário
import MensagensService from '@/services/MensagensService'
import FuncionariosService from '@/services/FuncionariosService'
import infiniteScroll from "vue-infinite-scroll";
import store from '@/store/store'
export default {
  directives: {
    infiniteScroll
  },
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } 
    this.loading = true
    this.funcionarios = (await FuncionariosService.index2()).data
    this.loading = false
  },
  data () {
    return {
      mensagens: [],
      funcionarios: [],
      filtrarMensagens: {
        mensagem_resposta: 0,
        id_funcionario: this.$store.state.funcionarioselect.idfuncionario
      },

      dialogFiltrarMensagens: false,

      loading: true,
      page: 1,
      busy: false,
    }
  },
  methods: {
    async getMensagens() {
        this.busy = true;
        this.loading = true;
        const response = await MensagensService.index(
            this.page,
            this.filtrarMensagens.mensagem_resposta,
            this.filtrarMensagens.id_funcionario
        );
        this.mensagens = this.mensagens.concat(response.data);
        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
            this.busy = true;
        }
        this.loading = false;
    },
    goBack() {
        this.$router.push('home')
    },
    // filtrar funcionários
    async filtrarFuncionario () {
      this.filtrar();
    },
    filtrar () {
        this.mensagens = []
        this.page = 1
        this.getMensagens();
    },
    navigateToMensagemCriar (route) {
        this.$router.push(route)
    },
    navigateToMensagemEditar (route) {
        this.$store.dispatch('setMensagem', {  
          idmensagem: route.params.idMensagemEnviado,
        })
        this.$router.push(route)
    },
    // formatar data
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 16)
    },
  }
}
</script>
<style>
</style>