import Api from '@/services/Api'

export default {
  index (page, inativo, numero, contribuinte, telefone) {
    return Api().get('clientes',{
      params: {
        page,
        inativo,
        numero,
        contribuinte,
        telefone
      }
    })
  },
  index2 (inativo) {
    return Api().get('clientesindex2',{
      params: {
        inativo
      }
    })
  },
  post (elementos) {
    return Api().post('clientes', elementos)
  },
  put (elementos) {
    return Api().put(`clientes/${elementos.numero}`, elementos)
  },
  show (numero) {
    return Api().get(`clientes/${numero}`)
  },
  delete (numero) {
    return Api().delete(`clientes/${numero}`)
  },
  deleteall () {
    return Api().delete(`clientes`)
  },
  ultimocliente () {
    return Api().get(`ultimocliente`)
  },
  showcontribuinte (contribuinte) {
    return Api().get(`showcontribuinte`,{
      params: {
        contribuinte
      }
    })
  },


  //IMPORTAR ERP'S
  importarclienteserp (numero_cliente) {
    return Api().get('importarclienteserp', {
      params: {
        numero_cliente
      }
    })
  },
}
